import {
  Box,
  getContrastRatio,
  PaletteColor,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Decimal from 'decimal.js';
import { CSSProperties } from 'react';

import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

export function ZeroLine() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ borderTop: `solid ${COLORS.GRAY[300]} 1px` }}
    />
  );
}

function LegendColor({
  color,
  variant,
}: {
  color: LegendLabelProps['color'];
  variant: LegendLabelProps['variant'];
}) {
  return (
    <Box
      sx={{
        width: 12,
        height: 12,
        borderRadius: variant === 'circles' ? 12 : 1,
        background: `radial-gradient(farthest-corner at 10% 15%, ${color.main}, ${color.light})`,
      }}
    />
  );
}

interface LegendLabelProps {
  copy: string;
  color: PaletteColor;
  variant: 'circles' | 'squares';
}
function LegendLabel({ copy, color, variant = 'circles' }: LegendLabelProps) {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <LegendColor color={color} variant={variant} />
      <Typography variant="subtitle2">{copy}</Typography>
    </Stack>
  );
}

export function LegendLEGACY({
  legendValues,
  variant = 'circles',
}: {
  legendValues: [string, PaletteColor][];
  variant: 'circles' | 'squares';
}) {
  return (
    <Stack direction="row" spacing={1.5}>
      {legendValues.map(([copy, color], idx) => {
        return (
          <LegendLabel
            copy={copy}
            color={color}
            key={`legend-${idx}`}
            variant={variant}
          />
        );
      })}
    </Stack>
  );
}

export function getBackgroundColor(fromColor: string, toColor: string) {
  return `radial-gradient(farthest-corner at 5% 10%, ${fromColor}, ${toColor})`;
}

export function BenefitPill({
  benefit,
  variant = 'netBenefit',
  outOfEstate,
  reduction,
}: {
  benefit?: Decimal;
  variant?: 'transferredToBeneficiaries' | 'netBenefit' | 'reduction';
  outOfEstate?: Decimal;
  reduction?: Decimal;
}) {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center">
      {variant === 'transferredToBeneficiaries' && (
        <Box px={1} py={'2px'} borderRadius={0.5}>
          <Typography
            color={
              getContrastRatio(
                theme.palette.dataVisualizationSecondary.main,
                theme.palette.background.default
              ) > 3
                ? theme.palette.dataVisualizationSecondary.main
                : theme.palette.text.primary
            }
            variant="label"
          >
            {formatCurrency(outOfEstate ?? new Decimal(0), {
              notation: 'compact',
            })}{' '}
            transferred out of estate
          </Typography>
        </Box>
      )}
      {variant === 'netBenefit' && (
        <Box
          bgcolor={theme.palette.dataVisualizationSecondary.main}
          px={1}
          py={'2px'}
          borderRadius={0.5}
        >
          <Typography
            color={theme.palette.getContrastText(
              theme.palette.dataVisualizationSecondary.main
            )}
            variant="label"
          >
            {'+'}
            {formatCurrency(benefit ?? new Decimal(0), {
              notation: 'compact',
            })}{' '}
            net to beneficiaries
          </Typography>
        </Box>
      )}
      {variant === 'reduction' && (
        <Box px={1} borderRadius={0.5} paddingBottom={0.5} paddingTop={1.5}>
          <Typography
            color={
              getContrastRatio(
                theme.palette.dataVisualizationNegative.dark,
                theme.palette.background.default
              ) > 3
                ? theme.palette.dataVisualizationNegative.dark
                : theme.palette.text.primary
            }
            variant="label"
          >
            {formatCurrency(reduction ?? new Decimal(0), {
              notation: 'compact',
              currencySign: 'accounting',
            })}{' '}
            reduction in estate taxes
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export const getPreferredBackground = ({
  backgroundCss,
  color,
}: {
  backgroundCss?: CSSProperties['background'];
  color: string;
}) => {
  if (backgroundCss) {
    return backgroundCss;
  }

  return color;
};
