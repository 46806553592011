import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SxProps,
  Theme,
} from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { XCloseIcon } from '@/components/icons/XCloseIcon';
import { COLORS } from '@/styles/tokens/colors';

import { Dialog } from '../Dialog';

export interface DialogModalProps {
  isOpen: boolean;
  heading: React.ReactNode;
  actions?: React.ReactNode;
  onClose: () => void;
  dialogContentSx?: SxProps<Theme>;
}

export const DIALOG_MODAL_MAX_WIDTH = 700 as const;

export function DialogModal({
  isOpen,
  onClose,
  heading,
  actions,
  children,
  dialogContentSx,
}: React.PropsWithChildren<DialogModalProps>) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: DIALOG_MODAL_MAX_WIDTH } }}
    >
      <Stack
        direction="row"
        component="header"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pr: 2 }}
      >
        <DialogTitle variant="h1">{heading}</DialogTitle>
        <Button
          variant="transparent"
          size="xs"
          aria-label="Close"
          onClick={onClose}
        >
          <XCloseIcon size={20} color={COLORS.GRAY[400]} />
        </Button>
      </Stack>
      <DialogContent sx={dialogContentSx}>{children}</DialogContent>
      {actions && (
        <DialogActions
          sx={{
            p: 2,
          }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
}
