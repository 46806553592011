import { IconButton, IconButtonProps } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';

import { HelpCircleIcon, IconProps } from '@/components/icons/HelpCircleIcon';

export interface HelpIconButtonProps {
  buttonProps?: Partial<IconButtonProps>;
  iconProps?: Partial<IconProps>;
}

function HelpIconButtonInner(
  { buttonProps = {}, iconProps = {} }: HelpIconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <IconButton
      aria-label="help"
      ref={ref}
      sx={{
        padding: 0,
        ml: 0.5,
      }}
      {...buttonProps}
    >
      <HelpCircleIcon
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        size={16}
        {...iconProps}
      />
    </IconButton>
  );
}

export const HelpIconButton = forwardRef<
  HTMLButtonElement,
  HelpIconButtonProps
>(HelpIconButtonInner);
