import Decimal from 'decimal.js';
import { useEffect, useRef } from 'react';
import { Control, FieldValues, Path, useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { FieldNameFromFormShape } from '@/types/react-hook-form';
import { getComparableDecimalJS } from '@/utils/decimalJSUtils';
import { diagnostics } from '@/utils/diagnostics';

/**
 * @description This hook is used to trigger validation on a field when the value changes.
 * It is used in conjunction with the `useFormContext` hook.
 *
 * @param validateOnChange Whether or not to trigger validation on change. This is set on the input component.
 * @param fieldName The name of the field to trigger validation on.
 */

interface UseValidateOnChangeOpts {
  valueType: 'string' | 'decimal';
}

export function useValidateOnChange<FormShape extends FieldValues>(
  validateOnChange: boolean,
  fieldName: FieldNameFromFormShape<FormShape>,
  control: Control<FormShape>,
  opts: UseValidateOnChangeOpts
) {
  const maybeFormContext = useFormContext<FormShape>();
  const comparableValue = useRef<string | null>(null);

  const watchedValue = useWatch({
    name: fieldName,
    control,
  });

  useEffect(
    function handleValidateOnChange() {
      // let the developer know they need to wrap the form in a FormContext
      // if they want to use this hook
      if (!maybeFormContext && validateOnChange) {
        diagnostics.warn(
          'Attempting to use useValidateOnChange without a FormContext.'
        );
      }

      if (!maybeFormContext || !validateOnChange) {
        return;
      }

      // returns a comparable value so we don't infinitely trigger validation
      const comparableForValidation: string | null = (() => {
        if (opts.valueType === 'string') {
          return (watchedValue || '') as string;
        }

        const value = new Decimal(watchedValue ?? 0);
        return getComparableDecimalJS(value);
      })();

      // if the comparable value is the same as the last comparable value, we don't need to trigger validation
      if (comparableForValidation === comparableValue.current) {
        return;
      }

      const trigger = maybeFormContext.trigger;

      void (async () => {
        await trigger(fieldName as Path<FormShape>);

        comparableValue.current = comparableForValidation;
      })();
    },
    [
      maybeFormContext,
      validateOnChange,
      fieldName,
      watchedValue,
      opts.valueType,
    ]
  );
}
