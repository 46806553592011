/* eslint-disable react/display-name */
import { Box, Stack } from '@mui/material';
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

import { EditButton as EditButtonExternal } from '@/components/form/baseInputs/Button/EditButton';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';

import { CellContainer } from '../../components/cells';
import { CellRenderProp } from '../../types';
import { getCellRenderProp } from '../../utils/getRenderProp';

interface EditButtonProps<R extends GridValidRowModel> {
  handleClick: (row: R) => void;
}

function EditButton<R extends GridValidRowModel = GridValidRowModel>({
  props,
  row,
}: {
  props?: EditButtonProps<R>;
  row: R;
}) {
  return (
    <Stack direction="row">
      <Tooltip title="Edit" placement="top-start">
        <Box>
          <EditButtonExternal
            size="xs"
            variant="secondary"
            onClick={() => props?.handleClick(row)}
            iconProps={{ size: 20 }}
          />
        </Box>
      </Tooltip>
    </Stack>
  );
}

interface EditButtonRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  editButtonProps: CellRenderProp<R, V, F, N, EditButtonProps<R>>;
}

export const EditButtonRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends string = string,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  factoryProps?: EditButtonRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const editButtonProps = getCellRenderProp({
      props,
      prop: factoryProps?.editButtonProps,
      defaultValue: undefined,
    });

    return (
      <CellContainer align={props.colDef.align}>
        <EditButton props={editButtonProps} row={props.row} />
      </CellContainer>
    );
  };
};
