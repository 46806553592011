import { Stack } from '@mui/material';
import React, { HTMLAttributes } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { useTabsContext } from './tabs.context';

export type TabsProps = React.PropsWithChildren<{
  fullWidth?: boolean;
}>;

export function Tabs({ children, fullWidth }: TabsProps) {
  return (
    <Stack
      component="ul"
      direction="row"
      spacing={2}
      sx={{
        listStyleType: 'none',
        p: 0,
        m: 0,
        minHeight: '40px',
        height: 'fit-content',
        borderBottom: `solid ${COLORS.GRAY[200]} 1px`,
        alignItems: 'flex-end',
      }}
    >
      {React.Children.map(children, (child, i) => {
        return (
          <li
            key={i}
            style={{
              display: 'flex',
              height: '100%',
              // Add a negative margin to the bottom of each tab so that the border
              // of the tabs is flush with the border of the container
              marginBottom: '-1px',
              alignItems: 'flex-end',
              flexGrow: fullWidth ? 1 : 0,
            }}
          >
            {child}
          </li>
        );
      })}
    </Stack>
  );
}

export interface TabContentProps<T extends string>
  extends HTMLAttributes<HTMLDivElement> {
  tabKey: T;
}

export function TabContent<T extends string>({
  children,
  tabKey,
  ...props
}: TabContentProps<T>) {
  const { currentTab } = useTabsContext();

  const isActive = currentTab === tabKey;

  return (
    <div
      style={{
        // Doing this so the contents of each tab don't get unmounted
        display: isActive ? 'unset' : 'none',
      }}
      {...props}
    >
      {children}
    </div>
  );
}
