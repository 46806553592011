// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type TaxDetail_TaxDetailFragment = { __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null };

export const TaxDetail_TaxDetailFragmentDoc = gql`
    fragment TaxDetail_TaxDetail on TaxDetail {
  _cacheKey
  deductions
  exemptionAvailable
  exemptionCliff
  exemptionUsed
  portableExemption
  stateCode
  stateDisclosure
  tax
  taxableAmount
  taxableAmountAfterExemption
  taxableTransfers
  totalExemption
  taxableAmountOverCliff
}
    `;