import { SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

import { COLORS } from '@/styles/tokens/colors';

interface HeaderCellTypographyProps {
  sx?: SxProps<Theme>;
}

export function HeaderCellTypography({
  sx,
  children,
}: React.PropsWithChildren<HeaderCellTypographyProps>) {
  return (
    <Typography
      fontWeight="bold"
      component="p"
      sx={{
        whiteSpace: 'normal',
        ...sx,
      }}
      variant="h6"
      color={COLORS.GRAY[500]}
    >
      {children}
    </Typography>
  );
}
