import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { GiftProposalProjection } from '@/modules/gifting/proposal/giftProposal.types';
import { getNodes } from '@/utils/graphqlUtils';

import { useGetProjectionsForProposalQuery } from '../graphql/GetProjectionsForProposal.generated';

export function useProjectionsForProposal(
  proposalId: string,
  externalProjections?:
    | Readonly<GiftProposalProjection[]>
    | GiftProposalProjection[]
): {
  projections?: GiftProposalProjection[];
} & ReturnType<typeof useGetProjectionsForProposalQuery> {
  const { createErrorFeedback } = useFeedback();
  const { reportError } = useReportError();

  const queryProps = useGetProjectionsForProposalQuery({
    variables: {
      where: {
        id: proposalId,
      },
    },
    fetchPolicy: 'cache-and-network',
    onError: createErrorFeedback('Could not load projections for proposal'),
    skip: !!externalProjections,
  });

  return useMemo(() => {
    const { data } = queryProps;

    const derivedData = (() => {
      if (!data) {
        return;
      }

      const proposal = getNodes(data?.proposals)[0];
      const giftingProposal = proposal?.giftingProposal;

      if (!giftingProposal) {
        const errorDescription =
          'Expected exactly one proposal to be returned.';
        const err = new Error(errorDescription);

        reportError(errorDescription, err, {
          proposalId,
        });
        throw err;
      }

      return {
        projections:
          giftingProposal.giftingScenarios?.map(
            (scenario) => scenario.projections
          ) || [],
      };
    })();

    const { projections } = derivedData ?? {
      projections: externalProjections,
    };

    return {
      projections: projections
        ?.slice() // clone the array to avoid mutating the original, as externalProjections may be read-only
        .sort((a, b) => (a.scenario?.order || 0) - (b.scenario?.order || 0)),

      ...queryProps,
    };
  }, [externalProjections, proposalId, queryProps, reportError]);
}
