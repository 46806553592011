import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

import { TextRenderer } from '../../renderers/cell/TextRenderer';
import { ContextualHelpHeaderRenderer } from '../../renderers/header/ContextualHelpHeaderRenderer';
import { InternalDataTableProps } from '../../types';

const defaultRenderCell = TextRenderer();
const defaultSortComparator = gridStringOrNumberComparator;

export function useDefaultColumns({
  columns,
}: InternalDataTableProps): InternalDataTableProps['columns'] {
  return useMemo(() => {
    return columns.map(
      (c) =>
        Object.assign(
          {
            renderHeader: ContextualHelpHeaderRenderer({
              contextualHelp: c.contextualHelp,
              header: c.headerName,
            }),
            renderCell: defaultRenderCell,
            sortComparator: defaultSortComparator,
            headerAlign: c.align || 'inherit',
            minWidth: c.minWidth ?? 100,
          },
          c
        ) as InternalDataTableProps['columns'][number]
    );
  }, [columns]);
}
