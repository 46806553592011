import { SxProps, Theme } from '@mui/material';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  BaseRadioGroupInputProps,
  HelpTextVariant,
  RadioGroupOption,
} from '../baseInputs/inputTypes';
import { RadioGroup } from '../baseInputs/RadioGroup';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwareRadioGroupProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label?: string;
  hideLabel?: boolean;
  required?: boolean;
  control: Control<FormShape>;
  options: RadioGroupOption<unknown>[];
  row?: boolean;
  stackingDirection?: BaseRadioGroupInputProps<
    FormShape[FieldNameFromFormShape<FormShape>]
  >['stackingDirection'];
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  sx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
}

export function FormAwareRadioGroup<FormShape extends FieldValues>({
  fieldName,
  label,
  hideLabel,
  required,
  options,
  control,
  row,
  disabled,
  validation,
  sx,
  labelSx,
  stackingDirection,
  helpText,
  helpTextVariant,
}: FormAwareRadioGroupProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(
    label || 'This field',
    !!required,
    validation
  );
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <RadioGroup
            label={label}
            hideLabel={hideLabel}
            onChange={field.onChange}
            value={field.value}
            name={field.name}
            inputRef={field.ref}
            required={required}
            options={options}
            disabled={disabledFromContext ?? disabled}
            row={row}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            sx={sx}
            labelSx={labelSx}
            stackingDirection={stackingDirection}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
          />
        );
      }}
    />
  );
}
