import { Stack, SxProps, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { COLORS } from '@/styles/tokens/colors';
import { zIndexes } from '@/styles/zIndexes';

interface SelectItemGroupLabelProps {
  label?: React.ReactNode;
  sx?: SxProps<Theme>;
}

// this is used to label a group of options in a select
export function SelectItemGroupLabel({
  label,
  sx = {},
}: SelectItemGroupLabelProps) {
  const theme = useTheme();

  if (!label) {
    return (
      <Stack justifyContent="end" sx={sx} component="div">
        <Stack
          bgcolor={COLORS.GRAY[300]}
          height="1px"
          justifyContent="center"
          paddingLeft="14px"
        >
          &nbsp;
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      justifyContent="end"
      sx={{
        height: theme.spacing(4),
        // this is to make the label stick to the top of the select input when the header
        // is scrolled past, so the user retains context of what section they're in
        position: 'sticky',
        top: '-12px',
        zIndex: zIndexes.MENU,
        ...sx,
      }}
      component="div"
    >
      <Stack
        bgcolor={COLORS.GRAY[200]}
        height={20}
        justifyContent="center"
        px={'14px'}
      >
        <Typography variant="h6" color={COLORS.GRAY[500]} component="p">
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
}
