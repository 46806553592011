import { Theme } from '@mui/material';
import React from 'react';

import { COLORS } from '@/styles/tokens/colors';

export const TAB_BOTTOM_PADDING = 12;

export const commonTabStyle: React.CSSProperties = {
  minHeight: '40px',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'center',
  padding: '0 6px',
  paddingBottom: `${TAB_BOTTOM_PADDING}px`,
  color: COLORS.GRAY[500],
  whiteSpace: 'nowrap', // prevent hyphenated tabs from wrapping to a new line
  flexGrow: 1, // a little bit funny, but it works in conjunction with the `display: 'flex'` logic relating to fullWidth behavior.
};

export const activeStyle = {
  borderBottom: `solid ${COLORS.ORANGE[400]} 2px`,
  color: COLORS.NAVY[700],
  // Subtract the border width from the padding to keep the tab the same height
  paddingBottom: `${TAB_BOTTOM_PADDING - 2}px`,
};

export const getActiveStyle = (variant: 'tab1' | 'tab2', theme: Theme) => {
  if (variant === 'tab2') {
    return {
      ...activeStyle,
      borderBottom: `solid ${theme.palette.primary.dark} 4px`,
      // Subtract the border width from the padding to keep the tab the same height
      paddingBottom: `${TAB_BOTTOM_PADDING - 4}px`,
    };
  }
  return activeStyle;
};
