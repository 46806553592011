import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { usePrevious } from 'react-use';

import { TabsContext } from './tabs.context';

export function TabsProvider<T extends string>(
  props: PropsWithChildren<{
    currentTab: T;
    disableExternalChange?: boolean; // Disables listening to external changes, i.e., use provider's internal state management
  }>
) {
  const {
    children,
    currentTab: externalCurrentTab,
    disableExternalChange,
  } = props;
  const [currentTab, setCurrentTab] = useState<T>(externalCurrentTab);
  const prevInternalCurrentTab = usePrevious(currentTab);
  const didCurrentTabUpdate = prevInternalCurrentTab !== currentTab; // A tab change can be identified by comparing the previous current tab with the current tab
  // A change can occur from an external source (e.g., table row click) or an internal source (e.g., tab click)
  const isExternalChange = externalCurrentTab !== currentTab; // An external change can be identified by comparing the current external tab with the current internal tab

  useEffect(() => {
    if (didCurrentTabUpdate) return;
    if (disableExternalChange) return;
    if (isExternalChange) {
      setCurrentTab(externalCurrentTab);
    }
  }, [
    externalCurrentTab,
    isExternalChange,
    didCurrentTabUpdate,
    disableExternalChange,
  ]);

  return (
    <TabsContext.Provider
      value={{
        currentTab,
        setCurrentTab: setCurrentTab as Dispatch<SetStateAction<string>>,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}
