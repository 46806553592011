import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';

import { OutOfEstatePortfolioFormShape } from '../GiftDesignerBasicInformationForm.types';
import { OutOfEstatePortfolioFormModal } from './OutOfEstatePortfolioFormModal/OutOfEstatePortfolioFormModal';

interface AddOutOfEstatePortfolioButtonProps {
  onSubmitSuccess: (formData: OutOfEstatePortfolioFormShape) => void;
}

export function AddOutOfEstatePortfolioButton({
  onSubmitSuccess,
}: AddOutOfEstatePortfolioButtonProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  return (
    <>
      <OutOfEstatePortfolioFormModal
        portfolioData={null}
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
        }}
        onSubmitSuccess={(formData) => {
          onSubmitSuccess(formData);
          closeModal();
        }}
      />
      <Button
        startIcon={PlusIcon}
        variant="secondary"
        size="md"
        onClick={() => {
          openModal();
        }}
      >
        Add out-of-estate portfolio
      </Button>
    </>
  );
}
