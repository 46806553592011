import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';
import { GiftingProposalCashFlowCashFlowType } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { AnnuallyRecurringValue } from '../ScenarioIncomeAndExpensesModal/ScenarioIncomeAndExpensesModal.fields';

export type GiftListItem = FieldArrayWithId<
  GiftDesignerModelScenariosFormShape,
  `scenarios.${number}.scenarioGifts`,
  '_id'
>;

export type IncomeOrExpenseListItem = FieldArrayWithId<
  GiftDesignerModelScenariosFormShape,
  `scenarios.${number}.scenarioCashFlows`,
  '_id'
>;

interface GiftScenarioListItemProps {
  item: GiftListItem | IncomeOrExpenseListItem;
  handleEditClick: (item: GiftListItem | IncomeOrExpenseListItem) => void;
}

function formatAmount(item: GiftListItem | IncomeOrExpenseListItem) {
  if (!item.amount) {
    return EMPTY_CONTENT_HYPHEN;
  }

  if (
    'cashFlowType' in item &&
    item.cashFlowType === GiftingProposalCashFlowCashFlowType.Expense
  ) {
    return `(${formatCurrencyNoDecimals(item.amount)})`;
  }

  return formatCurrencyNoDecimals(item.amount);
}

export function GiftScenarioListItem({
  item,
  handleEditClick,
}: GiftScenarioListItemProps) {
  const ItemDetail = useMemo(() => {
    if ('annuallyRecurring' in item) {
      let color = COLORS.NAVY[600];

      if ('cashFlowType' in item) {
        color =
          item.cashFlowType === GiftingProposalCashFlowCashFlowType.Expense
            ? COLORS.MATH.NEGATIVE
            : COLORS.NAVY[600];
      }

      const label =
        item.annuallyRecurring === AnnuallyRecurringValue.true
          ? '(annually)'
          : '(one-time)';

      const formattedAmount = formatAmount(item);

      return (
        <Stack
          direction="row"
          spacing={0.5}
          data-testid="GiftScenarioListItem-value"
        >
          <Typography variant="h6" color={color}>
            {formattedAmount}
          </Typography>
          <Typography variant="h6" color={COLORS.NAVY[600]}>
            {label}
          </Typography>
        </Stack>
      );
    }

    return '';
  }, [item]);

  const showEditButton = useMemo(() => {
    if ('isBaselineCashFlow' in item) {
      // We don't want to show the edit button for the baseline cash flows
      return !item.isBaselineCashFlow;
    }

    return true;
  }, [item]);

  return (
    <Stack
      key={item._id}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="subtitle2">{item.displayName}</Typography>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="end"
        alignItems="center"
      >
        {ItemDetail}
        {showEditButton && (
          <EditButton
            onClick={() => handleEditClick(item)}
            sx={{
              p: 0,
            }}
            iconProps={{
              size: 16,
            }}
          />
        )}
      </Stack>
    </Stack>
  );
}
