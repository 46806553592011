import { Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { useModalState } from '@/hooks/useModalState';
import { COLORS } from '@/styles/tokens/colors';

import {
  GiftDesignerBasicInformationFormShape,
  OutOfEstatePortfolioFormShape,
} from '../../GiftDesignerBasicInformationForm.types';
import { OutOfEstatePortfolioFormModal } from '../OutOfEstatePortfolioFormModal/OutOfEstatePortfolioFormModal';
import { columns } from './OutOfEstatePortfoliosTable.columns';
import { OutOfEstatePortfolioRow } from './OutOfEstatePortfoliosTable.types';

function useSlots() {
  return {
    noRowsOverlay: () => (
      <Stack
        spacing={1}
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        p="80px"
        textAlign="center"
      >
        <Typography variant="label">No out-of-estate portfolios</Typography>
        <Typography variant="body1" color={COLORS.GRAY[500]}>
          Enter individual, trust, or charity portfolios that will receive the
          gift(s) modeled in this analysis.
        </Typography>
      </Stack>
    ),
  };
}

interface OutOfEstatePortfoliosTableProps {
  outOfEstatePortfolios: FieldArrayWithId<
    GiftDesignerBasicInformationFormShape,
    'outOfEstatePortfolios',
    '_id'
  >[];
  onSubmitSuccess: (
    idx: number,
    formData: OutOfEstatePortfolioFormShape
  ) => void;
  onDelete?: (idx: number) => void;
}

type OutOfEstatePortfolioTableRow =
  OutOfEstatePortfoliosTableProps['outOfEstatePortfolios'][0];

export function OutOfEstatePortfoliosTable({
  outOfEstatePortfolios,
  onSubmitSuccess,
  onDelete,
}: OutOfEstatePortfoliosTableProps) {
  const slots = useSlots();
  const [{ isModalOpen, data: portfolioData }, { openModal, closeModal }] =
    useModalState<OutOfEstatePortfolioTableRow & { idx: number }>();

  const openEditModal = useCallback(
    (portfolioToOpen: OutOfEstatePortfolioTableRow, idx: number) => {
      openModal({ ...portfolioToOpen, idx });
    },
    [openModal]
  );

  const rows: OutOfEstatePortfolioRow[] = useMemo(() => {
    return outOfEstatePortfolios.map((p, idx) => ({
      _id: p._id,
      portfolioId: p.portfolioId,
      name: p.name,
      type: p.type,
      value: p.amount,
      handleEdit: () => {
        openEditModal(p, idx);
      },
      edit: null,
    }));
  }, [openEditModal, outOfEstatePortfolios]);

  return (
    <>
      <OutOfEstatePortfolioFormModal
        portfolioData={portfolioData}
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
        }}
        onSubmitSuccess={(formData) => {
          onSubmitSuccess(portfolioData?.idx ?? Infinity, formData);
          closeModal();
        }}
        onDelete={() => {
          onDelete?.(portfolioData?.idx ?? Infinity);
          closeModal();
        }}
      />
      <DataTable
        getRowId={(row: OutOfEstatePortfolioRow) => row._id}
        rows={rows}
        columns={columns}
        slots={slots}
        sx={{ '--DataGrid-overlayHeight': '208px' }}
        hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS
        data-testid="OutOfEstatePortfoliosTable"
      />
    </>
  );
}
