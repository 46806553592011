import { Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';

import { OutOfEstatePortfolioFormShape } from '../../GiftDesignerBasicInformationForm.types';
import { OutOfEstatePortfolioFormFields } from './OutOfEstatePortfolioFormModal.fields';
import { useOutOfEstatePortfolioFormDefaultValues } from './OutOfEstatePortfolioFormModal.utils';

interface OutOfEstatePortfolioFormModalProps {
  portfolioData: OutOfEstatePortfolioFormShape | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess: (formData: OutOfEstatePortfolioFormShape) => void;
  onDelete?: () => void;
}

function OutOfEstatePortfolioFormModalInner({
  portfolioData,
  isOpen,
  onClose,
  onSubmitSuccess,
  onDelete = () => null,
}: OutOfEstatePortfolioFormModalProps) {
  const isEdit = !!portfolioData;

  const { formRef, handleSave } = useFormSaveHandler();

  const { reset, handleSubmit, formState } =
    useFormContext<OutOfEstatePortfolioFormShape>();

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const heading = useMemo(() => {
    if (isEdit) {
      return 'Edit out-of-estate portfolio';
    }

    return 'Add an out-of-estate portfolio';
  }, [isEdit]);

  const actions = useMemo(() => {
    return (
      <FormModalActions.Provider<OutOfEstatePortfolioFormShape>
        formState={formState}
      >
        {isEdit && <FormModalActions.DeleteButton onConfirmDelete={onDelete} />}
        <FormModalActions.CancelButton onClick={handleClose} />
        <FormModalActions.SaveButton onClick={handleSave}>
          {isEdit ? 'Save edits' : 'Add portfolio'}
        </FormModalActions.SaveButton>
      </FormModalActions.Provider>
    );
  }, [formState, handleClose, handleSave, isEdit, onDelete]);

  const onSubmit = handleSubmit((formData) => {
    return onSubmitSuccess(formData);
  });

  useSubmitSuccessHandler(() => {
    handleClose();
  });

  return (
    <FormModal
      isOpen={isOpen}
      heading={heading}
      onClose={handleClose}
      actions={actions}
    >
      <Stack component="form" ref={formRef} onSubmit={onSubmit} noValidate>
        <FormLayoutRow>
          <FormLayoutItem>
            <OutOfEstatePortfolioFormFields.Name />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <OutOfEstatePortfolioFormFields.Amount />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <OutOfEstatePortfolioFormFields.PortfolioType />
          </FormLayoutItem>
        </FormLayoutRow>
      </Stack>
    </FormModal>
  );
}

export function OutOfEstatePortfolioFormModal(
  props: OutOfEstatePortfolioFormModalProps
) {
  const { portfolioData } = props;

  const { defaultValues } = useOutOfEstatePortfolioFormDefaultValues({
    portfolioData,
  });

  const formMethods = useForm<OutOfEstatePortfolioFormShape>({
    defaultValues,
    values: defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <OutOfEstatePortfolioFormModalInner {...props} />
    </FormProvider>
  );
}
