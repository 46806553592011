import { isNumber } from 'lodash';
import { useMemo } from 'react';

import { MAXIMUM_ROW_COUNT, PageSizes } from '../../constants';
import { InternalDataTableProps } from '../../types';

const pageSizeToStringVariant: Record<
  Exclude<NonNullable<InternalDataTableProps['pageSize']>, number>,
  number
> = {
  long: PageSizes.Twenty,
  short: PageSizes.Ten,
};

export const usePageSize = ({
  pageSize,
  hideFooter,
}: InternalDataTableProps) => {
  return useMemo(() => {
    if (isNumber(pageSize)) return Math.min(pageSize, MAXIMUM_ROW_COUNT);
    if (hideFooter) return MAXIMUM_ROW_COUNT;
    return pageSize ? pageSizeToStringVariant[pageSize] : PageSizes.Ten;
  }, [hideFooter, pageSize]);
};
