import { noop } from 'lodash';
import React from 'react';

type ModalState = Record<string, boolean>;
type SetModalContextCallback = (prevState: ModalState) => ModalState;
interface IModalContext {
  modalState: ModalState;
  setModalState: (setStateCallback: SetModalContextCallback) => void;
}
// This context keeps track of the modals on the page
// and whether they are open or closed.
export const ModalContext = React.createContext<IModalContext>({
  modalState: {},
  setModalState: noop,
});

export const ModalContextProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const [modalState, setModalState] = React.useState<Record<string, boolean>>(
    {}
  );

  return (
    <ModalContext.Provider
      value={{
        modalState,
        setModalState,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
