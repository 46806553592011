export function formatDateToMonDDYYYY(date: Date) {
  return date.toLocaleString('en-US', { dateStyle: 'medium' });
}

export function formatDateToMonYYYY(date: Date) {
  return date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
}

export function formatDateToMonDDYYYYWithTime(date: Date) {
  return date.toLocaleString('en-US', {
    timeStyle: 'short',
    dateStyle: 'medium',
  });
}

export function formatDateToMMDDYY(date: Date) {
  return date.toLocaleDateString('en-US', { dateStyle: 'short' });
}

export function formatDateToMMDD(date: Date) {
  return date.toLocaleString('en-US', { day: 'numeric', month: 'short' });
}

export function formatDateToTimestamp(date: Date): string {
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}
