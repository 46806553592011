import Decimal from 'decimal.js';

import { getZeroSignedNormalizedNumber } from './getZeroSignedNormalizedNumber';

export function commaDecimal(value: Decimal) {
  return new Intl.NumberFormat('en-US').format(
    getZeroSignedNormalizedNumber(value)
  );
}

export function commaNumber(value: number) {
  return new Intl.NumberFormat('en-US').format(value);
}
