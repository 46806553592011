import Decimal from 'decimal.js';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import {
  getDecimalJSSupportingOnChange,
  getFieldErrorValue,
  getPossiblyDecimalFieldValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';
import { commaNumber } from '@/utils/formatting/numbers';

import { HelpTextVariant } from '../baseInputs/inputTypes';
import { PercentInput } from '../baseInputs/PercentInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';
import { useValidateOnChange } from './hooks/useValidateOnChange';

export interface FormAwarePercentInputProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  control: Control<FormShape>;
  onFocus?: () => void;
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  required?: boolean;
  decimalScale?: number;
  maxValue?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  contextualHelp?: JSX.Element;
  isDecimalJSInput?: boolean;
  hideLabel?: boolean;
  validateOnChange?: boolean;
}

export function FormAwarePercentInput<FormShape extends FieldValues>({
  fieldName,
  label,
  helpText,
  helpTextVariant,
  required,
  control,
  onFocus,
  disabled,
  validation,
  decimalScale = 2,
  fixedDecimalScale,
  allowNegative = false,
  maxValue = 100,
  isDecimalJSInput,
  contextualHelp,
  hideLabel,
  validateOnChange,
}: FormAwarePercentInputProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, {
    ...validation,
    maxValue: (value) => {
      if (disabled) return undefined;
      const errorMessage = `${label} can't exceed ${commaNumber(maxValue)}%`;
      if (isDecimalJSInput && value) {
        const isOverLimit = (value as Decimal).greaterThan(
          new Decimal(maxValue)
        );
        if (isOverLimit) return errorMessage;
        return undefined;
      } else if (typeof value === 'number' && !isNaN(value)) {
        return value > maxValue ? errorMessage : undefined;
      }

      return undefined;
    },
  });

  useValidateOnChange<FormShape>(
    validateOnChange ?? false,
    fieldName,
    control,
    { valueType: 'decimal' }
  );

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <PercentInput
            label={label}
            onChange={getDecimalJSSupportingOnChange(field.onChange, {
              isDecimalJS: !!isDecimalJSInput,
            })}
            value={getPossiblyDecimalFieldValue(field.value, {
              isDecimalJS: !!isDecimalJSInput,
              fieldName: field.name,
              decimalScale,
            })}
            onBlur={field.onBlur}
            onFocus={onFocus}
            name={field.name}
            inputRef={field.ref}
            required={required}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            disabled={disabledFromContext ?? disabled}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            allowNegative={allowNegative}
            errorMessage={getFieldErrorValue(
              fieldState,
              formState.isSubmitted,
              validateOnChange ?? false
            )}
            contextualHelp={contextualHelp}
            hideLabel={hideLabel}
          />
        );
      }}
    />
  );
}
