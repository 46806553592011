// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { TaxDetail_TaxDetailFragmentDoc } from '../../../taxes/graphql/TaxDetail.generated';
export type GiftingProposalProjectionFragment = { __typename?: 'GiftingProposalProjections', inEstateValueAfterTax: CurrencyUSD, inEstateValueBeforeTax: CurrencyUSD, outOfEstateCharitableValue: CurrencyUSD, outOfEstateFamilyValue: CurrencyUSD, outOfEstateTotalValue: CurrencyUSD, taxableGift: CurrencyUSD, totalValue: CurrencyUSD, remainingLifetimeExemption: CurrencyUSD, lifetimeExemptionAppliedTowardGiftTax: CurrencyUSD, giftTax: CurrencyUSD, scenarioId: string, scenario: { __typename?: 'GiftingProposalScenario', order: number, displayName: string, isDefault: boolean, isBaseline: boolean, id: string }, estateTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, federalTax?: { __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null } | null, stateTax?: Array<{ __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null }> | null } };

export const GiftingProposalProjectionFragmentDoc = gql`
    fragment GiftingProposalProjection on GiftingProposalProjections {
  inEstateValueAfterTax
  inEstateValueBeforeTax
  outOfEstateCharitableValue
  outOfEstateFamilyValue
  outOfEstateTotalValue
  taxableGift
  totalValue
  remainingLifetimeExemption
  lifetimeExemptionAppliedTowardGiftTax
  giftTax
  scenarioId
  scenario {
    order
    displayName
    isDefault
    isBaseline @client
    id
  }
  estateTaxSummary {
    _cacheKey
    federalTax {
      ...TaxDetail_TaxDetail
    }
    stateTax {
      ...TaxDetail_TaxDetail
    }
  }
}
    ${TaxDetail_TaxDetailFragmentDoc}`;