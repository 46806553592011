import { useEffect } from 'react';

import { useUnloadPromptContext } from '@/navigation/unloadPrompt.context';

/**
 * @description This hook is used to prompt the user if they try to close the tab or reload the page.
 * It interacts with the global UnloadPromptContext to determine if the user should be prompted.
 * @param shouldBlock - This boolean is used to determine if the user should be prompted.
 * If true, the user will be prompted. If false, the user will not be prompted.
 * @returns {shouldBlockNavigation, setShouldBlockNavigation} - This function is used to override the blocking behavior of the hook.
 */
export const useOnBeforeUnloadPrompt = (shouldBlock: boolean) => {
  const { shouldBlockNavCtx: shouldBlockNavCtx, setShouldBlockNavCtx } =
    useUnloadPromptContext();

  useEffect(() => {
    setShouldBlockNavCtx(shouldBlock);
    return () => {
      setShouldBlockNavCtx(false);
    };
  }, [setShouldBlockNavCtx, shouldBlock]);

  return {
    shouldBlockNavigation: shouldBlockNavCtx,
    setShouldBlockNavigation: setShouldBlockNavCtx,
  };
};
