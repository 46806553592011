export function redirectToBackendPath(relativePath: string) {
  // TBD if this is required, but generally not allowing for accidental off-domain redirects is a good
  // security practice
  if (!relativePath.startsWith('/')) {
    throw new Error('Cannot redirect to a non-Luminary application.');
  }

  const host = window.location.origin;
  window.location.href = `${host}${relativePath}`;
}

export function getBackendAuthenticationEndpoint() {
  return '/login';
}

export function redirectToLogin() {
  return redirectToBackendPath(getBackendAuthenticationEndpoint());
}

export function getSearchParamsObject(
  searchParams: URLSearchParams
): Record<string, unknown> {
  const res: Record<string, unknown> = {};

  searchParams.forEach((value, key) => {
    res[key] = value;
  });

  return res;
}
