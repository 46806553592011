import { SyntheticEvent, useCallback, useRef } from 'react';

/**
 * Handles the form submission, when the "submit" button is not in the same component as the form.
 */
export const useFormSaveHandler = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleSave = useCallback(
    (event?: SyntheticEvent) => {
      formRef?.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
      event?.stopPropagation();
      event?.preventDefault();
    },
    [formRef]
  );

  return { formRef, handleSave };
};
