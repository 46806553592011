import { SelectChangeEvent } from '@mui/material';
import { compact } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, Path, useWatch } from 'react-hook-form';

import { SelectInput } from '@/components/form/baseInputs/SelectInput';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useFormContext } from '@/components/react-hook-form';
import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { GiftGrowthRate } from '@/modules/content/tooltipContent/GiftGrowthRate';
import {
  GiftingProposalGiftNonTaxableGiftType,
  GiftingProposalPortfolioPortfolioType,
} from '@/types/schema';

import { ScenarioGiftFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { validateYearRange } from '../../GiftDesignerModelScenariosForm.utils';
import { portfolioTypeOptions } from '../OutOfEstatePortfolioFormModal/OutOfEstatePortfolioFormModal.utils';
import { useScenarioGiftRecipientOptions } from './useScenarioGiftRecipientOptions';
import { useScenarioGiftTypeOptions } from './useScenarioGiftTypeOptions';

type ScenarioGiftField = Path<ScenarioGiftFormShape>;

function Name() {
  const {
    control,
    formState: { isLoading },
  } = useFormContext<ScenarioGiftFormShape>();

  return (
    <FormAwareTextInput<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-name"
      control={control}
      fieldName={'displayName' as const satisfies ScenarioGiftField}
      label="Name"
      disabled={isLoading}
      required
    />
  );
}

function Recipient({ proposalId }: { proposalId: string }) {
  const {
    control,
    getValues,
    setValue,
    formState: { isLoading: isFormLoading },
  } = useFormContext<ScenarioGiftFormShape>();

  const { recipientOptions, loading: isOptionsLoading } =
    useScenarioGiftRecipientOptions(proposalId);

  const selectedRecipientId = useWatch({
    control,
    name: 'recipientId',
  });

  const isNewRecipient = useWatch({
    control,
    name: 'isNewRecipient',
  });

  useEffect(
    function handleSelectRecipient() {
      const recipientKind = getValues('recipientKind');
      const selectedRecipientKind = recipientOptions.find((o) => {
        if ('value' in o) {
          return o.value === selectedRecipientId;
        }
        return false;
      })?.kind;

      if (recipientKind !== selectedRecipientKind && selectedRecipientKind) {
        setValue('recipientKind', selectedRecipientKind);
      }
    },
    [getValues, recipientOptions, selectedRecipientId, setValue]
  );

  return (
    <FormAwareSelectInput<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-recipient"
      control={control}
      fieldName={'recipientId' as const satisfies ScenarioGiftField}
      label="Recipient"
      disabled={isFormLoading || isOptionsLoading || isNewRecipient}
      options={recipientOptions}
      required={!isNewRecipient}
    />
  );
}

function Discount() {
  const { control } = useFormContext<ScenarioGiftFormShape>();

  return (
    <FormAwareSwitch<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-discount-toggle"
      control={control}
      fieldName={'discount' as const satisfies ScenarioGiftField}
      labelPosition="right"
      label="Discount"
      required // Required since we are in optional-label mode
      validation={{
        // Overrwite the default required validation which treats false as invalid
        required: () => undefined,
      }}
    />
  );
}

function DiscountPercent() {
  const { control } = useFormContext<ScenarioGiftFormShape>();

  return (
    <FormAwarePercentInput<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-discount-percent"
      control={control}
      fieldName={'discountPercent' as const satisfies ScenarioGiftField}
      label=""
      isDecimalJSInput
      decimalScale={0}
      maxValue={100}
    />
  );
}

function Amount() {
  const { control } = useFormContext<ScenarioGiftFormShape>();

  return (
    <FormAwareCurrencyInput<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-amount"
      control={control}
      fieldName={'amount' as const satisfies ScenarioGiftField}
      isDecimalJSInput
      decimalScale={2}
      label="Amount"
      required
    />
  );
}

// Need to use a custom type here because the radio group form expects value to be a string
export enum AnnuallyRecurringValue {
  true = 'isAnnuallyRecurring',
  false = 'isOneTime',
}

function AnnuallyRecurring() {
  const { control } = useFormContext<ScenarioGiftFormShape>();

  return (
    <FormAwareRadioGroup<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-annually-recurring"
      control={control}
      fieldName={'annuallyRecurring' as const satisfies ScenarioGiftField}
      options={[
        {
          label: 'Annually recurring',
          value: AnnuallyRecurringValue.true,
        },
        {
          label: 'One-time',
          value: AnnuallyRecurringValue.false,
        },
      ]}
      row={true}
      required
    />
  );
}

function StartYear({ isAnnuallyRecurring }: { isAnnuallyRecurring: boolean }) {
  const { control } = useFormContext<ScenarioGiftFormShape>();

  const [lengthOfAnalysis, endYear, annuallyRecurring] = useWatch({
    control,
    name: ['lengthOfAnalysis', 'endYear', 'annuallyRecurring'],
  });

  const minMax = useCallback(
    (value: unknown) => {
      if (annuallyRecurring === AnnuallyRecurringValue.true) {
        return validateYearRange(value, lengthOfAnalysis, {
          ltEq: endYear,
        });
      }
      return undefined;
    },
    [annuallyRecurring, endYear, lengthOfAnalysis]
  );

  return (
    <FormAwareFormattedNumberInput<ScenarioGiftFormShape>
      control={control}
      data-testid="scenario-gift-modal-start-year"
      label={isAnnuallyRecurring ? 'Start year' : 'Year of gift'}
      fieldName={'startYear' as const satisfies ScenarioGiftField}
      fixedDecimalScale={true}
      decimalScale={0}
      required
      isDecimalJSInput
      validation={{ minMax }}
    />
  );
}

function EndYear() {
  const { control } = useFormContext<ScenarioGiftFormShape>();

  const [lengthOfAnalysis, startYear, annuallyRecurring] = useWatch({
    control,
    name: ['lengthOfAnalysis', 'startYear', 'annuallyRecurring'],
  });

  const minMax = useCallback(
    (value: unknown) => {
      if (annuallyRecurring === AnnuallyRecurringValue.true) {
        return validateYearRange(value, lengthOfAnalysis, {
          gtEq: startYear,
        });
      }

      return undefined;
    },
    [annuallyRecurring, lengthOfAnalysis, startYear]
  );

  return (
    <FormAwareFormattedNumberInput<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-end-year"
      control={control}
      label="End year"
      fieldName={'endYear' as const satisfies ScenarioGiftField}
      fixedDecimalScale={true}
      decimalScale={0}
      required
      isDecimalJSInput
      validation={{ minMax }}
    />
  );
}

function GrowthRate() {
  const { control } = useFormContext<ScenarioGiftFormShape>();

  return (
    <FormAwarePercentInput<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-growth-rate"
      control={control}
      label={'Growth rate'}
      fieldName={'growthPercentage' as const satisfies ScenarioGiftField}
      isDecimalJSInput
      contextualHelp={<GiftGrowthRate />}
      required
    />
  );
}

// Gift type aggregates multiple form fields into one select input
function GiftType({ proposalId }: { proposalId: string }) {
  const { typeOptions: grantors, loading: isLoadingOptions } =
    useScenarioGiftTypeOptions(proposalId);

  const {
    control,
    formState: { isLoading: isLoadingForm },
    setValue,
  } = useFormContext<ScenarioGiftFormShape>();

  const isTaxable = useWatch({
    control,
    name: 'isTaxable',
  });

  const nonTaxableGiftType = useWatch({
    control,
    name: 'nonTaxableGiftType',
  });

  const senderIds = useWatch({
    control,
    name: 'senderIds',
  });

  const formStateGiftType = useWatch({
    control,
    name: 'giftType',
  });

  // Option values are either 'both', 'annualExclusionGift', 'charitableGift', or a grantor id
  const options = useMemo(() => {
    return compact([
      {
        component: <SelectItemGroupLabel label="Taxable gift from" />,
        type: 'component' as const,
      },
      grantors.length === 2
        ? {
            value: 'both',
            display: `${grantors[0]?.display} and ${grantors[1]?.display}`,
          }
        : null,
      ...grantors.map((grantor) => ({
        value: grantor.value,
        display: grantor.display,
      })),
      {
        component: <SelectItemGroupLabel label="Non-taxable gifts" />,
        type: 'component' as const,
      },
      {
        display: 'Annual exclusion gift',
        value: 'annualExclusionGift',
      },
      {
        display: 'Charitable gift',
        value: 'charitableGift',
      },
    ]);
  }, [grantors]);

  // Set the form state based on the selected value
  const handleGiftTypeChange = useCallback(
    (e: SelectChangeEvent<string | undefined>) => {
      const selectedValue = e.target.value;

      if (!selectedValue) {
        setValue('giftType', undefined);
        return;
      }

      setValue('giftType', selectedValue);

      switch (selectedValue) {
        case 'both':
          setValue('isTaxable', true);
          setValue('nonTaxableGiftType', null);
          setValue('senderIds', [
            grantors[0]?.value ?? '',
            grantors[1]?.value ?? '',
          ]);
          return;

        case 'annualExclusionGift':
          setValue('isTaxable', false);
          setValue(
            'nonTaxableGiftType',
            GiftingProposalGiftNonTaxableGiftType.AnnualExclusion
          );
          setValue('senderIds', []);
          return;

        case 'charitableGift':
          setValue('isTaxable', false);
          setValue(
            'nonTaxableGiftType',
            GiftingProposalGiftNonTaxableGiftType.Charitable
          );
          setValue('senderIds', []);
          return;

        default:
          setValue('isTaxable', true);
          setValue('nonTaxableGiftType', null);
          setValue('senderIds', [selectedValue]);
      }
    },
    [grantors, setValue]
  );

  // Derive the selected value from the form state
  const value = useMemo(() => {
    if (isTaxable) {
      if (senderIds.length === 2) {
        return 'both';
      }

      return senderIds[0];
    } else {
      if (
        nonTaxableGiftType ===
        GiftingProposalGiftNonTaxableGiftType.AnnualExclusion
      ) {
        return 'annualExclusionGift';
      }

      if (
        nonTaxableGiftType === GiftingProposalGiftNonTaxableGiftType.Charitable
      ) {
        return 'charitableGift';
      }

      return '';
    }
  }, [isTaxable, nonTaxableGiftType, senderIds]);

  useEffect(
    function syncLocalValueWithFormState() {
      // If the form state gift type is not the same as the value, we need to
      // sync the value with the form state. This can happen on form
      // initialization.
      if (formStateGiftType !== value) {
        setValue('giftType', value);
      }
    },
    [formStateGiftType, setValue, value]
  );

  const helpText = useMemo(() => {
    if (!value) {
      return '';
    }

    if (isTaxable) {
      return 'Taxable gift';
    }

    return 'Non-taxable gift';
  }, [isTaxable, value]);

  const label = 'Gift type';
  const validations = getValidations(label, true);

  // Because this field controls a number of fields on the form state
  // through setValue, we need to control it independently so we get
  // the correct validation errors
  return (
    <Controller
      name="giftType"
      control={control}
      defaultValue={undefined}
      rules={{
        validate: validations,
      }}
      render={({ fieldState, formState }) => (
        <SelectInput
          data-testid="scenario-gift-modal-gift-type"
          label={label}
          disabled={isLoadingForm || isLoadingOptions}
          options={options}
          onChange={(e) => handleGiftTypeChange(e)}
          value={value}
          required
          helpText={helpText}
          errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
        />
      )}
    />
  );
}

function IsNewRecipient() {
  const { control, setValue } = useFormContext<ScenarioGiftFormShape>();

  const isNewRecipient = useWatch({
    control,
    name: 'isNewRecipient',
  });

  const selectedRecipientId = useWatch({
    control,
    name: 'recipientId',
  });

  const newRecipientName = useWatch({
    control,
    name: 'newRecipientName',
  });

  const newRecipientType = useWatch({
    control,
    name: 'newRecipientType',
  });

  useEffect(() => {
    // Effect for clearing the recipientId field when the user toggles on
    if (isNewRecipient && selectedRecipientId) {
      setValue('recipientId', '');
    }
  }, [isNewRecipient, selectedRecipientId, setValue]);

  useEffect(() => {
    // Effect for clearing the new recipient fields when the user toggles off
    if (!isNewRecipient && newRecipientName) {
      setValue('newRecipientName', '');
    }
    if (!isNewRecipient && newRecipientType) {
      setValue('newRecipientType', '');
    }
  }, [isNewRecipient, newRecipientName, newRecipientType, setValue]);

  return (
    <FormAwareSwitch<ScenarioGiftFormShape>
      control={control}
      data-testid="scenario-gift-modal-is-new-recipient-toggle"
      label="New recipient"
      fieldName={'isNewRecipient' as const satisfies ScenarioGiftField}
      labelPosition="right"
      required
      validation={{
        // Overrwite the default required validation which treats false as invalid
        required: () => undefined,
      }}
    />
  );
}

function NewRecipientType() {
  const { control } = useFormContext<ScenarioGiftFormShape>();

  const selectedNewRecipientType = useWatch({
    control,
    name: 'newRecipientType',
  });

  const helpText = useMemo(() => {
    const porfolioTypeName = portfolioTypeOptions.find(
      (option) => option.value === selectedNewRecipientType
    )?.display;

    switch (selectedNewRecipientType) {
      case GiftingProposalPortfolioPortfolioType.Charitable:
        return `${porfolioTypeName}: The portfolio will grow by the full assumed pre-tax return`;
      case GiftingProposalPortfolioPortfolioType.GrantorTrust:
        return `${porfolioTypeName}: The portfolio will grow by the full assumed pre-tax return, and any tax generated from investment income will be deducted from the client's estate each year`;
      case GiftingProposalPortfolioPortfolioType.NonGrantorIndividual:
        return `${porfolioTypeName}: The portfolio will grow by the assumed after-tax return, as the portfolio will use investment returns to pay any tax generated from investment income`;
      case GiftingProposalPortfolioPortfolioType.NonGrantorTrust:
        return `${porfolioTypeName}: The portfolio will grow by the assumed after-tax return, as the portfolio will use investment returns to pay any tax generated from investment income`;
      default:
        return '';
    }
  }, [selectedNewRecipientType]);

  return (
    <FormAwareSelectInput<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-new-recipient-type"
      control={control}
      fieldName={'newRecipientType' as const satisfies ScenarioGiftField}
      label="Portfolio type"
      options={portfolioTypeOptions}
      required
      helpText={helpText}
    />
  );
}

function NewRecipientName() {
  const { control, setValue } = useFormContext<ScenarioGiftFormShape>();

  const isNewRecipient = useWatch({
    control,
    name: 'isNewRecipient',
  });

  const selectedRecipientId = useWatch({
    control,
    name: 'recipientId',
  });

  useEffect(() => {
    if (isNewRecipient && selectedRecipientId) {
      setValue('recipientId', '');
    }
  }, [isNewRecipient, selectedRecipientId, setValue]);

  return (
    <FormAwareTextInput<ScenarioGiftFormShape>
      data-testid="scenario-gift-modal-new-recipient-name"
      control={control}
      fieldName={'newRecipientName' as const satisfies ScenarioGiftField}
      label="Name"
      required
    />
  );
}

export const ScenarioIncomeAndExpensesFormFields = {
  Name,
  Recipient,
  Discount,
  DiscountPercent,
  Amount,
  AnnuallyRecurring,
  StartYear,
  EndYear,
  GrowthRate,
  GiftType,
  IsNewRecipient,
  NewRecipientType,
  NewRecipientName,
};
