import { Box, SxProps, Theme, Typography } from '@mui/material';
import React, { ComponentProps } from 'react';

import {
  NOTIFICATION_THEME_BY_SEVERITY,
  NotificationSeverity,
} from '../constants';

export type CalloutProps = {
  severity: NotificationSeverity;
  textVariant?: ComponentProps<typeof Typography>['variant'];
  sx?: SxProps<Theme>;
} & React.HTMLProps<HTMLDivElement>;

export const Callout = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CalloutProps>
>(
  (
    { severity, textVariant, children, sx = {}, ref: _ref, ...htmlProps },
    ref
  ) => {
    const theme = NOTIFICATION_THEME_BY_SEVERITY[severity];
    return (
      <Box
        ref={ref}
        sx={{
          py: 0.5,
          pl: 2,
          display: 'flex',
          borderLeft: `solid 4px ${theme.border}`,
          alignItems: 'center',
          ...sx,
        }}
        {...htmlProps}
      >
        <Typography
          component="div"
          variant={textVariant ?? 'subtitle1'}
          color={theme.text}
        >
          {children}
        </Typography>
      </Box>
    );
  }
);

Callout.displayName = 'Callout';
