import { cx } from '@emotion/css';
import { Box, styled, SxProps, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';

interface HighlightOffsets {
  width?: number;
  height?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
}

const DEFAULT_HIGHLIGHT_OFFSETS = {
  width: 12,
  height: 3.5,
  marginTop: -1.5,
  marginBottom: -2,
  marginLeft: -6,
  marginRight: -6,
};

const useHighlightedStyles = makeStyles<HighlightOffsets | undefined>()((
  theme,
  highlightOffsets
) => {
  const offsets = {
    ...DEFAULT_HIGHLIGHT_OFFSETS,
    ...highlightOffsets,
  };

  return {
    highlight: {
      position: 'relative', // Needed for absolute positioning of the ::after pseudo-element
      '&::after': {
        // This is how you define a pseudo-element in JSS
        content: '""', // The content property is required for ::after, even if it's an empty string
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: COLORS.TEAL[100],
        zIndex: 1,
        // Extend the element as needed
        width: `calc(100% + ${theme.spacing(offsets.width)})`,
        height: `calc(100% + ${theme.spacing(offsets.height)})`,
        marginTop: theme.spacing(offsets.marginTop),
        marginBottom: theme.spacing(offsets.marginBottom),
        marginLeft: theme.spacing(offsets.marginLeft),
        marginRight: theme.spacing(offsets.marginRight),
      },
      '& > *': {
        // This targets all direct children of the element with this class
        zIndex: 2,
        position: 'relative',
      },
    },
  };
});

type ColumnWidths = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type FormLayoutProps = React.PropsWithChildren<{
  sx?: SxProps<Theme>;
  classes?: string;
  highlighted?: boolean;
  highlightOffsets?: HighlightOffsets;
  columns?: ColumnWidths;
}>;

const StyledBox = styled(Box)(({ theme }) => ({
  ':not(:last-child)': {
    marginBottom: theme.spacing(3),
  },
}));

const NUM_COLUMNS = 12;

export function FormLayoutRow({
  children,
  sx,
  classes: inputClasses,
  highlighted,
  highlightOffsets,
  columns = NUM_COLUMNS,
}: FormLayoutProps) {
  const { classes } = useHighlightedStyles(highlightOffsets);

  return (
    <StyledBox
      data-it={`FormLayoutRow`}
      sx={{
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: 3,
        ...sx,
      }}
      className={cx(inputClasses, {
        [classes.highlight]: highlighted,
      })}
    >
      {children}
    </StyledBox>
  );
}

export type FormLayoutItemProps = React.PropsWithChildren<{
  sx?: SxProps<Theme>;
  width?: ColumnWidths;
  classes?: string;
}>;

export function FormLayoutItem({
  width = NUM_COLUMNS,
  children,
  sx = {},
}: FormLayoutItemProps) {
  return (
    <Box sx={{ gridRow: '1', gridColumn: `span ${width}`, ...sx }}>
      {children}
    </Box>
  );
}
