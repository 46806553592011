import { useEffect, useRef } from 'react';

export function useEventListener(
  eventName: keyof WindowEventMap,
  handler: EventListener,
  element: HTMLElement | Window | Document | null = window
) {
  const savedHandler = useRef<EventListener>(handler);

  // Updating the ref if the handler changes allows our effect below to always
  // reference the latest handler without us needing to pass it in the effect deps array
  // and potentially causing the effect to re-run on every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(() => {
    // null is supported so that if you want to pass a ref,
    // we handly it neatly for you.
    if (element === null) return;
    const isSupported = element?.addEventListener;
    if (!isSupported)
      throw new Error(
        `Attempting to add an event listener to an unsupported element.`
      );

    const eventListener: EventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    // Make sure we're cleaning up the event listener to avoid leaks! (This is basically the
    // entire purpose of this hook.)
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
