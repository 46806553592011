import { SxProps, Theme } from '@mui/material';
import { useState } from 'react';

import { Trash01Icon } from '@/components/icons/Trash01Icon';

import { Button, ButtonProps } from './Button';

// omit variant because we only want to support the destructive variant
export interface DeleteButtonProps
  extends Omit<ButtonProps, 'variant' | 'size'> {
  sx?: SxProps<Theme>;
  confirmDeleteText?: string;
  promptDeleteText?: string;
  onConfirmDelete: () => void;
}

export function DeleteButton({
  onConfirmDelete,
  confirmDeleteText = 'Confirm delete',
  promptDeleteText,
  ...otherProps
}: DeleteButtonProps) {
  const [isPendingConfirmDelete, setIsPendingConfirmDelete] = useState(false);
  function handleClick() {
    if (isPendingConfirmDelete) {
      onConfirmDelete();
    } else {
      setIsPendingConfirmDelete(true);
    }
  }

  return (
    <Button
      size="sm"
      variant={isPendingConfirmDelete ? 'destructive-prominent' : 'destructive'}
      onClick={handleClick}
      {...otherProps}
      sx={{}}
    >
      {isPendingConfirmDelete
        ? confirmDeleteText
        : promptDeleteText || <Trash01Icon size={16} />}
    </Button>
  );
}
