import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  FormControlLabelPosition,
  HelpTextVariant,
  SwitchVariant,
} from '../baseInputs/inputTypes';
import { Switch } from '../baseInputs/Switch';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwareSwitchProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  required?: boolean;
  control: Control<FormShape>;
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  labelPosition?: FormControlLabelPosition;
  variant?: SwitchVariant;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
}

export function FormAwareSwitch<FormShape extends FieldValues>({
  fieldName,
  label,
  required,
  control,
  disabled,
  labelPosition,
  variant,
  validation,
  helpText,
  helpTextVariant,
  contextualHelp,
  ...inputProps
}: FormAwareSwitchProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, validation);

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <Switch
            label={label}
            labelPosition={labelPosition}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            inputRef={field.ref}
            required={required}
            disabled={disabledFromContext ?? disabled}
            variant={variant}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            contextualHelp={contextualHelp}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            {...inputProps}
          />
        );
      }}
    />
  );
}
