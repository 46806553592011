import { Box } from '@mui/material';

import { Trash01Icon } from '@/components/icons/Trash01Icon';
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@/components/modals/ConfirmationModal/ConfirmationModal';
import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';
import { useModalState } from '@/hooks/useModalState';
import { COLORS } from '@/styles/tokens/colors';

export interface DeleteMenuItemWithConfirmationModalProps {
  label: React.ReactNode;
  menuItemProps?: Partial<MenuItemProps>;
  modalProps?: Partial<ConfirmationModalProps>;
  onConfirmDelete: () => void;
}

export function DeleteMenuItemWithConfirmationModal({
  onConfirmDelete,
  label,
  menuItemProps = {},
  modalProps = {},
}: DeleteMenuItemWithConfirmationModalProps) {
  const [{ isModalOpen }, { setModalIsOpen }] = useModalState();

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        heading="Are you sure you want to delete this?"
        cancelText="Cancel"
        confirmText="Delete"
        confirmButtonProps={{ variant: 'destructive-prominent' }}
        {...modalProps}
        onClose={(result) => {
          if (result === 'confirm') {
            onConfirmDelete();
          }
          setModalIsOpen(false);
        }}
      />
      <MenuItem
        icon={<Trash01Icon color={COLORS.FUNCTIONAL.ERROR.DEFAULT} size={18} />}
        label={<Box color={COLORS.FUNCTIONAL.ERROR.DEFAULT}>{label}</Box>}
        onClick={(event) => {
          event.stopPropagation();
          setModalIsOpen(true);
        }}
        {...menuItemProps}
      />
    </>
  );
}
