import { Box } from '@mui/material';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import {
  DeleteButton,
  DeleteButtonProps,
} from '@/components/form/baseInputs/Button/DeleteButton';

import {
  FormModalActionsProvider,
  useFormModalActionsContext,
} from './FormModalActions.provider';

export type FormModalCancelButtonProps = Partial<ButtonProps>;

export const FormModalCancelButton = ({
  size = 'sm',
  variant = 'secondary',
  children = 'Cancel',
  disabled,
  ...props
}: FormModalCancelButtonProps) => {
  const formCtx = useFormModalActionsContext();
  return (
    <Button
      disabled={disabled ?? formCtx?.disabled}
      size={size}
      variant={variant}
      {...props}
    >
      {children}
    </Button>
  );
};

export type FormModalSaveButtonProps = Partial<ButtonProps>;
export const FormModalSaveButton = ({
  size = 'sm',
  variant = 'primary',
  children = 'Save',
  loading,
  disabled,
  ...props
}: FormModalSaveButtonProps) => {
  const formCtx = useFormModalActionsContext();
  return (
    <Button
      loading={loading || formCtx?.isSubmitting}
      disabled={disabled || formCtx?.disabled}
      size={size}
      variant={variant}
      {...props}
    >
      {children}
    </Button>
  );
};

export type FormModalDeleteButtonProps = DeleteButtonProps;
export const FormModalDeleteButton = ({
  loading,
  disabled,
  ...props
}: FormModalDeleteButtonProps) => {
  const formCtx = useFormModalActionsContext();
  return (
    <>
      <DeleteButton
        loading={loading || formCtx?.isDeleting}
        disabled={disabled || formCtx?.disabled}
        {...props}
      />
      <Box flexGrow={1} />
    </>
  );
};

export type FormModalDestructiveButtonProps = Partial<ButtonProps>;
export const FormModalDestructiveButton = ({
  size = 'sm',
  variant = 'destructive',
  children = 'Destroy',
  loading,
  disabled,
  ...props
}: FormModalDestructiveButtonProps) => {
  const formCtx = useFormModalActionsContext();
  return (
    <>
      <Button
        loading={loading || formCtx?.isSubmitting}
        disabled={disabled || formCtx?.disabled}
        size={size}
        variant={variant}
        {...props}
      >
        {children}
      </Button>
      <Box flexGrow={1} />
    </>
  );
};

export const FormModalActions = {
  Provider: FormModalActionsProvider,
  CancelButton: FormModalCancelButton,
  SaveButton: FormModalSaveButton,
  DeleteButton: FormModalDeleteButton,
  DestructiveButton: FormModalDestructiveButton,
};
