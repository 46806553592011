import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { GiftScenarioTab } from '../components/GiftScenarios/GiftScenarioTabContent';
import { GetGiftDesignerModelScenariosFormData_ProposalFragment } from '../graphql/GetGiftDesignerModelScenariosFormData.generated';

type ScenarioTabState = Record<string, GiftScenarioTab>;

export interface GiftDesignerModelScenariosContext {
  proposal:
    | GetGiftDesignerModelScenariosFormData_ProposalFragment['giftingProposal']
    | null;
  setProposal: Dispatch<
    SetStateAction<GiftDesignerModelScenariosContext['proposal']>
  >;
  scenarioTabState: ScenarioTabState;
  setScenarioTabState: Dispatch<
    SetStateAction<GiftDesignerModelScenariosContext['scenarioTabState']>
  >;
}

export const GiftDesignerModelScenariosContext =
  createContext<GiftDesignerModelScenariosContext>({
    proposal: null,
    setProposal: () => null,
    // Hold the tab state, i.e., Gift details or Cash flow, in elevated state because when the form is submitted,
    // the tab state is reset to the default value, which is Gift details. This is because the form state has
    // a dependency on IDs generated by the backend. See the reset in useSaveGiftDesigner.
    scenarioTabState: {},
    setScenarioTabState: () => null,
  });

export const useGiftDesignerModelScenariosContext = () => {
  return useGuardedContext(
    GiftDesignerModelScenariosContext,
    'GiftDesignerModelScenariosContext'
  );
};
