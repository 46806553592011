import { Box, styled, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { WarningIndicator } from '@/components/notifications/WarningIndicator/WarningIndicator';

import { activeStyle, commonTabStyle } from './Tabs.utils';

const StyledNavLink = styled(NavLink)(() => ({
  ...commonTabStyle,
  textDecoration: 'none',
}));

export interface LinkTabProps {
  to: string;
  display: string;
  isActive?: boolean;
  showWarningBadge?: boolean;
  dataTestId?: string;
}

export function LinkTab({
  to,
  display,
  isActive: isActiveOverride,
  dataTestId,
  showWarningBadge,
}: LinkTabProps) {
  return (
    <StyledNavLink
      data-testid={dataTestId}
      to={to}
      style={({ isActive }) => ({
        ...(isActive || isActiveOverride ? activeStyle : {}),
        position: 'relative',
      })}
    >
      <Typography variant="button">{display}</Typography>
      {showWarningBadge && (
        <Box sx={{ position: 'absolute', top: -10, right: -10 }}>
          <WarningIndicator size={16} />
        </Box>
      )}
    </StyledNavLink>
  );
}
