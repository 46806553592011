import { Box, SxProps, Theme } from '@mui/material';

import WarningIndicatorSVG from './warningIndicator.svg';

interface WarningIndicatorProps extends React.HTMLAttributes<HTMLSpanElement> {
  size: number;
  sx?: SxProps<Theme>;
}

export function WarningIndicator({
  size,
  sx = {},
  ...htmlProps
}: WarningIndicatorProps) {
  return (
    <Box
      component="span"
      sx={{
        height: size,
        display: 'inline-block',
        overflow: 'hidden',
        flexShrink: 0,
        userSelect: 'none',
        textAlign: 'center',
        ...sx,
      }}
      {...htmlProps}
    >
      <WarningIndicatorSVG height={`${size}px`} />
    </Box>
  );
}
