import { Stack } from '@mui/material';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  unstable_Blocker as NavigationBlocker,
  unstable_BlockerFunction as BlockerFunction,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';

import { UnloadPromptContext } from './unloadPrompt.context';

export const CONFIRMATION_MESSAGE = 'You have unsaved changes. Continue?';

function ConfirmNavigation({
  blocker,
  setShouldBlockNavCtx,
}: {
  blocker: NavigationBlocker;
  setShouldBlockNavCtx: UnloadPromptContext['setShouldBlockNavCtx'];
}) {
  if (blocker.state === 'blocked') {
    return (
      <DialogModal
        isOpen={true}
        onClose={() => blocker.reset?.()}
        heading="You have unsaved changes. Continue?"
      >
        <Stack direction="row" justifyContent="center" spacing={3}>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => blocker.reset?.()}
          >
            Keep me here
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              // Why setTimeout?
              // https://github.com/remix-run/react-router/blob/f9b3dbd9cbf513366c456b33d95227f42f36da63/packages/react-router-dom/index.tsx#L1475C16-L1475C16
              // eslint-disable-next-line @typescript-eslint/unbound-method -- is not unbound
              setTimeout(blocker.proceed, 0);
              setShouldBlockNavCtx(false);
            }}
          >
            Continue
          </Button>
        </Stack>
      </DialogModal>
    );
  }

  return null;
}

function Blocker({
  shouldBlockNavCtx,
  setShouldBlockNavCtx,
}: {
  shouldBlockNavCtx: boolean;
  setShouldBlockNavCtx: Dispatch<SetStateAction<boolean>>;
}) {
  const blockerFn = useCallback<BlockerFunction>(
    (navigationEvent) => {
      if (!navigationEvent) {
        return shouldBlockNavCtx;
      }

      if (navigationEvent.currentLocation && navigationEvent.nextLocation) {
        if (!shouldBlockNavCtx) {
          return false;
        }

        if (
          navigationEvent.currentLocation.pathname ===
          navigationEvent.nextLocation.pathname
        ) {
          // If the user is navigating to the same page, we don't want to prompt them
          return false;
        }

        // If the user is navigating to a different page, we want to prompt them
        return true;
      }

      return false;
    },
    [shouldBlockNavCtx]
  );

  const blocker = useBlocker(blockerFn);

  return (
    <>
      {blocker ? (
        <ConfirmNavigation
          blocker={blocker}
          setShouldBlockNavCtx={setShouldBlockNavCtx}
        />
      ) : null}
    </>
  );
}

/**
 * @description This HOC is used to house a single instance of usePrompt, which as of July 2023
 * only supports a single instance of usePrompt per app. This is a workaround for that.
 */
export function UnloadPromptProvider({ children }: PropsWithChildren<unknown>) {
  const [shouldBlockNavCtx, setShouldBlockNavCtx] =
    useState<UnloadPromptContext['shouldBlockNavCtx']>(false);
  const [isHandledByBrowser, setIsHandledByBrowser] = useState(false);

  useEffect(() => {
    return () => {
      setShouldBlockNavCtx(false);
    };
  }, [setShouldBlockNavCtx]);

  // this useEffect will prompt the user if they try to close the tab or reload the page
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      setIsHandledByBrowser(true);
      if (shouldBlockNavCtx) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      setIsHandledByBrowser(false);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldBlockNavCtx]);

  return (
    <UnloadPromptContext.Provider
      value={{
        shouldBlockNavCtx,
        setShouldBlockNavCtx,
      }}
    >
      {!isHandledByBrowser && (
        <Blocker
          shouldBlockNavCtx={shouldBlockNavCtx}
          setShouldBlockNavCtx={setShouldBlockNavCtx}
        />
      )}
      {children}
    </UnloadPromptContext.Provider>
  );
}
