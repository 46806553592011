import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  SxProps,
  Theme,
} from '@mui/material';
import { ComponentProps } from 'react';

import { COLORS } from '@/styles/tokens/colors';

interface Props {
  heading?: string;
  body: React.ReactNode;
  sx?: SxProps<Theme>;
  actions?: React.ReactNode;
  actionsSx?: ComponentProps<typeof DialogActions>['sx'];
}

export function PopperContent({
  heading,
  body,
  sx,
  actions,
  actionsSx = {},
}: Props) {
  return (
    <Paper
      sx={{
        maxWidth: 400,
        overflow: 'auto',
        border: 'none',
        backgroundColor: COLORS.PRIMITIVES.WHITE,
        boxShadow: 'none',
        px: 1.5,
        py: 1.5,
        borderRadius: 2,
        ...sx,
      }}
    >
      {heading && (
        <DialogTitle
          sx={{
            padding: 0,
            paddingBottom: 0.5,
          }}
          component="span"
          variant="h6"
        >
          {heading}
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          p: 0,
          paddingBottom: actions ? 1.5 : 0,
        }}
      >
        <DialogContentText component="div" variant="subtitle2">
          {body}
        </DialogContentText>
      </DialogContent>
      {actions && (
        <DialogActions
          sx={{
            padding: 0,
            marginBottom: 2,
            marginRight: 2,
            ...actionsSx,
          }}
        >
          {actions}
        </DialogActions>
      )}
    </Paper>
  );
}
