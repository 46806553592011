import {
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';

import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

import { Button, ButtonProps } from '../../form/baseInputs/Button';
import { Dialog } from '../Dialog';

export type ConfirmationModalProps = React.PropsWithChildren<{
  isOpen?: boolean;
  heading: string;
  cancelText?: string;
  confirmText?: string;
  confirmButtonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
  onClose(reason: 'confirm' | 'cancel'): void;
  confirmationOnly?: boolean;
  dialogContentProps?: DialogContentProps;
}>;

export function ConfirmationModal({
  isOpen = false,
  heading,
  children,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  confirmButtonProps,
  cancelButtonProps,
  onClose,
  confirmationOnly = false,
  dialogContentProps,
}: ConfirmationModalProps) {
  const handleCancel = useCallback(() => {
    onClose('cancel');
  }, [onClose]);
  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      PaperProps={{ sx: { width: 325, p: 1, pb: 2, borderRadius: 3 } }}
    >
      <DialogTitle
        textAlign="center"
        variant="h3"
        fontWeight={FONT_WEIGHTS.bold}
      >
        {heading}
      </DialogTitle>
      <DialogContent
        {...dialogContentProps}
        sx={{ textAlign: 'center', ...dialogContentProps?.sx }}
      >
        <Typography variant="subtitle1">{children}</Typography>
      </DialogContent>
      <DialogActions disableSpacing sx={{ mt: 0.5 }}>
        <Stack direction="row" spacing={2} flexGrow={1}>
          {!confirmationOnly && (
            <Button
              onClick={handleCancel}
              size="sm"
              variant="secondary"
              fullWidth
              {...cancelButtonProps}
            >
              {cancelText}
            </Button>
          )}
          <Button
            onClick={() => onClose('confirm')}
            size="sm"
            variant="primary"
            fullWidth
            {...confirmButtonProps}
          >
            {confirmText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
