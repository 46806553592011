import {
  Stack,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';
import React, { forwardRef, PropsWithChildren } from 'react';

import { HeaderCellTypography } from '../components/HeaderCellTypography';
import { HEADER_BACKGROUND_COLOR } from '../constants';

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: HEADER_BACKGROUND_COLOR,
    color: theme.palette.common.white,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    ':not(:last-of-type)': {
      borderRight: `solid white 1px`,
    },
  },
}));

export const StyledTableCell = styled(TableCell)(({ align }) => ({
  [`&.${tableCellClasses.body}`]: {
    border: 'none',
    textAlign: align,
  },
}));

export interface DisplayTableColumn {
  headerName: React.ReactNode;
  headerIconAfter?: React.ReactNode;
  width?: number | string;
  flex?: number;
  align?: TableCellProps['align'];
}

export interface DisplayTableProps
  extends React.HTMLAttributes<HTMLDivElement> {
  containerSx?: SxProps<Theme>;
  tableSx?: SxProps<Theme>;
  columns: DisplayTableColumn[];
  Footer?: React.ReactElement;
}

type DisplayTablePropsWithChildren = PropsWithChildren<DisplayTableProps>;

function DisplayTableInner(
  props: DisplayTablePropsWithChildren,
  ref: React.ForwardedRef<HTMLTableElement>
) {
  const { children, containerSx, tableSx, columns, Footer, ...rest } = props;
  return (
    <TableContainer sx={containerSx} ref={ref} {...rest}>
      <Table sx={tableSx}>
        <TableHead>
          <TableRow>
            {columns.map(
              ({ headerName, headerIconAfter, width, flex, align }, i) => (
                <StyledHeaderCell key={i} sx={{ width, flex }} align={align}>
                  {headerIconAfter ? (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <HeaderCellTypography sx={{ paddingX: 0, marginX: 1 }}>
                        {headerName}
                      </HeaderCellTypography>
                      {headerIconAfter}
                    </Stack>
                  ) : (
                    <HeaderCellTypography sx={{ paddingX: 0, marginX: 1 }}>
                      {headerName}
                    </HeaderCellTypography>
                  )}
                </StyledHeaderCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
        {Footer}
      </Table>
    </TableContainer>
  );
}

export const DisplayTable = forwardRef<
  HTMLTableElement,
  DisplayTablePropsWithChildren
>(DisplayTableInner);
