export const LOCAL_STORAGE_KEYS = {
  /**
   * Set when user visits ClientDetailsEstateWaterfallDiagramPage or
   * ClientDetailsEstateWaterfallSummaryPage. Used on ClientDetailsEstateWaterfallBasePage
   * to determine which waterfall to navigate to.
   */
  LAST_VIEWED_WATERFALL: 'LAST_VIEWED_WATERFALL',
  LAST_VIEWED_ENTITY_MAP: 'LAST_VIEWED_ENTITY_MAP',
  LAST_VIEWED_HOUSEHOLDS: 'LAST_VIEWED_HOUSEHOLDS',
  /** Set when a user dismisses the "Disable this toggle before creating your last entity" coachmark */
  COACHMARK_ENTITY_CREATE: 'COACHMARK_ENTITY_CREATE',
  HOUSEHOLD_ONBOARDING_SIDEBAR_EXPANDED: `HOUSEHOLD_ONBOARDING_SIDEBAR_EXPANDED`,
  COACHMARK_ENTITY_MAP_TOP_LINE_ESTATE_METRICS:
    'COACHMARK_ENTITY_MAP_TOP_LINE_ESTATE_METRICS',
  COACHMARK_ENTITY_MAP_ADD_BENEFICIARIES:
    'COACHMARK_ENTITY_MAP_ADD_BENEFICIARIES',
  COACHMARK_COPY_DISPOSITIVE_PROVISIONS:
    'COACHMARK_COPY_DISPOSITIVE_PROVISIONS',
  COACHMARK_DOCUMENT_PRECEDENCE: 'COACHMARK_DOCUMENT_PRECEDENCE',
};
