import { useCallback, useState } from 'react';

/**
 * @description Convenience hook to manage modal state and capture data related to modal.
 */

interface ModalState<T> {
  isModalOpen: boolean;
  data: T | null;
}

type HookOutput<T> = [
  ModalState<T>,
  {
    openModal: (data?: T) => void;
    closeModal: () => void;
    setModalIsOpen: (open: boolean) => void;
  },
];

export function useModalState<Data = unknown>(
  initiallyOpen = false
): HookOutput<Data> {
  const [modalState, setModalState] = useState<ModalState<Data>>({
    data: null,
    isModalOpen: initiallyOpen,
  });

  const openModal = useCallback(
    (data?: Data) =>
      setModalState(() => ({ data: data ?? null, isModalOpen: true })),
    []
  );

  const closeModal = useCallback(
    () => setModalState(() => ({ data: null, isModalOpen: false })),
    []
  );

  const setModalIsOpen = useCallback(
    (open: boolean) => (open ? openModal() : closeModal()),
    [closeModal, openModal]
  );

  return [modalState, { openModal, closeModal, setModalIsOpen }];
}
