import { ButtonBase, styled } from '@mui/material';

import { getButtonStyles } from './styles';
import { CommonButtonProps } from './types';

type ButtonRootProps = CommonButtonProps;

export const ButtonRoot = styled(ButtonBase, {
  name: 'ButtonRoot',
  shouldForwardProp: (propName) =>
    !(['fullWidth', 'square', 'whiteSpace'] as PropertyKey[]).includes(
      propName
    ),
})<ButtonRootProps>(({
  theme,
  variant,
  size,
  fullWidth,
  disabled,
  whiteSpace,
  square,
}) => {
  return getButtonStyles({
    theme,
    variant,
    size,
    fullWidth,
    disabled,
    whiteSpace,
    square,
  });
});
