import { ReactNode } from 'react';

/**
 * Determines if a ReactNode is a string.
 */
export function isString(node: ReactNode): node is string {
  return typeof node === 'string';
}

/**
 * @description Truncates a string to a maximum length, appending a terminator string if truncated. For example `truncateToLength('Hello, world!', 5)` returns `'Hello...'`.
 * @param input The string to truncate
 * @param length The maximum length, not including the terminator string
 * @param terminator The string to terminate with, defaulting to an ellipses
 */
export function truncateToLength(
  input: string,
  length: number,
  terminator = '...'
): string {
  return input.length > length
    ? `${input.slice(0, length)}${terminator}`
    : input;
}
