/* eslint-disable react/display-name */
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { ButtonProps } from '@/components/form/baseInputs/Button';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import {
  ChevronRightIcon,
  IconProps,
} from '@/components/icons/ChevronRightIcon';
import { COLORS } from '@/styles/tokens/colors';

import { CellContainer } from '../../components/cells';
import { CellRenderProp } from '../../types';

type IconType = typeof ChevronRightIcon;

interface ExpansionCaretRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  icon: CellRenderProp<R, V, F, N, IconType>;
  iconProps?: CellRenderProp<R, V, F, N, Partial<IconProps>>;
}

export const ExpansionCaretRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends IconType = IconType,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  _factoryProps?: ExpansionCaretRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const { id, field, rowNode } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const isExpanded = rowNode.type === 'group' && rowNode.childrenExpanded;
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: ButtonProps['onClick'] = (event) => {
      if (rowNode.type !== 'group') return;

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    const expandedIconStyle = {
      transform: `rotate(90deg)`,
    };

    return (
      <CellContainer
        align={props.colDef.align}
        // lower the default padding on the cell container so the button can take up more space
        // without increasing the overall row height
        sx={{ p: 1, pl: rowNode.depth * 2 }}
      >
        {filteredDescendantCount > 0 && (
          <IconButton
            icon={ChevronRightIcon}
            ariaLabel="Expand cell"
            size="sm"
            variant="transparent"
            onClick={handleClick}
            iconProps={{
              sx: {
                color: COLORS.GRAY[500],
                transition: `0.2s`,
                position: 'relative',
                ...(isExpanded ? expandedIconStyle : {}),
              },
            }}
          />
        )}
      </CellContainer>
    );
  };
};
