// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScenarioGiftRecipientOptionsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ProposalWhereInput>;
}>;


export type GetScenarioGiftRecipientOptionsQuery = { __typename?: 'Query', proposals: { __typename?: 'ProposalConnection', edges?: Array<{ __typename?: 'ProposalEdge', node?: { __typename?: 'Proposal', id: string, giftingProposal?: { __typename?: 'GiftingProposal', id: string, outOfEstatePortfolios?: Array<{ __typename?: 'GiftingProposalPortfolio', id: string, displayName: string, portfolioType: Types.GiftingProposalPortfolioPortfolioType }> | null } | null, household: { __typename?: 'Household', id: string, possibleBeneficiariesV2: { __typename?: 'PossibleBeneficiariesV2', clients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string }>, organizations: Array<{ __typename?: 'ClientOrganization', id: string, name: string }> } } } | null } | null> | null } };


export const GetScenarioGiftRecipientOptionsDocument = gql`
    query GetScenarioGiftRecipientOptions($where: ProposalWhereInput) {
  proposals(where: $where) {
    edges {
      node {
        id
        giftingProposal {
          id
          outOfEstatePortfolios {
            id
            displayName
            portfolioType
          }
        }
        household {
          id
          possibleBeneficiariesV2 {
            clients {
              id
              displayName
            }
            organizations {
              id
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScenarioGiftRecipientOptionsQuery__
 *
 * To run a query within a React component, call `useGetScenarioGiftRecipientOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioGiftRecipientOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioGiftRecipientOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetScenarioGiftRecipientOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetScenarioGiftRecipientOptionsQuery, GetScenarioGiftRecipientOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioGiftRecipientOptionsQuery, GetScenarioGiftRecipientOptionsQueryVariables>(GetScenarioGiftRecipientOptionsDocument, options);
      }
export function useGetScenarioGiftRecipientOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioGiftRecipientOptionsQuery, GetScenarioGiftRecipientOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioGiftRecipientOptionsQuery, GetScenarioGiftRecipientOptionsQueryVariables>(GetScenarioGiftRecipientOptionsDocument, options);
        }
export function useGetScenarioGiftRecipientOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScenarioGiftRecipientOptionsQuery, GetScenarioGiftRecipientOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScenarioGiftRecipientOptionsQuery, GetScenarioGiftRecipientOptionsQueryVariables>(GetScenarioGiftRecipientOptionsDocument, options);
        }
export type GetScenarioGiftRecipientOptionsQueryHookResult = ReturnType<typeof useGetScenarioGiftRecipientOptionsQuery>;
export type GetScenarioGiftRecipientOptionsLazyQueryHookResult = ReturnType<typeof useGetScenarioGiftRecipientOptionsLazyQuery>;
export type GetScenarioGiftRecipientOptionsSuspenseQueryHookResult = ReturnType<typeof useGetScenarioGiftRecipientOptionsSuspenseQuery>;
export type GetScenarioGiftRecipientOptionsQueryResult = Apollo.QueryResult<GetScenarioGiftRecipientOptionsQuery, GetScenarioGiftRecipientOptionsQueryVariables>;