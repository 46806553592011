import React, { createContext } from 'react';

export type FormOptionalDisplayType = 'required-asterisk' | 'optional-label';
interface IFormConfigurationContext {
  optionalDisplayType: FormOptionalDisplayType;
}

export const FormConfigurationContext =
  createContext<IFormConfigurationContext>({
    optionalDisplayType: 'required-asterisk',
  });

interface FormConfigurationProviderProps {
  value: IFormConfigurationContext;
}

export const FormConfigurationProvider = ({
  children,
  value,
}: React.PropsWithChildren<FormConfigurationProviderProps>) => {
  return (
    <FormConfigurationContext.Provider value={value}>
      {children}
    </FormConfigurationContext.Provider>
  );
};
