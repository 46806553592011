import Decimal from 'decimal.js';

import { sumDecimalJS } from '@/utils/decimalJSUtils';

import { GiftProposalProjection } from './giftProposal.types';

export function getTaxMetricsForProjection(p: GiftProposalProjection) {
  const giftTax = p.giftTax;
  const federalEstateTax = p.estateTaxSummary.federalTax?.tax ?? new Decimal(0);

  const stateEstateTax = sumDecimalJS(
    p.estateTaxSummary.stateTax?.map((s) => s.tax) ?? []
  );

  let combinedFederalAndStateEstateTax = federalEstateTax;

  combinedFederalAndStateEstateTax =
    combinedFederalAndStateEstateTax.plus(stateEstateTax);

  const combinedGiftAndEstateTax = giftTax.plus(
    combinedFederalAndStateEstateTax
  );

  return {
    giftTax,
    federalEstateTax,
    stateEstateTax,
    combinedFederalAndStateEstateTax,
    combinedGiftAndEstateTax,
  };
}
