import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { ScenarioGiftFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { DEFAULT_GROWTH_PERCENTAGE } from '../../GiftDesignerModelScenariosForm.utils';
import { AnnuallyRecurringValue } from './ScenarioGiftModal.fields';

interface UseScenarioGiftFormDefaultValuesProps {
  giftData: ScenarioGiftFormShape | null;
  lengthOfAnalysis: Decimal | undefined;
}

export function useScenarioGiftFormDefaultValues({
  giftData,
  lengthOfAnalysis,
}: UseScenarioGiftFormDefaultValuesProps) {
  const defaultValues = useMemo(() => {
    if (!giftData) {
      return {
        _id: '', // This will be replaced by a field array id when the field array is initialized
        displayName: '',
        recipientId: '',
        recipientKind: null,
        amount: null,
        annuallyRecurring: AnnuallyRecurringValue.true,
        discount: false,
        discountPercent: null,
        startYear: new Decimal(getYear(new Date())),
        endYear: (lengthOfAnalysis || new Decimal(0)).plus(getYear(new Date())),
        growthPercentage: DEFAULT_GROWTH_PERCENTAGE,
        isTaxable: null,
        nonTaxableGiftType: null,
        senderIds: [] as string[],
        order: 0,
        lengthOfAnalysis: lengthOfAnalysis || new Decimal(0),
        isNewRecipient: false,
        newRecipientName: '',
        newRecipientType: '',
      } as const satisfies ScenarioGiftFormShape;
    }

    return giftData;
  }, [giftData, lengthOfAnalysis]);

  return { defaultValues };
}
