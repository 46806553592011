import { PopperContent } from '@/components/poppers/PopperContent';

export function GiftInEstatePortfolio() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body="Enter the value of the client's estate, prior to making any gifts modeled in this analysis."
    />
  );
}
