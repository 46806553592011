import TextField, { TextFieldProps } from '@mui/material/TextField';
import * as React from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';

import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BaseFormattedNumberInputProps,
  FormControlFormattedNumberInputProps,
  HelpTextVariant,
} from '../inputTypes';

interface FormattedNumberInputProps
  extends Omit<FormControlFormattedNumberInputProps, 'type'> {
  label: string;
  id?: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  formatHashString?: string; // e.g. ##### would be a 5-digit number
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean | string;
  allowNegative?: boolean;
  hideLabel?: boolean;
  endAdornment?: React.ReactNode;
  labelIconEnd?: React.ReactNode;
}

interface NumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  label: string;
  id?: string;
  contextualHelp?: JSX.Element;
  formatHashString?: string; // e.g. ##### would be a 5-digit number
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean | string;
  allowNegative?: boolean;
}

const NumberFormatInput = React.forwardRef<
  NumberFormat<InputAttributes>,
  NumberFormatProps
>(function NumberFormatCustom(props, ref) {
  // destructuring formatHashString here and not the other "native" NumberFormat props because
  // formatHashString is passed to NumberFormat with the attribute name "format", whereas for all the
  // other ones we use the same name as the library in our props.
  const { onChange, formatHashString, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format={formatHashString}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      type="tel"
    />
  );
});

function BaseFormattedNumberInput({
  // see here: https://github.com/mui/material-ui/issues/12946
  // stripping off onKeyUp and onKeyDown is required here because TextField doesn't actually implement those props.
  onKeyUp: _onKeyUp,
  onKeyDown: _onKeyDown,
  formatHashString,
  decimalScale,
  fixedDecimalScale,
  thousandSeparator,
  allowNegative,
  endAdornment,
  ...inputProps
}: BaseFormattedNumberInputProps) {
  const { disabled } = inputProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  const mergedSx = Object.assign({ background: 'white' }, disabledStyle);
  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatInput as unknown as NonNullable<
          NonNullable<TextFieldProps['InputProps']>['inputComponent']
        >,
        sx: mergedSx,
        inputProps: {
          'data-testid': `formatted-number-input-${inputProps.name ?? ''}`,
          formatHashString,
          decimalScale,
          fixedDecimalScale,
          thousandSeparator,
          allowNegative,
        },
        endAdornment,
      }}
      variant="outlined"
      {...inputProps}
    />
  );
}

export function FormattedNumberInput({
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  labelIconEnd,
  ...inputProps
}: FormattedNumberInputProps) {
  return (
    <FormControl<BaseFormattedNumberInputProps>
      component={BaseFormattedNumberInput}
      inputProps={inputProps}
      errorMessage={errorMessage}
      contextualHelp={contextualHelp}
      required={inputProps.required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      id={inputProps.id}
      label={label}
      hideLabel={hideLabel}
      labelIconEnd={labelIconEnd}
    />
  );
}
