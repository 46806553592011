import { lighten, Theme } from '@mui/material';

export const getTriggerStyling = (color: string, _theme: Theme) => {
  const hoverColor = lighten(color, 0.2);
  return {
    display: 'inline-block',
    border: 'none',
    padding: 0,
    background: 'transparent',
    cursor: 'pointer',
    borderBottom: `solid ${color} 1px`,
    // need important here to overwrite the color defined in the typography component
    color: `${color} !important`,
    ':hover': {
      borderBottomColor: hoverColor,
    },
    // need extra specificity here to overwrite the color defined in the typography component
    ':hover span': {
      color: `${hoverColor} !important`,
    },
  };
};
