import { COLORS } from '@/styles/tokens/colors';

export type NotificationSeverity =
  | 'success'
  | 'info-high'
  | 'info-low'
  | 'warning'
  | 'error';

export const NOTIFICATION_THEME_BY_SEVERITY: Record<
  NotificationSeverity,
  Record<'border' | 'text' | 'background', string>
> = {
  success: {
    border: COLORS.FUNCTIONAL.SUCCESS.DEFAULT,
    text: COLORS.FUNCTIONAL.SUCCESS[600],
    background: COLORS.FUNCTIONAL.SUCCESS[50],
  },
  'info-high': {
    border: COLORS.BLUE[300],
    text: COLORS.BLUE[900],
    background: COLORS.PRIMITIVES.WHITE,
  },
  'info-low': {
    border: COLORS.GRAY[300],
    text: COLORS.GRAY[500],
    background: COLORS.PRIMITIVES.WHITE,
  },
  error: {
    border: COLORS.FUNCTIONAL.ERROR.DEFAULT,
    text: COLORS.FUNCTIONAL.ERROR.DEFAULT,
    background: COLORS.FUNCTIONAL.ERROR[50],
  },
  warning: {
    border: COLORS.FUNCTIONAL.WARNING[400],
    text: COLORS.FUNCTIONAL.WARNING[600],
    background: COLORS.FUNCTIONAL.WARNING[50],
  },
};
