import { Stack, Typography } from '@mui/material';
import { BaseSyntheticEvent } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';

import { Card } from '@/components/layout/Card';
import { COLORS } from '@/styles/tokens/colors';

import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { GiftScenario } from './GiftScenario';

type GiftScenariosProps = UseFieldArrayReturn<
  GiftDesignerModelScenariosFormShape,
  'scenarios',
  '_id'
> & {
  triggerSave: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>;
  proposalId: string;
};

export const MAX_GIFT_SCENARIOS_COUNT = 4;

export function GiftScenarios(props: GiftScenariosProps) {
  const { fields: scenarios } = props;

  if (!scenarios?.length) {
    return (
      <Card variant="outlined" sx={{ p: 3 }}>
        <Stack py={2} justifyContent="center" alignItems="center">
          <Typography color={COLORS.GRAY[500]} variant="body1">
            No scenarios
          </Typography>
        </Stack>
      </Card>
    );
  }

  return (
    <Stack spacing={3}>
      {scenarios?.map((scenario, idx) => (
        <GiftScenario
          key={scenario._id}
          idx={idx}
          scenario={scenario}
          {...props}
        />
      ))}
      <Typography variant="subtitle2">
        {scenarios.length} of {MAX_GIFT_SCENARIOS_COUNT} scenarios created
      </Typography>
    </Stack>
  );
}
