import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { GiftingProposalPortfolioPortfolioType } from '@/types/schema';

import { OutOfEstatePortfolioFormShape } from '../../GiftDesignerBasicInformationForm.types';

interface UseOutOfEstatePortfolioFormDefaultValuesProps {
  portfolioData: OutOfEstatePortfolioFormShape | null;
}

export const portfolioTypeOptions: SelectInputOption<GiftingProposalPortfolioPortfolioType>[] =
  [
    {
      value: GiftingProposalPortfolioPortfolioType.GrantorTrust,
      display: 'Grantor trust',
    },
    {
      value: GiftingProposalPortfolioPortfolioType.NonGrantorTrust,
      display: 'Non-grantor trust',
    },
    {
      value: GiftingProposalPortfolioPortfolioType.NonGrantorIndividual,
      display: 'Non-client individual',
    },
    {
      value: GiftingProposalPortfolioPortfolioType.Charitable,
      display: 'Charitable (non-taxable)',
    },
  ];

export function useOutOfEstatePortfolioFormDefaultValues({
  portfolioData,
}: UseOutOfEstatePortfolioFormDefaultValuesProps) {
  const defaultValues = useMemo(() => {
    if (!portfolioData) {
      return {
        name: '',
        amount: new Decimal(0),
        type: '',
      } as const satisfies OutOfEstatePortfolioFormShape;
    }

    return portfolioData;
  }, [portfolioData]);

  return { defaultValues };
}
