import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { set } from 'lodash';

import { redirectToLogin } from '@/components/navigation/navigationUtils';
import { diagnostics } from '@/utils/diagnostics';

export const forceLoginLink: ApolloLink = onError(({ networkError }) => {
  // if we're on the server, don't do handling here
  if (typeof window === 'undefined') return;

  // We expect 401s to happen when the user isn't logged in, and don't want to notify on them, but we do
  // want to redirect that user to the login page.
  const isUnauthenticatedError =
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401;
  if (isUnauthenticatedError) {
    return redirectToLogin();
  }
});

// Sentry caps its message length fairly low and won't display anything if the message is too long.
const MESSAGE_LENGTH_LIMIT = 250;
const IGNORED_STATUSES = new Set([401]);

export const reportNetworkErrorsLink: ApolloLink = onError(
  ({ operation, networkError, graphQLErrors: gqlErrors }) => {
    if (networkError) {
      const statusCode =
        'statusCode' in networkError ? networkError.statusCode : undefined;
      const isIgnoredStatusCode =
        statusCode && IGNORED_STATUSES.has(statusCode);

      if (isIgnoredStatusCode) {
        // Do not report 401s, they should be redirected to the login page
        return; // Returning undefined here will propagate the error to the next link
      } else {
        let stringifiedErrorObj: string | undefined = undefined;
        try {
          // if the statusCode is missing, then it's a plain error object -- log the entire thing to provide some more context
          stringifiedErrorObj = !('statusCode' in networkError)
            ? JSON.stringify(networkError).slice(0, MESSAGE_LENGTH_LIMIT)
            : undefined;
        } catch (e) {
          stringifiedErrorObj = 'Error stringifying network error';
        }

        let graphQLErrors: string | undefined = undefined;
        try {
          if (gqlErrors) {
            graphQLErrors = JSON.stringify(gqlErrors).slice(
              0,
              MESSAGE_LENGTH_LIMIT
            );
          }
        } catch (e) {
          graphQLErrors = 'Error stringifying GraphQL errors';
        }

        diagnostics.error(
          '[Network error]',
          networkError,
          // extra data to send to Sentry
          {
            name: networkError.name,
            message: networkError.message,
            operationName: operation.operationName,
            statusCode,
            stringifiedErrorObj,
            graphQLErrors,
            query: JSON.stringify(operation.query),
            variables: JSON.stringify(operation.variables),
            extensions: JSON.stringify(operation.extensions),
          },
          // fingerprint to aggregate on in Sentry
          [
            'graphql',
            `${networkError.name} > ${networkError.message} > ${operation.operationName}`,
          ]
        );
      }
    }
  }
);

export const logErrorDetailsLink: ApolloLink = onError(
  ({ operation, networkError, graphQLErrors, response }) => {
    const errCtx = {
      operationName: operation.operationName,
      query: JSON.stringify(operation.query),
      variables: JSON.stringify(operation.variables),
      extensions: JSON.stringify(operation.extensions),
    };

    if (response) {
      diagnostics.info(`[INFO][Response]`, {
        ...errCtx,
        response: JSON.stringify(response),
      });
    }

    if (networkError) {
      diagnostics.info(`[INFO][Network error]`, {
        ...errCtx,
        error: networkError,
        name: networkError.name,
        message: networkError.message,
      });
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        diagnostics.info(`[INFO][GraphQL error]`, {
          ...errCtx,
          error: graphQLError,
          message: graphQLError.message,
          path: JSON.stringify(graphQLError.path),
        });
      });
    }
  }
);

// This link is used to monitor the number of GraphQL requests that
// are currently active. It only works on __print routes.
export const printNetworkStatusMonitor: ApolloLink = new ApolloLink(
  (operation, forward) => {
    if (typeof window === 'undefined') return forward(operation);

    const isPrint = window.location.pathname.includes('__print');

    if (!isPrint) return forward(operation);

    const increment = () => {
      set(
        window,
        '__print.inFlightRequests',
        (window.__print?.inFlightRequests || 0) + 1
      );
    };

    const decrement = () => {
      set(
        window,
        '__print.inFlightRequests',
        (window.__print?.inFlightRequests || 0) - 1
      );
    };

    // Increment the loading count on operation start
    increment();

    return forward(operation).map((response) => {
      // Decrement the loading count on operation end
      decrement();

      return response;
    });
  }
);

// This link is used to monitor the count of Apollo errors.
// It only works on __print routes.
export const printApolloErrorsMonitor: ApolloLink = onError(
  ({ networkError, graphQLErrors }) => {
    if (typeof window === 'undefined') return;

    const isPrint = window.location.pathname.includes('__print');

    if (!isPrint) return; // No need to forward() here, as that will retry the request in onError

    if (networkError || graphQLErrors) {
      set(
        window,
        '__print.apolloErrorCount',
        (window.__print?.apolloErrorCount || 0) + 1
      );
    }
  }
);
