import {
  InEstatePortfolioInput,
  LengthOfAnalysis,
  Name,
} from './GiftDesignerBasicInformationForm.fields';
import {
  AnnualPreTaxReturn,
  ExemptionGrowth,
  ExemptionSunsets,
  ShowAfterEstateTax,
  YearOfAnalysis,
} from './GiftDesignerModelScenariosForm.fields';

export * from './components';
export * from './GiftDesignerBasicInformationForm.types';
export * from './GiftDesignerBasicInformationForm.utils';
export * from './GiftDesignerModelScenariosForm.types';
export * from './GiftDesignerModelScenariosForm.utils';

export const GiftDesignerBasicInformation = {
  Name,
  LengthOfAnalysis,
  InEstatePortfolioInput,
};

export const GiftDesignerModelScenarios = {
  AnnualPreTaxReturn,
  ExemptionGrowth,
  YearOfAnalysis,
  ShowAfterEstateTax,
  ExemptionSunsets,
};
