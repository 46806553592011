import Decimal from 'decimal.js';

export function getZeroSignedNormalizedNumber(value: Decimal): number {
  // this is necessary because depending on the method used to calculate this, the underlying value
  // can either be -0 or +0. we never want to show a "negative zero", because that's confusing for the user.
  // read more: https://medium.com/coding-at-dawn/is-negative-zero-0-a-number-in-javascript-c62739f80114
  if (value.isZero()) {
    return 0;
  }

  return value.toNumber();
}
