import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';

import { EditButtonRenderer } from '@/components/tables/DataTable/renderers/cell/EditButtonRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';
import { GiftingProposalCashFlowCashFlowType } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { IncomeRow } from './BaselineIncomeAndExpensesTable.types';

function formatValue(row: IncomeRow) {
  if (!row.value) {
    return EMPTY_CONTENT_HYPHEN;
  }

  if (row.cashFlowType === GiftingProposalCashFlowCashFlowType.Expense) {
    return `(${formatCurrencyNoDecimals(row.value)})`;
  }

  return formatCurrencyNoDecimals(row.value);
}

export const columns: Column<IncomeRow>[] = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
    renderCell: TextRenderer({
      text: ({ row }) => row.name,
    }),
  },
  {
    headerName: 'Date(s)',
    field: 'dates',
    flex: 1,
    align: 'right',
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) =>
        row.dates.endYear
          ? `${row.dates.startYear}–${row.dates.endYear}`
          : `${row.dates.startYear}`,
      lineTwo: ({ row }) => row.dates.frequency,
    }),
    sortComparator: (
      v1: IncomeRow['dates'],
      v2: IncomeRow['dates'],
      p1,
      p2
    ) => {
      return gridStringOrNumberComparator(v1.startYear, v2.startYear, p1, p2);
    },
  },
  {
    headerName: 'Growth rate',
    field: 'growthRate',
    flex: 1,
    align: 'right',
    renderCell: TextRenderer({
      text: ({ row }) =>
        row.growthRate
          ? `${formatPercent(row.growthRate, 0)}%`
          : EMPTY_CONTENT_HYPHEN,
    }),
    sortComparator: (
      v1: IncomeRow['growthRate'],
      v2: IncomeRow['growthRate'],
      p1,
      p2
    ) => {
      return gridStringOrNumberComparator(v1, v2, p1, p2);
    },
  },
  {
    headerName: 'Value',
    field: 'value',
    flex: 1,
    align: 'right',
    renderCell: TextRenderer({
      text: ({ row }) => formatValue(row),
      textProps: ({ row }) => ({
        sx: {
          color:
            row.cashFlowType === GiftingProposalCashFlowCashFlowType.Expense
              ? COLORS.MATH.NEGATIVE
              : 'inherit',
        },
      }),
    }),
    sortComparator: (
      v1: IncomeRow['value'],
      v2: IncomeRow['value'],
      p1,
      p2
    ) => {
      return gridStringOrNumberComparator(v1, v2, p1, p2);
    },
  },
  {
    field: 'edit',
    headerName: '',
    width: 20,
    align: 'center',
    sortable: false,
    renderCell: EditButtonRenderer({
      editButtonProps: {
        handleClick: (row) => {
          row.handleEdit();
        },
      },
    }),
  },
];
