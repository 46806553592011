// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Authentication_ApplicationAuthorizationFragment = { __typename?: 'ApplicationAuthorization', userKind: Types.UserKind, households: Types.AuthorizationLevel, branding: Types.AuthorizationLevel, collaborators: Types.AuthorizationLevel, internalEmployees: Types.AuthorizationLevel, canActAsRelationshipOwner: boolean, hasAllClientsAccess: boolean, canModifyGlobalCategories: boolean };

export type Authentication_UserFragment = { __typename?: 'User', nameWithLastInitial: string, lastName: string, initials: string, id: string, firstName: string, email: string, displayName: string, employees?: Array<{ __typename?: 'Employee', id: string, isAdmin?: boolean | null }> | null };

export type GetUserStateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserStateQuery = { __typename?: 'Query', applicationAuthorization: { __typename?: 'ApplicationAuthorization', userKind: Types.UserKind, households: Types.AuthorizationLevel, branding: Types.AuthorizationLevel, collaborators: Types.AuthorizationLevel, internalEmployees: Types.AuthorizationLevel, canActAsRelationshipOwner: boolean, hasAllClientsAccess: boolean, canModifyGlobalCategories: boolean }, currentUser?: { __typename?: 'User', id: string, nameWithLastInitial: string, lastName: string, initials: string, firstName: string, email: string, displayName: string, employees?: Array<{ __typename?: 'Employee', id: string, isAdmin?: boolean | null }> | null } | null, isUserLoggedInToAddepar: { __typename?: 'IsLoggedInToAddepar', isTokenValid: boolean, isLoggedIn: boolean }, isUserLoggedInToBlackDiamond: { __typename?: 'IsLoggedIn', isTokenValid: boolean, isLoggedIn: boolean }, isIntegrationUserLoggedInToAddepar: { __typename?: 'IsLoggedInToAddepar', isLoggedIn: boolean, isTokenValid: boolean }, isIntegrationUserLoggedInToBlackDiamond: { __typename?: 'IsLoggedIn', isLoggedIn: boolean, isTokenValid: boolean } };

export const Authentication_ApplicationAuthorizationFragmentDoc = gql`
    fragment Authentication_ApplicationAuthorization on ApplicationAuthorization {
  userKind
  households
  branding
  collaborators
  internalEmployees
  canActAsRelationshipOwner
  hasAllClientsAccess
  canModifyGlobalCategories
}
    `;
export const Authentication_UserFragmentDoc = gql`
    fragment Authentication_User on User {
  nameWithLastInitial
  lastName
  initials
  id
  firstName
  email
  displayName
  employees {
    id
    isAdmin
  }
}
    `;
export const GetUserStateDocument = gql`
    query GetUserState {
  applicationAuthorization {
    ...Authentication_ApplicationAuthorization
  }
  currentUser {
    id
    ...Authentication_User
  }
  isUserLoggedInToAddepar {
    isTokenValid
    isLoggedIn
  }
  isUserLoggedInToBlackDiamond {
    isTokenValid
    isLoggedIn
  }
  isIntegrationUserLoggedInToAddepar {
    isLoggedIn
    isTokenValid
  }
  isIntegrationUserLoggedInToBlackDiamond {
    isLoggedIn
    isTokenValid
  }
}
    ${Authentication_ApplicationAuthorizationFragmentDoc}
${Authentication_UserFragmentDoc}`;

/**
 * __useGetUserStateQuery__
 *
 * To run a query within a React component, call `useGetUserStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserStateQuery(baseOptions?: Apollo.QueryHookOptions<GetUserStateQuery, GetUserStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserStateQuery, GetUserStateQueryVariables>(GetUserStateDocument, options);
      }
export function useGetUserStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserStateQuery, GetUserStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserStateQuery, GetUserStateQueryVariables>(GetUserStateDocument, options);
        }
export function useGetUserStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserStateQuery, GetUserStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserStateQuery, GetUserStateQueryVariables>(GetUserStateDocument, options);
        }
export type GetUserStateQueryHookResult = ReturnType<typeof useGetUserStateQuery>;
export type GetUserStateLazyQueryHookResult = ReturnType<typeof useGetUserStateLazyQuery>;
export type GetUserStateSuspenseQueryHookResult = ReturnType<typeof useGetUserStateSuspenseQuery>;
export type GetUserStateQueryResult = Apollo.QueryResult<GetUserStateQuery, GetUserStateQueryVariables>;