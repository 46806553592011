// disable the console.* ban for this file because this is a very valid place
// to use console methods
/* eslint-disable ban/ban */
import { ApolloError } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
import { ScopeContext } from '@sentry/types';
import { GraphQLFormattedError } from 'graphql';
import { startCase } from 'lodash';
import posthog from 'posthog-js';

import { getTenant } from '@/utils/tenantUtils';

import { getDatadogSessionURL } from './datadogUtils';
import {
  getAppVersion,
  getEnvironment,
  isDevelopmentMode,
} from './environmentUtils';
import { getPosthogSessionURL } from './posthogUtils';

export function initialize() {
  if (!isDevelopmentMode()) {
    datadogRum.init({
      applicationId: '4471acdf-ee38-4f71-85f4-a02584504f94',
      clientToken: 'pub3e8f3b7bd9b1b19bcc45b3d51a5ec38b',
      site: 'datadoghq.com',
      service: 'interface',
      env: getEnvironment(),
      version: getAppVersion(),
      sampleRate: 100,
      // TODO: Lower sessionReplaySampleRate as we have more users on the platform. We don't need to
      // save *every single session*, only a representative sample.
      sessionReplaySampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      // TODO: Update this "mask-user-input".
      defaultPrivacyLevel: 'allow',
      enableExperimentalFeatures: ['feature_flags'],
    });

    datadogRum.startSessionReplayRecording();
    const datadogSessionUrl = getDatadogSessionURL();

    if (datadogSessionUrl) {
      Sentry.setExtra('datadogReplayUrl', datadogSessionUrl);
    }

    const posthogSessionUrl = getPosthogSessionURL();
    if (posthogSessionUrl) {
      Sentry.setExtra('posthogReplayUrl', posthogSessionUrl);
    }
  }
}

export function identifyUserWithSession(email: string, userId: string) {
  Sentry.setUser({
    email,
    id: userId,
  });

  datadogRum.setUser({
    id: userId,
    email,
    tenant: getTenant(),
  });

  posthog.identify(userId, { email, tenant: getTenant() });
  posthog.group('company', getTenant());
}

/**
 * @description Error logger for GraphQL network errors
 */
function logGraphQLNetworkError({
  description,
  error,
  tags,
  fingerprint,
}: {
  description: string;
  error?: Error | null;
  tags: ScopeContext['tags'];
  fingerprint?: ScopeContext['fingerprint'];
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { query, ...restTags } = tags;
  const possibleFingerprint = (() => {
    if (fingerprint) {
      return { fingerprint };
    }

    return {};
  })();

  const prettyErrorName = `GraphQLError ${description} ${String(
    tags.operationName
  )}`;
  const captureContext = {
    level: 'error' as const,
    ...possibleFingerprint,
    tags: {
      description,
      ...restTags,
    },
    contexts: {
      GraphQL: {
        description,
        message: error?.message,
        fingerprint,
        ...tags,
      },
    },
  };

  console.error(error, {
    errorName: prettyErrorName,
    ...captureContext,
  });

  Sentry.captureMessage(prettyErrorName, captureContext);
}

function logError({
  description,
  error,
  tags,
  fingerprint,
}: {
  description: string;
  error?: Error | null;
  tags?: ScopeContext['tags'];
  fingerprint?: ScopeContext['fingerprint'];
}) {
  if (tags?.operationName) {
    // this is a GraphQL network error
    logGraphQLNetworkError({
      description,
      error,
      tags,
      fingerprint,
    });
  } else {
    const finalError = error || new Error(description);
    const captureContext = {
      fingerprint,
      tags: {
        description,
        ...tags,
      },
    };

    console.error(finalError, captureContext);
    Sentry.captureException(finalError, captureContext);
  }
}

/**
 * @description Error logger for GraphQL errors returned
 * from the backend
 */
function logGraphQLError({
  err,
  description,
  tags,
}: {
  err: GraphQLFormattedError;
  description: string;
  tags?: ScopeContext['tags'];
}) {
  Sentry.withScope((scope) => {
    const errorPath = (err.path ?? [])
      .map((v: string | number) => (typeof v === 'number' ? '$index' : v))
      .join(' > ');

    const errorTags: ScopeContext['tags'] = {
      errorType: 'GraphQLError',
      description: `GraphQL Error at ${errorPath}`,
      ...tags,
    };

    scope.setTags({ ...errorTags });

    if (errorPath) {
      scope.addBreadcrumb({
        category: 'execution-path',
        message: errorPath,
        level: 'debug',
      });
    }

    const captureContext = {
      level: 'error' as const,
      fingerprint: ['graphql', errorPath],
      contexts: {
        GraphQL: {
          description,
          message: err.message,
          extensions: err.extensions,
          path: errorPath,
        },
      },
    };

    const prettyErrorName = `GraphQLError ${startCase(
      `${err.message} ${description}`
    )}`;

    console.error(err, {
      ...captureContext,
      errorName: prettyErrorName,
      tags: errorTags,
    });

    Sentry.captureMessage(prettyErrorName, captureContext);
  });
}

/**
 *
 * @param description A human-friendly description of the context of the issue
 * @param error The underlying associated error. If no error is generated, you can create your own. This is useful because it make sure there's always an associated stacktrace.
 * @param context Any additional parameters that would be useful for debugging.
 */
export function error(
  description: string,
  error?: Error | null,
  tags?: ScopeContext['tags'],
  fingerprint?: ScopeContext['fingerprint']
) {
  if (error instanceof ApolloError) {
    error.graphQLErrors.forEach((err) => {
      logGraphQLError({
        err,
        description,
        tags,
      });
    });
  } else {
    logError({ description, error: error as Error | null, tags, fingerprint });
  }
}

export function trace(message: unknown, data?: object) {
  if (isDevelopmentMode()) {
    console.trace(message, data);
  }
}

export function debug(message: unknown, data?: object) {
  if (isDevelopmentMode()) {
    console.debug(message, data);
  }
}

export function info(message: unknown, data?: object) {
  if (isDevelopmentMode()) {
    console.info(message, data);
  }

  Sentry.addBreadcrumb({
    level: 'info',
    message: message as string,
    data,
  });
}

export function warn(message?: unknown, data?: object) {
  console.warn(message, data);
  Sentry.addBreadcrumb({
    level: 'warning',
    message: message as string,
    data,
  });
}

export const diagnostics = {
  error,
  warn,
  debug,
  trace,
  info,
  isDevelopmentMode,
  identifyUserWithSession,
  getEnvironment,
  getAppVersion,
  initialize,
};
