import { CheckCircleIcon } from '@/components/icons/CheckCircleIcon';
import { RefreshCw05Icon } from '@/components/icons/RefreshCw05Icon';
import { COLORS } from '@/styles/tokens/colors';

export interface TabValidityIndicatorProps {
  isValid: boolean;
}

export function TabValidityIndicator({ isValid }: TabValidityIndicatorProps) {
  const iconSizeProps = { height: 22, width: 22 };
  if (isValid) {
    return (
      <CheckCircleIcon
        {...iconSizeProps}
        color={COLORS.FUNCTIONAL.SUCCESS.DEFAULT}
      />
    );
  }

  return <RefreshCw05Icon {...iconSizeProps} color={COLORS.GRAY[400]} />;
}
