import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { ArrowDownRightIcon } from '@/components/icons/ArrowDownRightIcon';
import { ArrowUpRightIcon } from '@/components/icons/ArrowUpRightIcon';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

import { titleStyles } from './GiftBenefitsCard';

interface ValueWithArrowProps {
  value: Decimal;
  isCompareSource?: boolean;
}

export function ValueWithArrow({
  value,
  isCompareSource,
}: ValueWithArrowProps) {
  if (isCompareSource) {
    return (
      <Typography
        variant="subtitle2"
        sx={{
          ...titleStyles,
          textAlign: 'right',
        }}
      >
        -
      </Typography>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={0.5}
      justifyContent="end"
      alignItems="center"
    >
      {value.gt(0) && (
        <ArrowUpRightIcon
          size={16}
          sx={{
            display: 'flex',
            color: COLORS.NAVY[200],
          }}
        />
      )}
      {value.lt(0) && (
        <ArrowDownRightIcon
          size={16}
          sx={{
            display: 'flex',
            color: COLORS.NAVY[200],
          }}
        />
      )}

      <Typography
        variant="subtitle2"
        sx={{
          ...titleStyles,
          textAlign: 'right',
        }}
      >
        {formatCurrency(value, {
          notation: 'compact',
          currencySign: 'accounting',
        })}
      </Typography>
    </Stack>
  );
}
