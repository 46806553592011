import '../src/styles/globals.css';
import '@ungap/with-resolvers';

import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AppProps } from 'next/app';
import { Public_Sans } from 'next/font/google';
import React from 'react';

import { useApollo } from '@/graphql/client';

// If loading a variable font, you don't need to specify the font weight
const PublicSans = Public_Sans({
  subsets: ['latin'],
  variable: '--font-public-sans',
});
export default function CustomApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${PublicSans.style.fontFamily};
        }
      `}</style>
      {/* Put providers here that should be global to both authenticated and unauthenticated scenarios across the app */}
      {/* For auth-only/app scenarios, put providers in index.tsx */}
      {/* For login-only scenarios, put providers in login.tsx */}
      <ApolloProvider client={apolloClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Component {...pageProps} />
        </LocalizationProvider>
      </ApolloProvider>
    </>
  );
}
