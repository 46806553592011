import { ExpansionCaretRenderer } from '../../renderers/cell/ExpansionCaretRenderer';
import { ContextualHelpHeaderRenderer } from '../../renderers/header/ContextualHelpHeaderRenderer';
import { DataTableProps, InternalDataTableProps } from '../../types';

export const defaultGroupColDef: InternalDataTableProps['groupingColDef'] = {
  renderHeader: ContextualHelpHeaderRenderer({ header: '' }),
  // TODO: This doesn't do a great job of dynamically expanding to N levels of nesting,
  // but can be addressed later on when we have a use case for it
  width: 70,
  headerName: '',
  align: 'center',
  renderCell: ExpansionCaretRenderer(),
  valueGetter: () => '',
};

const defaultRowHeightGetter: InternalDataTableProps['getRowHeight'] = () => {
  return 'auto';
};

export function useDefaultProps({
  hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS = false,
  rowHoverVariant = 'default',
  pagination = true,
  autoHeight = true,
  disableColumnFilter = true,
  disableColumnMenu = true,
  disableColumnSelector = true,
  disableRowSelectionOnClick = true,
  groupingColDef = defaultGroupColDef,
  rowHeight,
  getRowHeight = rowHeight === undefined ? defaultRowHeightGetter : undefined,
  ...rest
}: DataTableProps): InternalDataTableProps {
  return {
    ...rest,
    hideFooter: hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS,
    groupingColDef,
    pagination,
    rowHoverVariant,
    autoHeight,
    getRowHeight,
    disableColumnFilter,
    disableColumnMenu,
    disableColumnSelector,
    disableRowSelectionOnClick,
  };
}
