import styled from '@emotion/styled';
import {
  TableRow as MUITableRow,
  tableRowClasses,
  TableRowProps as MUITableRowProps,
} from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

import { ROW_BORDER_STYLE } from '../constants';

interface TableRowProps extends MUITableRowProps {
  disabled?: boolean;
  /** Do not change the cursor or hover background, because the row can't be interacted with */
  inactive?: boolean;
}

export function StyledTableRow({
  children,
  disabled,
  onClick,
  ...rest
}: React.PropsWithChildren<TableRowProps>) {
  const handleClick: MUITableRowProps['onClick'] = (e) => {
    if (disabled) return;
    onClick?.(e);
  };

  return (
    <TableRow disabled={disabled} onClick={handleClick} {...rest}>
      {children}
    </TableRow>
  );
}

const TableRow = styled(MUITableRow)<{
  disabled?: boolean;
  inactive?: boolean;
}>(({ onClick, disabled, inactive }) => ({
  [`&.${tableRowClasses.root}`]: {
    ':not(:last-of-type)': {
      borderBottom: ROW_BORDER_STYLE,
      overflow: 'hidden',
    },
    ...(disabled
      ? {
          backgroundColor: COLORS.GRAY[50],
        }
      : {}),
    ...(onClick && !disabled && !inactive
      ? {
          ':hover': {
            backgroundColor: COLORS.FUNCTIONAL.HOVER,
          },
          cursor: 'pointer',
        }
      : {}),
  },
}));
