import {
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
} from '@mui/material';
import { useCallback } from 'react';

// we implement custom handling for escape keys
type RelevantMUIDialogProps = Omit<MUIDialogProps, 'disableEscapeKeyDown'>;

export interface DialogProps extends RelevantMUIDialogProps {
  onClose?: () => void;
}

export function Dialog({ open, onClose, ...props }: DialogProps) {
  // we have this custom handling for escape rather than using the native MUIDialog escape key handling because
  // we have a fair number of nested modals and we only want to close the *top-most* modal; the native MUIDialog
  // handling closes *all open modals* on escape.
  const keyboardHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (open && !event.defaultPrevented) {
          event.preventDefault();
          event.stopImmediatePropagation();
          event.stopPropagation();
          if (onClose) {
            onClose();
          }
        }
      }
    },
    [onClose, open]
  );

  const registerHandler = useCallback(
    (node: HTMLDivElement) => {
      if (node?.addEventListener) {
        node?.addEventListener('keyup', keyboardHandler);
      }
      return () => {
        if (node?.removeEventListener) {
          node?.removeEventListener('keyup', keyboardHandler);
        }
      };
    },
    [keyboardHandler]
  );

  return (
    <MUIDialog
      ref={registerHandler}
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      {...props}
    />
  );
}
