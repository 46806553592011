/* eslint-disable react/display-name */
import { Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';

import {
  CellContainer,
  CellContainerProps,
  PrimaryCellTypography,
  PrimaryCellTypographyProps,
} from '../../components/cells';
import { CellRenderProp } from '../../types';
import { getCellRenderProp } from '../../utils/getRenderProp';

interface TextRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  text?: CellRenderProp<R, V, F, N, ReactNode>;
  // if passing a non-string to text, pass a string here to render as the title
  textTitle?: CellRenderProp<R, V, F, N, string>;
  textProps?: CellRenderProp<R, V, F, N, Partial<PrimaryCellTypographyProps>>;
  rightContent?: CellRenderProp<R, V, F, N, ReactNode>;
  cellContainerProps?: CellRenderProp<R, V, F, N, Partial<CellContainerProps>>;
}

export const TextRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends string = string,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  factoryProps?: TextRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const text = getCellRenderProp({
      props,
      prop: factoryProps?.text,
      defaultValue: props.value,
    });

    const textTitle = getCellRenderProp({
      props,
      prop: factoryProps?.textTitle,
      defaultValue: props.value,
    });

    const textProps = getCellRenderProp({
      props,
      prop: factoryProps?.textProps,
      defaultValue: {},
    });

    const rightContent = getCellRenderProp({
      props,
      prop: factoryProps?.rightContent,
      defaultValue: null,
    });

    const cellContainerProps = getCellRenderProp({
      props,
      prop: factoryProps?.cellContainerProps,
      defaultValue: {},
    });

    // We need this bit of seemingly redundant "direction=row-reverse" and the
    // backwards conditional-rendering of rightContent, otherwise, the alignment
    // of the textContent will be wonky for right-aligned cells.
    // See reference picture: https://github.com/withluminary/interface/pull/2148/files#diff-17f1223f707b150e916420756c14f4415853fdc8c44c0630ec5830473d5558c4
    const isRightAligned = props.colDef.align === 'right';

    return (
      <CellContainer {...cellContainerProps} align={props.colDef.align}>
        <Stack
          direction={isRightAligned ? 'row-reverse' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          {isRightAligned && rightContent}
          <PrimaryCellTypography title={textTitle} {...textProps}>
            {text}
          </PrimaryCellTypography>
          {!isRightAligned && rightContent}
        </Stack>
      </CellContainer>
    );
  };
};
