import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import { AnnuallyRecurringValue } from './components/BaselineIncomeAndExpensesModal/BaselineIncomeAndExpensesModal.fields';
import {
  BaselineIncomeAndExpensesFormShape,
  GiftDesignerBasicInformationFormShape,
  OutOfEstatePortfolioFormShape,
} from './GiftDesignerBasicInformationForm.types';
import { DEFAULT_GROWTH_PERCENTAGE } from './GiftDesignerModelScenariosForm.utils';
import { useGetGiftDesignerBasicInformationFormDataLazyQuery } from './graphql/GetGiftDesignerBasicInformationFormData.generated';

interface UseGiftDesignerBasicInformationDefaultValuesProps {
  proposalId: string;
}

export const DEFAULT_BASIC_INFORMATION_FORM_VALUES = {
  name: `Gift Analysis ${formatDateToMonDDYYYY(new Date())}`,
  lengthOfAnalysis: new Decimal(30),
  inEstatePortfolio: null,
  outOfEstatePortfolios: [] as OutOfEstatePortfolioFormShape[],
  baselineIncomeAndExpenses: [] as BaselineIncomeAndExpensesFormShape[],
  _status: null,
} as const satisfies GiftDesignerBasicInformationFormShape;

export function useGiftDesignerBasicInformationDefaultValues({
  proposalId,
}: UseGiftDesignerBasicInformationDefaultValuesProps) {
  const { createErrorFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [queryFormData, queryProps] =
    useGetGiftDesignerBasicInformationFormDataLazyQuery({
      variables: {
        where: {
          id: proposalId,
        },
      },
      onError: createErrorFeedback(
        'There was an error loading the gift proposal details.'
      ),
    });

  const getDefaultValues = useCallback<
    () => Promise<GiftDesignerBasicInformationFormShape>
  >(async () => {
    if (!proposalId || proposalId === NEW_PROPOSAL_ID) {
      return DEFAULT_BASIC_INFORMATION_FORM_VALUES;
    }

    const { data } = await queryFormData();
    const proposals = getNodes(data?.proposals);

    if (!(proposals.length === 1) || !proposals[0]) {
      const errorDescription =
        'Expected exactly one gift proposal to be returned.';
      const err = new Error(errorDescription);

      reportError(errorDescription, err, {
        proposalId,
      });
      throw err;
    }

    const proposal = proposals[0];

    const lengthOfAnalysis = new Decimal(
      proposal.giftingProposal?.lengthOfAnalysis ??
        DEFAULT_BASIC_INFORMATION_FORM_VALUES.lengthOfAnalysis
    );

    return {
      name: proposal.displayName,
      lengthOfAnalysis,
      inEstatePortfolio:
        proposal.giftingProposal?.inEstatePortfolioValue ?? null,
      outOfEstatePortfolios:
        proposal.giftingProposal?.outOfEstatePortfolios?.map(
          (p) =>
            ({
              portfolioId: p.id,
              name: p.displayName,
              amount: p.startingAssetValue,
              type: p.portfolioType,
            }) as const satisfies OutOfEstatePortfolioFormShape
        ) ?? [],
      baselineIncomeAndExpenses:
        proposal.giftingProposal?.baseCashFlows?.map((c) => {
          const commonCashFlowProps = {
            cashFlowId: c.id,
            amount: c.amount,
            cashFlowType: c.cashFlowType,
            displayName: c.displayName,
            order: c.order,
            startYear: new Decimal(c.startYear),
            lengthOfAnalysis,
          };

          if (c.annuallyRecurring) {
            return {
              annuallyRecurring: AnnuallyRecurringValue.true,
              endYear: new Decimal(c.endYear ?? getYear(new Date()) + 1),
              growthPercentage: c.growthPercentage ?? DEFAULT_GROWTH_PERCENTAGE,
              ...commonCashFlowProps,
            } as const satisfies BaselineIncomeAndExpensesFormShape;
          } else {
            return {
              annuallyRecurring: AnnuallyRecurringValue.false,
              endYear: null,
              growthPercentage: null,
              ...commonCashFlowProps,
            } as const satisfies BaselineIncomeAndExpensesFormShape;
          }
        }) ?? [],
      _status: proposal.giftingProposal?.status ?? null,
    };
  }, [proposalId, queryFormData, reportError]);

  return { getDefaultValues, ...queryProps };
}
