import { Theme } from '@emotion/react';
import { SxProps } from '@mui/material';
import { SetOptional } from 'type-fest';

import { Edit02Icon, IconProps } from '@/components/icons/Edit02Icon';

import { Button } from './Button';
import { CommonButtonProps } from './types';

export type EditButtonProps = SetOptional<
  CommonButtonProps,
  'variant' | 'size'
> & {
  sx?: SxProps<Theme>;
  iconProps?: IconProps;
};

export function EditButton({
  variant = 'transparent',
  size = 'xs',
  iconProps,
  ...props
}: EditButtonProps) {
  return (
    <Button square variant={variant} size={size} {...props}>
      <Edit02Icon size={16} {...iconProps} />
    </Button>
  );
}
