import { makeStyles } from 'tss-react/mui';
import { SetOptional } from 'type-fest';

import { XCloseIcon } from '@/components/icons/XCloseIcon';
import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { Button, ButtonProps } from './Button';

export type CloseButtonProps = SetOptional<ButtonProps, 'size' | 'variant'> & {
  classes?: WithClasses<typeof useStyles>;
};

const useStyles = makeStyles()((_theme) => ({
  closeButton: {
    padding: 0,
    margin: 0,
  },
  closeIcon: {
    color: COLORS.GRAY[500],
  },
}));

export function CloseButton(props: CloseButtonProps) {
  const { classes } = useStyles(undefined, { props });
  return (
    <Button
      size="sm"
      variant="secondary"
      className={classes.closeButton}
      square
      {...props}
    >
      <XCloseIcon className={classes.closeIcon} />
    </Button>
  );
}
