import { Box, Stack, Typography } from '@mui/material';
import { useContext } from 'react';

import { ContextualHelpTooltip } from '../../../modules/content/components/ContextualHelpTooltip';
import {
  FormConfigurationContext,
  FormOptionalDisplayType,
} from '../context/FormConfigurationContext';

type InputLabelProps = React.PropsWithChildren<{
  contextualHelp?: JSX.Element;
  required?: boolean;
  /**
   * hideRequirementIndicator is used for checkboxes and toggles, where there's no need to show an
   * asterisk or "optional"  tag because the default value of the input is already valid
   */
  hideRequirementIndicator?: boolean;
  hidden?: boolean;
  labelIconEnd?: React.ReactNode;
}> &
  React.LabelHTMLAttributes<HTMLLabelElement>;

export function InputLabel({
  children,
  contextualHelp,
  required,
  hidden,
  labelIconEnd,
  hideRequirementIndicator,
  ...props
}: InputLabelProps) {
  // this allows choosing between rendering how to render the requirement status of a given input!
  // the default is "Label name *" for required inputs, and "Label name" for optional inputs.
  // the alternative is e.g. "Label name" for required inputs, and "Label name (optional)" for optional inputs.
  // you can wrap inputs in a FormConfigurationContext
  const formConfigurationContext = useContext(FormConfigurationContext);
  const optionalDisplayType: FormOptionalDisplayType =
    formConfigurationContext?.optionalDisplayType ?? 'required-asterisk';
  const requirementIndicator = (() => {
    if (hideRequirementIndicator) return null;

    switch (optionalDisplayType) {
      case 'optional-label':
        return !required && <span>&nbsp;(optional)</span>;
      case 'required-asterisk':
      // falls through
      default:
        return required && <span>&nbsp;*</span>;
    }
  })();

  return (
    <Stack
      direction="row"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography
          component="label"
          variant="label"
          hidden={hidden}
          {...props}
        >
          {children}
          {requirementIndicator}
        </Typography>
        {contextualHelp && !hidden && (
          <ContextualHelpTooltip>{contextualHelp}</ContextualHelpTooltip>
        )}
      </Box>
      {labelIconEnd}
    </Stack>
  );
}
