import { DocumentNode } from '@apollo/client';
import { getOperationDefinition } from '@apollo/client/utilities';
import { OperationTypeNode } from 'graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

interface RelayConnection<N> {
  edges?: Maybe<RelayEdge<N>[]>;
}
type RelayEdge<N> = Maybe<Maybe<{ node?: N | null }>>;
type ExtractRelayNodeType<T extends RelayConnection<N>, N> = NonNullable<
  NonNullable<NonNullable<T['edges']>[number]>['node']
>;

export function getNodes<T extends RelayConnection<N>, N>(
  connection?: Maybe<RelayConnection<N>>
): ExtractRelayNodeType<T, N>[] {
  return (connection?.edges || []).flatMap((edge) => edge?.node ?? []);
}

export function getQueryNameFromDocument(doc: DocumentNode): string | null {
  const def = getOperationDefinition(doc);
  return def?.operation === OperationTypeNode.QUERY && def.name?.value
    ? def.name.value
    : null;
}
