import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import { ButtonGroup } from '../baseInputs/ButtonGroup';
import {
  ButtonGroupInputOption,
  ButtonGroupVariant,
  HelpTextVariant,
} from '../baseInputs/inputTypes';

export interface FormAwareButtonGroupProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  options: ButtonGroupInputOption[];
  required?: boolean;
  variant?: ButtonGroupVariant;
  control: Control<FormShape>;
  contextualHelp?: JSX.Element;
  validation?: RegisterOptions<FormShape>['validate'];
  disabled?: boolean;
  hideLabel?: boolean;
}

export function FormAwareButtonGroup<FormShape extends FieldValues>({
  fieldName,
  label,
  helpText,
  helpTextVariant,
  options,
  required,
  variant,
  control,
  contextualHelp,
  disabled,
  validation,
  hideLabel,
}: FormAwareButtonGroupProps<FormShape>) {
  const validations = getValidations(label, !!required, validation);
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <ButtonGroup
            inputRef={field.ref}
            label={label}
            variant={variant}
            options={options}
            onChange={field.onChange}
            contextualHelp={contextualHelp}
            value={field.value}
            name={field.name}
            required={required}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            disabled={disabled}
            hideLabel={hideLabel}
          />
        );
      }}
    />
  );
}
