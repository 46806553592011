import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import type { FieldValues, UseFormStateReturn } from 'react-hook-form';

interface FormModalActionsContext {
  isSubmitting: boolean;
  isDeleting: boolean;
  disabled: boolean;
}

const FormModalActionsContext = createContext<FormModalActionsContext>({
  isSubmitting: false,
  isDeleting: false,
  disabled: false,
});

export function useFormModalActionsContext(): FormModalActionsContext | null {
  return useContext(FormModalActionsContext);
}

type FormModalActionsProviderProps<T extends FieldValues> = PropsWithChildren<
  Partial<FormModalActionsContext>
> & {
  formState?: UseFormStateReturn<T>;
};

export const FormModalActionsProvider = <T extends FieldValues>({
  children,
  formState,
  isDeleting = false,
  ...props
}: FormModalActionsProviderProps<T>) => {
  const value = useMemo<FormModalActionsContext>(() => {
    const isSubmitting = !!(props.isSubmitting || formState?.isSubmitting);
    const disabled = props.disabled || isSubmitting || isDeleting;
    return { disabled, isSubmitting, isDeleting };
  }, [formState?.isSubmitting, isDeleting, props.disabled, props.isSubmitting]);

  return (
    <FormModalActionsContext.Provider value={value}>
      {children}
    </FormModalActionsContext.Provider>
  );
};
