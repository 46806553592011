import { Box, useTheme } from '@mui/material';
import { CSSProperties } from 'react';

interface ColorBoxProps {
  color: CSSProperties['background'];
  width?: CSSProperties['width'];
}

export function ColorBox({ color, width }: ColorBoxProps) {
  const theme = useTheme();

  return (
    <Box
      borderRadius={theme.spacing(0.5)}
      sx={{
        width: width || theme.spacing(1.5),
        height: width || theme.spacing(1.5),
        background: color,
      }}
    />
  );
}
