import { Path, useFieldArray } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';

import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';

export function useGiftScenarioListData(scenarioIdx: number) {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const giftFieldArrayMethods = useFieldArray({
    control,
    name: `scenarios.${scenarioIdx}.scenarioGifts` as const satisfies Path<GiftDesignerModelScenariosFormShape>,
    keyName: '_id',
  });

  const incomeAndExpensesFieldArrayMethods = useFieldArray({
    control,
    name: `scenarios.${scenarioIdx}.scenarioCashFlows` as const satisfies Path<GiftDesignerModelScenariosFormShape>,
    keyName: '_id',
  });

  return {
    giftFieldArrayMethods: giftFieldArrayMethods,
    incomeAndExpensesFieldArrayMethods,
  };
}
