const SEMANTIC_INDEX_LAYERS = [
  'BASE',
  'CARD',
  'HIGHLIGHT',
  'MENU',
  'MODAL',
  'CONTEXTUAL_HELP', // this needs to be over modal, since it's triggered by modals
  'POPOVER',
  'TOOLTIP',
] as const;

type IndexRecord = Record<(typeof SEMANTIC_INDEX_LAYERS)[number], number>;

function generateIndexes(
  semanticLayers: typeof SEMANTIC_INDEX_LAYERS
): IndexRecord {
  const res: Partial<IndexRecord> = {};
  semanticLayers.forEach((layerName, i) => {
    res[layerName] = i * 100;
  });

  return res as IndexRecord;
}

export const zIndexes = generateIndexes(SEMANTIC_INDEX_LAYERS);
