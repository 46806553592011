import Decimal from 'decimal.js';

import { PopperContent } from '@/components/poppers/PopperContent';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

interface SectionTooltipProps {
  value: Decimal;
  sectionKindCopy: string;
}

export function SectionTooltip({
  value,
  sectionKindCopy,
}: SectionTooltipProps) {
  return (
    <PopperContent
      sx={{
        width: 'auto',
      }}
      body={
        <MiniTable
          variant="default"
          rows={[
            {
              label: sectionKindCopy,
              value: formatCurrencyNoDecimals(value),
            },
          ]}
        />
      }
    />
  );
}
