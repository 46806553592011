// Base color tokens
export const ORANGE = {
  50: '#ffecef',
  100: '#ffd0d3',
  200: '#fa9f9b',
  300: '#f47973',
  400: '#ff594e', // main brand color
  500: '#ff4a30',
  600: '#f64131',
  700: '#e4362b',
  800: '#d72f24',
  900: '#c82417',
};

export const NAVY = {
  50: '#e0ebff',
  100: '#b9cfe9',
  200: '#97adcc',
  300: '#738daf',
  400: '#5a769a',
  500: '#3f6086',
  600: '#315375',
  700: '#224160',
  800: '#132f4a',
  900: '#001c33', // this is the primary navy
};

export const BLUE = {
  50: '#e1f5fa',
  100: '#b4e6f4',
  200: '#85d5ed',
  300: '#59c4e6',
  400: '#3ab7e3',
  500: '#20abdf',
  600: '#189dd2',
  700: '#0b8abf',
  800: '#0b79ab',
  900: '#005a8a', // this is the primary blue
};

export const GRAY = {
  50: '#f9fafb',
  100: '#f3f4f6',
  200: '#e5e7eb',
  300: '#d1d5db',
  400: '#9ca3af',
  500: '#6b7280',
  600: '#4b5563',
  700: '#374151',
  800: '#1f2937',
  900: '#111827',
};

export const NEUTRAL_GRAY = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#e5e5e5',
  300: '#d4d4d4',
  400: '#a3a3a3',
  500: '#737373',
  600: '#525252',
  700: '#404040',
  800: '#262626',
  900: '#171717',
};

export const TEAL = {
  50: '#f0fdfa',
  100: '#ccfbf1',
  200: '#99f6e4',
  300: '#5eead4',
  400: '#2dd4bf',
  500: '#14b8a6',
  600: '#0d9488',
  700: '#0f766e',
  800: '#115e59',
  900: '#134e4a',
};

const FUNCTIONAL_SUCCESS = {
  50: '#f0fdf4',
  100: '#dcfce7',
  200: '#bbf7d0',
  300: '#86efac',
  400: '#4ade80',
  500: '#22c55e',
  600: '#16a34a',
  700: '#15803d',
  800: '#166534',
  900: '#14532d',
};
const DEFAULT_FUNCTIONAL_SUCCESS = FUNCTIONAL_SUCCESS[500];

const FUNCTIONAL_WARNING = {
  50: '#fefce8',
  100: '#fef9c3',
  200: '#fef08a',
  300: '#fde047',
  400: '#facc15',
  500: '#eab308',
  600: '#ca8a04',
  700: '#a16207',
  800: '#854d0e',
  900: '#713f12',
};
const DEFAULT_FUNCTIONAL_WARNING = FUNCTIONAL_WARNING[400];

const FUNCTIONAL_ERROR = {
  50: '#fef2f2',
  100: '#fee2e2',
  200: '#fecaca',
  300: '#fca5a5',
  400: '#f87171',
  500: '#ef4444',
  600: '#dc2626',
  700: '#b91c1c',
  800: '#991b1b',
  900: '#7f1d1d',
};
const DEFAULT_FUNCTIONAL_ERROR = FUNCTIONAL_ERROR[600];

export const FUNCTIONAL = {
  SUCCESS: {
    ...FUNCTIONAL_SUCCESS,
    DEFAULT: DEFAULT_FUNCTIONAL_SUCCESS,
  },
  WARNING: {
    ...FUNCTIONAL_WARNING,
    DEFAULT: DEFAULT_FUNCTIONAL_WARNING,
  },
  ERROR: {
    ...FUNCTIONAL_ERROR,
    DEFAULT: DEFAULT_FUNCTIONAL_ERROR,
  },
  HOVER: 'rgba(224, 235, 255, .5)',
};

export const PRIMITIVES = {
  WHITE: '#ffffff',
  BLACK: '#111827',
};

const CATEGORIES = {
  IN_ESTATE: NAVY,
  FAMILY_GIVING: TEAL,
  CHARITABLE_GIVING: BLUE,
  ESTATE_TAX_SAVINGS: ORANGE,
};

const MATH = {
  NEGATIVE: ORANGE[600],
};

export const COLORS = {
  GRAY,
  NEUTRAL_GRAY,

  TEAL,
  BLUE,
  NAVY,

  ORANGE,

  FUNCTIONAL,
  PRIMITIVES,
  CATEGORIES,
  MATH,
};
