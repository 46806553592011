import { PopperContent } from '@/components/poppers/PopperContent';

export function GiftGrowthRate() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body={`If this is a recurring cash flow, enter the rate (%) the cash flow amount will increase or decrease each year.`}
    />
  );
}
