import { ReactNode } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import { FormAwareInputProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import {
  getDecimalJSSupportingOnChange,
  getFieldErrorValue,
  getPossiblyDecimalFieldValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import { FormattedNumberInput } from '../baseInputs/FormattedNumberInput';
import { HelpTextVariant } from '../baseInputs/inputTypes';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwareFormattedNumberInputProps<
  FormShape extends FieldValues,
> extends FormAwareInputProps<FormShape> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  control: Control<FormShape>;
  onFocus?: () => void;
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  contextualHelp?: JSX.Element;
  required?: boolean;
  formatHashString?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean | string;
  allowNegative?: boolean;
  isDecimalJSInput?: boolean;
  hideLabel?: boolean;
  endAdornment?: ReactNode;
  validateOnChange?: boolean;
}

export function FormAwareFormattedNumberInput<FormShape extends FieldValues>({
  fieldName,
  label,
  helpText,
  helpTextVariant,
  hideLabel = false,
  contextualHelp,
  required,
  formatHashString,
  decimalScale = 2,
  fixedDecimalScale,
  thousandSeparator,
  isDecimalJSInput,
  // the underlying library for this input defaults it to true, but i don't
  // think we probably want that
  allowNegative = false,
  control,
  onFocus,
  disabled,
  validation,
  endAdornment,
  validateOnChange,
  labelIconEnd,
}: FormAwareFormattedNumberInputProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, validation);
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <FormattedNumberInput
            label={label}
            hideLabel={hideLabel}
            required={required}
            name={field.name}
            inputRef={field.ref}
            onChange={getDecimalJSSupportingOnChange(field.onChange, {
              isDecimalJS: !!isDecimalJSInput,
            })}
            onBlur={field.onBlur}
            onFocus={onFocus}
            disabled={disabledFromContext ?? disabled}
            value={getPossiblyDecimalFieldValue(field.value, {
              isDecimalJS: !!isDecimalJSInput,
              fieldName: field.name,
              decimalScale,
            })}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            contextualHelp={contextualHelp}
            formatHashString={formatHashString}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            thousandSeparator={thousandSeparator}
            allowNegative={allowNegative}
            errorMessage={getFieldErrorValue(
              fieldState,
              formState.isSubmitted,
              validateOnChange
            )}
            endAdornment={endAdornment}
            labelIconEnd={labelIconEnd}
          />
        );
      }}
    />
  );
}
