import { Menu, MenuProps, styled } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

export type MenuPopperProps = React.PropsWithChildren<{
  anchorRef: React.RefObject<HTMLElement>;
}> &
  MenuProps;

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    border: `1px solid ${COLORS.GRAY[200]}`,
  },
  '& .MuiMenu-list': {
    padding: '4px 0',
  },
  '& .MuiMenuItem-root': {
    padding: '10px 14px',
  },
}));

export function MenuPopper({
  anchorRef,
  children,
  ...menuProps
}: MenuPopperProps) {
  return (
    <StyledMenu anchorEl={anchorRef.current} {...menuProps}>
      {children}
    </StyledMenu>
  );
}
