import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { isFunction } from 'lodash';

import {
  CellRenderProp,
  ExtendedGridColumnHeaderParams,
  HeaderRenderProp,
} from '../types';

export const getCellRenderProp = <
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
  O,
>({
  prop,
  props,
  defaultValue,
}: {
  prop?: CellRenderProp<R, V, F, N, O>;
  props: GridRenderCellParams<R, V, F, N>;
  defaultValue: O;
}) => {
  if (prop === undefined) return defaultValue;
  if (isFunction(prop)) {
    return prop(props);
  }
  return prop;
};

export const getHeaderRenderProp = <R extends GridValidRowModel, V, F, O>({
  prop,
  props,
  defaultValue,
}: {
  prop?: HeaderRenderProp<R, V, F, O>;
  props: ExtendedGridColumnHeaderParams<R, V, F>;
  defaultValue: O;
}) => {
  if (prop === undefined) return defaultValue;
  if (isFunction(prop)) {
    return prop(props);
  }
  return prop;
};
