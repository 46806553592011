import { isNil } from 'lodash';
import { Exact } from 'type-fest';

/**
 * @description Asserts a type is the exact type of an object, with no optional properties.
 * Helpful in asserting the correct inputs to graphql mutations.
 */
export function assertExact<T = never>() {
  return <V extends Exact<T, V>>(val: V): V => val;
}

export function assertNonNil<T>(
  t: T | null | undefined,
  message: string
): NonNullable<T> {
  if (isNil(t)) {
    throw new Error(message);
  }
  return t as NonNullable<T>;
}
