import { Stack, styled, Typography, useTheme } from '@mui/material';

import { Share04Icon } from '@/components/icons/Share04Icon';

import { getTriggerStyling } from './styles';

export type LinkProps = React.PropsWithChildren<{
  display: string;
  external?: boolean;
}> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

const StyledLink = styled('a')<{ color: string }>(({ theme, color }) => {
  return {
    ...getTriggerStyling(color, theme),
    // generally hiding text decoration is bad for accessibility, but we're replacing it with an
    // underline in the trigger styling.
    textDecoration: 'none',
  };
});

/**
 * This is a link element that's styled to be identical to the LinkButton.
 */

export function Link({ display, external, ...nativeButtonProps }: LinkProps) {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const externalLinkProps = external
    ? {
        target: '_blank',
      }
    : {};
  return (
    <StyledLink
      color={color}
      type="button"
      {...nativeButtonProps}
      {...externalLinkProps}
    >
      <Stack direction="row" alignItems="center" spacing={0.5} pb="3px">
        <Typography variant="h6" fontSize="sm">
          {display}
        </Typography>
        {external && (
          <Share04Icon
            sx={{
              height: 14,
              width: 14,
              display: 'flex',
              alignItems: 'center',
            }}
          />
        )}
      </Stack>
    </StyledLink>
  );
}
