import { css } from '@emotion/css';
import {
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { Dialog } from '../Dialog';
import { ModalContext } from '../ModalContext';

const fullHeightStyle = css({ height: '100%' });
const defaultStyle = css({
  height: '80vh',
  minHeight: 400,
  maxHeight: `800px !important`,
});

export type ModalProps = Pick<
  DialogProps,
  'classes' | 'maxWidth' | 'fullWidth'
> &
  React.PropsWithChildren<{
    heading: string;
    isOpen: boolean;
    onClose: () => void;
    actions?: JSX.Element;
    rightHeaderContent?: React.ReactNode;
    fullHeight?: boolean;
  }>;

export function Modal({
  isOpen,
  onClose,
  children,
  heading,
  rightHeaderContent,
  actions,
  fullHeight,
  ...dialogProps
}: ModalProps) {
  const { setModalState } = useContext(ModalContext);

  useEffect(() => {
    // update the dialog state when the modal is opened or closed
    setModalState((prevDialogState: Record<string, boolean>) => {
      const newState = {
        ...prevDialogState,
        [heading]: isOpen,
      };

      return newState;
    });
  }, [isOpen, heading, setModalState]);

  const modalTestId = useMemo(() => {
    let modalId = '';

    try {
      modalId = `modal-${heading.toLowerCase().split(' ').join('-')}-${
        isOpen ? 'open' : 'closed'
      }`;
    } catch {
      // do nothing
    }

    return modalId;
  }, [heading, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth={true}
      data-testid={modalTestId}
      classes={{
        paper: fullHeight ? fullHeightStyle : defaultStyle,
        ...dialogProps.classes,
      }}
      {...dialogProps}
    >
      <Stack
        direction="row"
        component="header"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderBottom: `solid 1px ${COLORS.ORANGE[400]}`,
          px: 3,
          py: 2,
        }}
      >
        {/* overwriting the padding on the dialog title to handle it within the header container */}
        <DialogTitle sx={{ p: 0 }} variant="h1">
          {heading}
        </DialogTitle>
        {rightHeaderContent && rightHeaderContent}
      </Stack>
      <DialogContent>{children}</DialogContent>
      {actions && (
        <DialogActions
          sx={{
            p: 1.5,
            borderTop: `solid 1px ${COLORS.NAVY[600]}`,
          }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
}
