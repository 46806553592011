import { isNil } from 'lodash';

/**
 * @description This function is used to simulate a form post-style submission
 * while still allowing you to use all of the more robust functionality
 * of our form and state management libraries and submit validation handling.
 */
export function formPost(
  path: string,
  params: Record<string, string | number | boolean>
): void {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = path;

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;

      const value = params[key]?.toString();
      if (!isNil(value)) {
        hiddenField.value = value;
      }

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  // we don't need to worry about removing the form from the DOM because we're
  // navigating away from the page
  form.submit();
}
