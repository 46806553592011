import { compact } from 'lodash';
import { useState } from 'react';

import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { GiftingProposalGiftRecipientKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { useGetScenarioGiftRecipientOptionsQuery } from './graphql/GetScenarioGiftRecipientOptions.generated';

const errorMessage =
  "We weren't able to initialize this form. Please refresh the page and try again.";

type GiftRecipientOptions = (
  | {
      display: string;
      value: string;
      kind: GiftingProposalGiftRecipientKind;
    }
  | {
      component: JSX.Element;
      type: 'component';
      kind: GiftingProposalGiftRecipientKind;
    }
)[];

export function useScenarioGiftRecipientOptions(proposalId: string) {
  const { createErrorFeedback, showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [recipientOptions, setRecipientOptions] =
    useState<GiftRecipientOptions>([]);

  const queryMethods = useGetScenarioGiftRecipientOptionsQuery({
    variables: {
      where: {
        id: proposalId,
      },
    },
    onCompleted(data) {
      const proposals = getNodes(data.proposals);
      const portfolioBeneficiaries =
        proposals[0]?.giftingProposal?.outOfEstatePortfolios ?? null;
      const clientBeneficiaries =
        proposals[0]?.household?.possibleBeneficiariesV2.clients ?? [];
      const organizationBeneficiaries =
        proposals[0]?.household?.possibleBeneficiariesV2.organizations ?? [];

      const portfolioRecipients =
        portfolioBeneficiaries?.map((portfolio) => ({
          display: portfolio.displayName,
          value: portfolio.id,
          kind: GiftingProposalGiftRecipientKind.OutOfEstatePortfolio,
        })) ?? [];

      const clientRecipients = clientBeneficiaries.map((c) => ({
        display: c.displayName,
        value: c.id,
        kind: GiftingProposalGiftRecipientKind.Individual,
      }));

      const organizationRecipients = organizationBeneficiaries.map((c) => ({
        display: c.name,
        value: c.id,
        kind: GiftingProposalGiftRecipientKind.Organization,
      }));

      if (!portfolioBeneficiaries) {
        const errorDescription =
          'Expected outOfEstatePortfolios for the proposal.';
        const err = new Error(errorDescription);
        reportError(errorDescription, err, {
          proposalId,
        });
        showFeedback(errorMessage);
        throw err;
      }

      const selectOptions = compact([
        (() =>
          clientRecipients.length
            ? {
                component: <SelectItemGroupLabel label="Individuals" />,
                type: 'component' as const,
                kind: GiftingProposalGiftRecipientKind.Individual,
              }
            : null)(),

        ...clientRecipients.sort((a, b) => a.display.localeCompare(b.display)),
        (() =>
          portfolioRecipients.length
            ? {
                component: (
                  <SelectItemGroupLabel label="Out-of-estate portfolios" />
                ),
                type: 'component' as const,
                kind: GiftingProposalGiftRecipientKind.OutOfEstatePortfolio,
              }
            : null)(),

        ...portfolioRecipients.sort((a, b) =>
          a.display.localeCompare(b.display)
        ),
        (() =>
          organizationRecipients.length
            ? {
                component: <SelectItemGroupLabel label="Organizations" />,
                type: 'component' as const,
                kind: GiftingProposalGiftRecipientKind.Organization,
              }
            : null)(),

        ...organizationRecipients.sort((a, b) =>
          a.display.localeCompare(b.display)
        ),
      ]);
      setRecipientOptions(selectOptions);
    },
    onError: createErrorFeedback(
      "We weren't able to initialize this form. Please refresh the page and try again."
    ),
  });

  return { recipientOptions, ...queryMethods };
}
