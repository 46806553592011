import { Context, createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface TabsContext<T extends string> {
  currentTab: T;
  setCurrentTab: Dispatch<SetStateAction<T>>;
}

export const TabsContext = createContext<TabsContext<string>>({
  currentTab: '',
  setCurrentTab: () => null,
});

export function useTabsContext<T extends string>() {
  return useGuardedContext<TabsContext<T>>(
    TabsContext as unknown as Context<TabsContext<T>>,
    'TabsContext'
  );
}
