import { Path, useWatch } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useFormContext } from '@/components/react-hook-form';
import { GiftGrowthRate } from '@/modules/content/tooltipContent/GiftGrowthRate';
import { GiftingProposalCashFlowCashFlowType } from '@/types/schema';

import { ScenarioIncomeAndExpensesFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { validateYearRange } from '../../GiftDesignerModelScenariosForm.utils';

type ScenarioIncomeAndExpensesField = Path<ScenarioIncomeAndExpensesFormShape>;

function Name() {
  const {
    control,
    formState: { isLoading },
  } = useFormContext<ScenarioIncomeAndExpensesFormShape>();

  return (
    <FormAwareTextInput<ScenarioIncomeAndExpensesFormShape>
      control={control}
      fieldName={
        'displayName' as const satisfies ScenarioIncomeAndExpensesField
      }
      label="Name"
      disabled={isLoading}
      required
    />
  );
}

function CashFlowType() {
  const { control } = useFormContext<ScenarioIncomeAndExpensesFormShape>();

  return (
    <FormAwareRadioGroup<ScenarioIncomeAndExpensesFormShape>
      control={control}
      fieldName={
        'cashFlowType' as const satisfies ScenarioIncomeAndExpensesField
      }
      options={[
        {
          label: 'Income',
          value: GiftingProposalCashFlowCashFlowType.Income,
        },
        {
          label: 'Expense',
          value: GiftingProposalCashFlowCashFlowType.Expense,
        },
      ]}
      row={true}
      required
    />
  );
}

function Amount() {
  const { control } = useFormContext<ScenarioIncomeAndExpensesFormShape>();

  return (
    <FormAwareCurrencyInput<ScenarioIncomeAndExpensesFormShape>
      control={control}
      fieldName={'amount' as const satisfies ScenarioIncomeAndExpensesField}
      isDecimalJSInput
      decimalScale={2}
      label="After-tax amount"
      required
    />
  );
}

// Need to use a custom type here because the radio group form expects value to be a string
export enum AnnuallyRecurringValue {
  true = 'isAnnuallyRecurring',
  false = 'isOneTime',
}

function AnnuallyRecurring() {
  const { control } = useFormContext<ScenarioIncomeAndExpensesFormShape>();

  return (
    <FormAwareRadioGroup<ScenarioIncomeAndExpensesFormShape>
      control={control}
      fieldName={
        'annuallyRecurring' as const satisfies ScenarioIncomeAndExpensesField
      }
      options={[
        {
          label: 'Annually recurring',
          value: AnnuallyRecurringValue.true,
        },
        {
          label: 'One-time',
          value: AnnuallyRecurringValue.false,
        },
      ]}
      row={true}
      required
    />
  );
}

function StartYear({ isAnnuallyRecurring }: { isAnnuallyRecurring: boolean }) {
  const { control } = useFormContext<ScenarioIncomeAndExpensesFormShape>();

  const lengthOfAnalysis = useWatch({
    control,
    name: 'lengthOfAnalysis',
  });

  const endYear = useWatch({
    control,
    name: 'endYear',
  });

  return (
    <FormAwareFormattedNumberInput<ScenarioIncomeAndExpensesFormShape>
      control={control}
      label={isAnnuallyRecurring ? 'Start year' : 'Year of income or expense'}
      fieldName={'startYear' as const satisfies ScenarioIncomeAndExpensesField}
      fixedDecimalScale={true}
      decimalScale={0}
      required
      isDecimalJSInput
      validation={{
        minMax: (value) =>
          validateYearRange(value, lengthOfAnalysis, {
            ltEq: endYear,
          }),
      }}
    />
  );
}

function EndYear() {
  const { control } = useFormContext<ScenarioIncomeAndExpensesFormShape>();

  const lengthOfAnalysis = useWatch({
    control,
    name: 'lengthOfAnalysis',
  });

  const startYear = useWatch({
    control,
    name: 'startYear',
  });

  return (
    <FormAwareFormattedNumberInput<ScenarioIncomeAndExpensesFormShape>
      control={control}
      label="End year"
      fieldName={'endYear' as const satisfies ScenarioIncomeAndExpensesField}
      fixedDecimalScale={true}
      decimalScale={0}
      required
      isDecimalJSInput
      validation={{
        minMax: (value) =>
          validateYearRange(value, lengthOfAnalysis, {
            gt: startYear,
          }),
      }}
    />
  );
}

function GrowthRate() {
  const { control } = useFormContext<ScenarioIncomeAndExpensesFormShape>();

  return (
    <FormAwarePercentInput<ScenarioIncomeAndExpensesFormShape>
      control={control}
      label={'Growth rate'}
      fieldName={
        'growthPercentage' as const satisfies ScenarioIncomeAndExpensesField
      }
      isDecimalJSInput
      contextualHelp={<GiftGrowthRate />}
      required
    />
  );
}

export const ScenarioIncomeAndExpensesFormFields = {
  Name,
  CashFlowType,
  Amount,
  AnnuallyRecurring,
  StartYear,
  EndYear,
  GrowthRate,
};
