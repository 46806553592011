import { Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  FieldArrayWithId,
  Path,
  UseFieldArrayInsert,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  useWatch,
} from 'react-hook-form';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { ArrowDownIcon } from '@/components/icons/ArrowDownIcon';
import { ArrowUpIcon } from '@/components/icons/ArrowUpIcon';
import { Copy07Icon } from '@/components/icons/Copy07Icon';
import { DotsVerticalIcon } from '@/components/icons/DotsVerticalIcon';
import { DeleteMenuItemWithConfirmationModal } from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { MAX_GIFT_SCENARIOS_COUNT } from './GiftScenarios';

interface GiftScenarioProps {
  scenario: FieldArrayWithId<
    GiftDesignerModelScenariosFormShape,
    'scenarios',
    '_id'
  >;
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
  insert: UseFieldArrayInsert<GiftDesignerModelScenariosFormShape, 'scenarios'>;
  idx: number;
}
export function GiftScenarioCardTitle({
  scenario,
  remove,
  move,
  insert,
  idx,
}: GiftScenarioProps) {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scenarios = useWatch({
    control,
    name: 'scenarios' as const satisfies Path<GiftDesignerModelScenariosFormShape>,
    defaultValue: [],
  });

  const currentScenarioName = useMemo(() => {
    const currentScenario = scenarios[idx];

    if (!currentScenario) {
      return null;
    }

    return currentScenario.name;
  }, [idx, scenarios]);

  const isNoGiftingScenario =
    'isNoGiftingScenario' in scenario && scenario.isNoGiftingScenario;

  const numScenarios = scenarios.length;

  const scenarioLabelText = useMemo(() => {
    if (isNoGiftingScenario) {
      return 'Baseline';
    }

    if (currentScenarioName) {
      return currentScenarioName;
    }

    return 'Enter a scenario name...';
  }, [currentScenarioName, isNoGiftingScenario]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      pb={2}
    >
      <Stack spacing={0.5}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: FONT_WEIGHTS.bold,
          }}
        >
          Scenario {idx + 1}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: FONT_WEIGHTS.bold,
            color: COLORS.NAVY[600],
          }}
        >
          {scenarioLabelText}
        </Typography>
      </Stack>
      <ButtonWithPopover
        size="xs"
        variant="transparent"
        popperVariant="menuBelow"
        onClick={() => setIsMenuOpen(true)}
        isOpen={isMenuOpen}
        label={<DotsVerticalIcon size={16} />}
        hideChevron={true}
        data-testid="GiftScenarioCardTitle-menuButton"
        square
        highlightWhenOpen
      >
        <MenuItem
          icon={<ArrowUpIcon size={18} />}
          label="Move up"
          onClick={() => {
            setIsMenuOpen(false);
            move(idx, idx - 1);
          }}
          disabled={idx === 0}
        />
        <MenuItem
          icon={<ArrowDownIcon size={18} />}
          label="Move down"
          onClick={() => {
            setIsMenuOpen(false);
            move(idx, idx + 1);
          }}
          disabled={idx === numScenarios - 1}
        />
        <MenuItem
          icon={<Copy07Icon size={18} />}
          label="Duplicate scenario"
          onClick={() => {
            setIsMenuOpen(false);
            // Guaranteed to exist because we are duplicating an existing scenario
            // and disabling the button if the scenario doesn't exist at this index.
            const scenario = scenarios[idx]!;
            insert(idx + 1, {
              ...scenario,
              name: `${scenario.name} (copy)`,
            });
          }}
          muiMenuItemProps={{
            divider: true,
          }}
          // Disable the duplicate button if we are at the max number of scenarios
          // or if we are on the special default scenario.
          disabled={
            !scenarios[idx] ||
            numScenarios === MAX_GIFT_SCENARIOS_COUNT ||
            isNoGiftingScenario
          }
        />
        <DeleteMenuItemWithConfirmationModal
          label="Delete scenario"
          modalProps={{
            heading: 'Delete scenario',
            children: (
              <>
                Are you sure you’d like to delete this scenario? This cannot be
                undone.
              </>
            ),
          }}
          onConfirmDelete={() => {
            remove(idx);
            setIsMenuOpen(false);
          }}
        />
      </ButtonWithPopover>
    </Stack>
  );
}
