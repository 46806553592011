import { Box, Typography } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

export function DefaultDataTableHeader({ children }: React.PropsWithChildren) {
  return (
    <Box p={1.5} mb={3} data-it="DataTable-headerContainer" component="header">
      <Typography color={COLORS.NAVY[600]} variant="h1" component="h2">
        {children}
      </Typography>
    </Box>
  );
}
