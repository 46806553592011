import { useGuardedContext } from '@/hooks/useGuardedContext';

import { FeedbackContext, FeedbackHook } from './feedback.context';

export enum FeedbackMessages {
  formSaveSuccess = 'Your changes have been saved.',
  formSaveError = "We weren't able to save your changes. Please try again.",
  queryError = "We weren't able to load your data. Please try again.",
  deleteError = 'Deletion failed. Please try again.',
}

export function useFeedback(): FeedbackHook {
  return useGuardedContext(FeedbackContext, 'FeedbackProvider');
}
