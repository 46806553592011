import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';

import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BaseTextInputProps,
  FormControlTextInputProps,
  HelpTextVariant,
} from '../inputTypes';

type MUITextInputProps = Omit<
  OutlinedTextFieldProps,
  // these conflict with our internally-defined props
  'maxRows' | 'rows' | 'onKeyDown' | 'onKeyUp' | 'variant'
>;

interface TextInputProps extends FormControlTextInputProps, MUITextInputProps {
  label: React.ReactNode;
  hideLabel?: boolean;
  id?: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  labelIconEnd?: React.ReactNode;
  autoFocus?: boolean;
}

export function BaseTextInput({
  // see here: https://github.com/mui/material-ui/issues/12946
  // stripping off onKeyUp and onKeyDown is required here because TextField doesn't actually implement those props.
  onKeyUp: _onKeyUp,
  onKeyDown: _onKeyDown,
  startAdornment,
  endAdornment,
  testId,
  autoFocus,
  onKeyDown,
  ...otherProps
}: BaseTextInputProps & MUITextInputProps) {
  const { disabled, multiline } = otherProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  const multilineStyle: Record<string, unknown> = {};
  if ((otherProps.rows && otherProps.rows > 1) || multiline) {
    multilineStyle.padding = 0;
  }
  const mergedSx = Object.assign(
    { background: 'white' },
    disabledStyle,
    multilineStyle
  );

  return (
    <TextField
      variant="outlined"
      {...otherProps}
      InputProps={{
        sx: mergedSx,
        startAdornment,
        endAdornment,
        autoFocus,
        onKeyDown,
        ...otherProps.InputProps,
      }}
      inputProps={{
        ...otherProps.inputProps,
        'data-testid': testId ?? `text-input-${otherProps.name ?? ''}`,
      }}
    />
  );
}

export function TextInput({
  label,
  hideLabel,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  labelIconEnd,
  ...inputProps
}: TextInputProps) {
  return (
    <FormControl<BaseTextInputProps>
      component={BaseTextInput}
      inputProps={inputProps}
      errorMessage={errorMessage}
      contextualHelp={contextualHelp}
      required={inputProps.required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      id={inputProps.id}
      label={label}
      hideLabel={hideLabel}
      labelIconEnd={labelIconEnd}
    />
  );
}
