import { styled } from '@mui/material';
import MuiSwitch from '@mui/material/Switch';

import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  FormControlLabelPosition,
  FormControlSwitchInputProps,
  HelpTextVariant,
  SwitchVariant,
} from '../inputTypes';

interface SwitchInputProps extends FormControlSwitchInputProps {
  label: string;
  labelPosition?: FormControlLabelPosition;
  variant?: SwitchVariant;
  id?: string;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
}

const StyledSwitch = styled((props: SwitchInputProps) => {
  // eslint-disable-next-line
  const { error, variant: _variant, testId, ...rest } = props;

  return (
    <MuiSwitch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      checked={props.value ?? false}
      {...rest}
      inputProps={{
        // eslint-disable-next-line
        // @ts-ignore this has to be set via the inputProps, so that it's set
        // on the actual <input> element, so playwright can set the checked state.
        'data-testid': testId ?? `checkbox-input-${props.name ?? ''}`,
      }}
    />
  );
})<{ variant: SwitchVariant }>(({ theme, variant }) => ({
  width: 44,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '250ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: COLORS.NAVY[500],
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: COLORS.NAVY[500],
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: variant === 'dark' ? COLORS.GRAY[300] : COLORS.GRAY[200],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export function Switch({
  label,
  labelPosition,
  errorMessage,
  helpText,
  helpTextVariant,
  contextualHelp,
  ...inputProps
}: SwitchInputProps) {
  return (
    <FormControl
      labelPosition={labelPosition}
      contextualHelp={contextualHelp}
      component={StyledSwitch}
      inputProps={inputProps}
      errorMessage={errorMessage}
      required={inputProps.required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      hideRequirementIndicator
      id={inputProps.id}
      label={label}
    />
  );
}
