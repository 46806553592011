import {
  ApolloClient,
  DocumentNode,
  Reference,
  StoreObject,
} from '@apollo/client';
import { IncomingHttpHeaders } from 'http';

import { GetUserStateDocument } from '@/modules/authentication/graphql/Authentication.generated';
import { FeatureFlagsDocument } from '@/modules/featureFlags/graphql/FeatureFlags.generated';
import { GlobalSearchDocument } from '@/modules/globalSearch/graphql/GlobalSearchQuery.generated';
import { IrsConstantsDocument } from '@/modules/irs/graphql/IRSConstants.generated';
import { TenantInformationDocument } from '@/modules/tenant/graphql/TenantInformation.generated';
import { getQueryNameFromDocument } from '@/utils/graphqlUtils';

const GRAPHQL_ROUTE = 'api/v1/graphql';

export function validSubdomain(str: string | undefined): boolean {
  if (!str) {
    return false;
  }

  return /^([A-Za-z0-9-]){1,63}$/.test(str);
}

const PORT = 3000;

export function getGraphqlEndpoint(
  headers: IncomingHttpHeaders | null = null,
  { isWebSocket }: { isWebSocket?: boolean } = { isWebSocket: false }
) {
  if (typeof window === 'undefined') {
    // We're on the server
    const subdomain = headers?.host?.split('.')[0];

    // NOTE: be careful here, since this `subdomain` var is user-provided data
    // and make sure it is at least safe to use in a URL interpolation
    if (subdomain && !validSubdomain(subdomain)) {
      throw new Error('Invalid subdomain');
    }

    if (process.env.NODE_ENV === 'production') {
      // We're on the server in production or staging
      if (process.env.NEXT_PUBLIC_DEPLOYMENT_STAGE === 'staging') {
        // We're on the server in staging
        return `${isWebSocket ? 'wss' : 'https'}://${subdomain}.withluminary.dev/${GRAPHQL_ROUTE}`;
      } else if (process.env.NEXT_PUBLIC_DEPLOYMENT_STAGE === 'pentest') {
        // We're on the server in pentest
        return `${isWebSocket ? 'wss' : 'https'}://${subdomain}.withluminary.app/${GRAPHQL_ROUTE}`;
      } else {
        // We're on the server in production
        return `${isWebSocket ? 'wss' : 'https'}://${subdomain}.withluminary.com/${GRAPHQL_ROUTE}`;
      }
    } else {
      // We're on the server locally
      return `${isWebSocket ? 'ws' : 'http'}://${subdomain}.localhost:${PORT}/${GRAPHQL_ROUTE}`;
    }
  } else {
    const websocketProtocol =
      window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    // We're on the client
    return `${isWebSocket ? websocketProtocol : window.location.protocol}//${window.location.hostname}:${window.location.port}/${GRAPHQL_ROUTE}`;
  }
}

// Queries that we know will return the same data given a
// certain input and do not need to be refetched after a mutation
const IGNORED_REFETCH_DOCUMENTS = [
  GlobalSearchDocument, // Search results should not update after a mutation
  IrsConstantsDocument, // IRS constants are static for a given session
  FeatureFlagsDocument, // Feature flags are static for a given session
  GetUserStateDocument, // User state is static for a given session
  TenantInformationDocument, // Tenant information is static for a given session
];

const IGNORED_REFETCH_QUERIES = IGNORED_REFETCH_DOCUMENTS.map(
  getQueryNameFromDocument
);

// Returns true if the query should be refetched after a mutation
export function shouldRefetchQuery(
  queryName?: string,
  opts?: {
    ignoredQueryDocuments?: DocumentNode[];
    allowedQueryDocuments?: DocumentNode[];
    ignoredWhen?: () => boolean;
  }
) {
  if (!queryName) {
    return false;
  }

  if (opts?.allowedQueryDocuments) {
    return opts.allowedQueryDocuments
      .map(getQueryNameFromDocument)
      .includes(queryName);
  }

  const ignoredQueries = [
    ...IGNORED_REFETCH_QUERIES,
    ...(opts?.ignoredQueryDocuments?.map(getQueryNameFromDocument) ?? []),
  ];

  // If the query is not in the ignored list, it should be refetched
  if (!ignoredQueries.includes(queryName)) return true;

  // If the query is in the ignored list, check if there are subsequent conditions
  if (opts?.ignoredWhen) {
    // If the condition is met, do not refetch the query
    return !opts.ignoredWhen();
  }

  return false;
}

export async function invalidateCacheObject(
  obj: Reference | StoreObject,
  client: ApolloClient<unknown>
) {
  await client.refetchQueries({
    updateCache(cache) {
      cache.evict({ id: cache.identify(obj) });
      cache.gc();
    },
  });
}

/**
 * This function is used to invalidate a specific field in the Apollo Client cache.
 *
 * @param fieldName - The name of the field to be invalidated in the cache.
 *
 * @description This function is particularly useful in situations where *list data* in the cache might be outdated and needs to be refreshed.
 * For example, after a mutation that created a new entity, you might want to call this function to invalidate the list of entities
 * in the cache, because there's no other way that we can think of to inform the apollo cache that there's a new object
 * in the root `entities` list.
 *
 * Any non no-cache query that depends on entities, for example, would refetch if this is called, so it's pretty safe in terms of
 * ensuring consistency. There are a number of other was of updating the cache including cache.modify, which is more of a scalpel
 * while this function, using cache.evict, is more of a hammer.
 *
 * Note: Use this function with caution as it directly manipulates the cache and can lead to inconsistencies if not used properly.
 */
export async function invalidateCacheField(
  fieldName: ApolloCacheRootField,
  client: ApolloClient<unknown>
) {
  await client.refetchQueries({
    updateCache(cache) {
      cache.evict({ id: 'ROOT_QUERY', fieldName });
      // remove any dangling data from the cache
      cache.gc();
    },
  });
}

/**
 * This is an incomplete list of the available fields on the ROOT_QUERY that you can evict.
 * If you need to evict a field that is not listed here, you can find the field name by
 * inspecting the cache in the Apollo DevTools, confirming the name, and then adding it here.
 */
type ApolloCacheRootField = 'entities' | 'assetClasses';
