import { HTMLInputTypeAttribute } from 'react';
import {
  Controller,
  FieldValues,
  RegisterOptions,
  ValidationRule,
} from 'react-hook-form';

import { FormAwareInputProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';

import { HelpTextVariant } from '../baseInputs/inputTypes';
import { TextInput } from '../baseInputs/TextInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwareTextInputProps<FormShape extends FieldValues>
  extends FormAwareInputProps<FormShape> {
  hideLabel?: boolean;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  placeholder?: string;
  validation?: RegisterOptions<FormShape>['validate'];
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  startAdornment?: React.ReactNode;
  contextualHelp?: JSX.Element;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  testId?: string;
  autoFocus?: boolean;
  endAdornment?: React.ReactNode;
}

const validationPatterns: Partial<
  Record<HTMLInputTypeAttribute, ValidationRule<RegExp>>
> = {
  email: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Enter an email address in the form of user@domain.com',
  },
};

export function FormAwareTextInput<FormShape extends FieldValues>({
  fieldName,
  label,
  hideLabel,
  helpText,
  helpTextVariant,
  required,
  placeholder,
  type = 'text',
  control,
  onFocus,
  onKeyDown,
  disabled,
  validation,
  startAdornment,
  contextualHelp,
  multiline,
  rows,
  maxRows,
  testId,
  labelIconEnd,
  endAdornment,
  autoFocus,
}: FormAwareTextInputProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, validation);
  const validationPattern = validationPatterns[type];

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        validate: validations,
        pattern: validationPattern,
      }}
      render={({ field, fieldState, formState }) => {
        return (
          <TextInput
            hideLabel={hideLabel}
            testId={testId}
            label={label}
            name={field.name}
            required={required}
            onChange={field.onChange}
            onBlur={field.onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            disabled={disabledFromContext ?? disabled}
            value={field.value}
            inputRef={field.ref}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            type={type}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            startAdornment={startAdornment}
            contextualHelp={contextualHelp}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            labelIconEnd={labelIconEnd}
            endAdornment={endAdornment}
            autoFocus={autoFocus}
          />
        );
      }}
    />
  );
}
