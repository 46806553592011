export class UnreachableError extends Error {
  /**
   * Typical usage is to throw this error in a default case of a switch statement.
   * You shouldn't need to cast the case to `never`. If you find yourself doing
   * that, it's a sign that the switch statement is not exhaustive. This will
   * help us catch when new enums are added that need to be handled.
   */
  constructor(params: { case: never; message: string }) {
    super(`Reached unexpected unreachable branch: ${params.message}`);
  }
}

export class ValidationError extends Error {
  constructor(params: { message: string }) {
    super(params.message);
  }
}

export class ClipboardAPIError extends Error {
  constructor() {
    super('Clipboard API is not supported in this browser');
  }
}
