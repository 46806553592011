import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface SelectInputContext {
  activeSelectInput: string | null;
  pushActiveSelectInput: (id: string) => void;
  popActiveSelectInput: () => void;
  removeSelectFromStack: (id: string) => void;
  peekActiveSelectInput: () => string | null;
}

export const SelectInputContext = createContext<SelectInputContext>({
  activeSelectInput: null,
  pushActiveSelectInput: () => null,
  popActiveSelectInput: () => null,
  removeSelectFromStack: () => null,
  peekActiveSelectInput: () => null,
});

export const useSelectInputContext = () => {
  return useGuardedContext(SelectInputContext, 'SelectInputContextProvider');
};
