import { lighten, recomposeColor } from '@mui/material';
import {
  analogue,
  colorScheme,
  complement,
  RGB,
  rgbToHsl,
  saturation,
} from 'simpler-color';

function hexToRBG(args: string): RGB {
  const match = args.toString().match(/[a-f0-9]{6}|[a-f0-9]{3}/i);
  if (!match) {
    return {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    };
  }

  let colorString = match[0];

  if (match[0].length === 3) {
    colorString = colorString
      .split('')
      .map((char: string) => {
        return char + char;
      })
      .join('');
  }

  const integer = parseInt(colorString, 16);
  const r = (integer >> 16) & 0xff;
  const g = (integer >> 8) & 0xff;
  const b = integer & 0xff;

  return {
    r,
    g,
    b,
    a: 1,
  };
}

export function ultraLight(color: string) {
  const rgb = hexToRBG(color);

  const { h: hue } = rgbToHsl(rgb);

  // pure grays (e.g. #333333) will be NaN here because grays have no actual hue. we'll just lighten
  // them, which is fine.
  if (isNaN(hue)) {
    return lighten(color, 0.92);
  }

  return recomposeColor({ type: 'hsl', values: [hue, 100, 92] });
}

function generateHeroDark() {
  return 'hsl(' + Math.floor(Math.random() * 360) + ', 90%, 30%)';
}

function generateHeroLight() {
  return 'hsl(' + Math.floor(Math.random() * 140) + ', 100%, 50%)';
}
export const getRandomPalette = (): [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
] => {
  return [
    generateHeroDark(),
    generateHeroDark(),
    generateHeroDark(),
    generateHeroDark(),
    generateHeroDark(),
    generateHeroLight(),
    '#000',
  ];
};

type Color = string;
interface BrandingInput {
  brandPrimaryDark: Color; // main dark color for the brand
  brandSecondaryDark?: Color; // optional secondary dark color
  brandPrimaryLight?: Color; // optional main light color for the brand
}

interface Branding {
  brandPrimaryDark: Color; // main dark color for the brand
  brandSecondaryDark: Color; // optional secondary dark color
  brandPrimaryLight: Color; // optional main light color for the brand
}

const makeDemoThemePrimitives = ({
  brandPrimaryDark,
  brandSecondaryDark,
  brandPrimaryLight,
}: BrandingInput): Branding => {
  const themePrimitives = {
    brandPrimaryDark: brandPrimaryDark,
    brandSecondaryDark: brandSecondaryDark ?? analogue(brandPrimaryDark, 1),
    brandPrimaryLight:
      brandPrimaryLight ?? saturation(complement(brandPrimaryDark, 1), 80),
  };

  return themePrimitives;
};

const makeDemoSchemeFromBrand = (brand: Branding) => {
  const scheme = colorScheme(brand, (colors) => {
    return {
      PRIMARY: brand.brandPrimaryDark,
      SECONDARY: brand.brandSecondaryDark ?? colors.brandSecondaryDark(60),
      BUTTONS_LINKS: brand.brandPrimaryDark,
      DATA_VISUALIZATION_PRIMARY: saturation(colors.brandPrimaryDark(40), 60),
      DATA_VISUALIZATION_SECONDARY: saturation(
        colors.brandSecondaryDark(60),
        40
      ),
      DATA_VISUALIZATION_TERTIARY: saturation(
        colors.brandSecondaryDark(80),
        60
      ),
    };
  });

  return [scheme, Object.values(scheme)];
};

const brands: BrandingInput[] = [
  {
    brandPrimaryDark: '#7D9CC0', // Goldman blue
  },
  {
    brandPrimaryDark: '#004879', // Capital One blue
    brandPrimaryLight: '#D22E1E', // Capital One red
  },
  {
    brandPrimaryDark: '#003F2D', // TD dark green
    brandPrimaryLight: '#54B848', // TD light green
  },
  {
    brandPrimaryDark: '#301504', // UPS brown
    brandPrimaryLight: '#FCB900', // UPS gold
  },
  {
    brandPrimaryDark: '#00704A', // Starbucks green
  },
  {
    brandPrimaryDark: '#4D148C', // FedEx purple
    brandSecondaryDark: '#FF6600', // FedEx orange
  },
  {
    brandPrimaryDark: '#ff9900', // Amazon orange
    brandSecondaryDark: '#146eb4', // Amazon blue
  },
];

let currentBrandIdx = 0;

export const getDemoTheme = () => {
  const [theme, colors] = makeDemoSchemeFromBrand(
    makeDemoThemePrimitives(brands[currentBrandIdx]!)
  );

  currentBrandIdx += 1;

  if (currentBrandIdx >= brands.length) {
    currentBrandIdx = 0;
  }

  return [theme, colors as string[]] as const;
};
