import {
  Card as MUICard,
  CardProps as MUICardProps,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

export type CardVariant =
  | 'outlined'
  | 'filled'
  | 'filled-light'
  | 'filled-callout'
  | 'filled-dark'
  | 'filled-error'
  | 'filled-teal'
  | 'transparent'
  | 'inner-shadow'
  | 'inner-shadow-light'
  | 'outlined-highlight'
  | 'outlined-shadow';

export type CardProps = Omit<MUICardProps, 'variant'> &
  React.PropsWithChildren<{
    variant: CardVariant;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter legacy
    inputRef?: React.Ref<any>;
  }> &
  React.HTMLProps<HTMLDivElement>;

function getVariantStyle(variant: CardProps['variant'], theme: Theme) {
  switch (variant) {
    case 'outlined':
      // this case is handled by the default card component
      return {};
    case 'filled-callout': {
      return {
        border: `none`,
        bgcolor: COLORS.FUNCTIONAL.HOVER,
      };
    }
    case 'filled-light': {
      return {
        border: `none`,
        bgcolor: COLORS.GRAY[50],
      };
    }
    case 'filled-dark':
      return {
        border: 'none',
        bgcolor: COLORS.GRAY[200],
      };
    case 'filled-error':
      return {
        border: 'none',
        bgcolor: COLORS.FUNCTIONAL.ERROR[50],
      };
    case 'filled-teal': {
      return {
        border: `none`,
        bgcolor: COLORS.TEAL[50],
      };
    }
    case 'filled':
      return {
        border: 'none',
        bgcolor: COLORS.GRAY[100],
      };
    case 'transparent':
      return {
        border: 'none',
        bgcolor: COLORS.PRIMITIVES.WHITE,
      };

    case 'inner-shadow':
      return {
        bgcolor: COLORS.GRAY[100],
        borderRadius: 1,
        boxShadow: theme.palette.shadows.mdInset,
      };

    case 'inner-shadow-light':
      return {
        bgcolor: COLORS.PRIMITIVES.WHITE,
        borderRadius: 1,
        boxShadow: theme.palette.shadows.mdInset,
      };

    case 'outlined-highlight':
      return {
        borderColor: COLORS.FUNCTIONAL.WARNING[400],
      };

    case 'outlined-shadow':
      return {
        borderColor: COLORS.GRAY[200],
        boxShadow: theme.palette.shadows.md,
      };

    default:
      throw new Error(`Unrecognized card variant ${variant}`);
  }
}

export function Card({
  variant,
  children,
  sx,
  inputRef,
  ...cardProps
}: CardProps) {
  const theme = useTheme();
  const mergedSx: SxProps<Theme> = Object.assign(
    getVariantStyle(variant, theme),
    sx || {}
  );
  return (
    <MUICard ref={inputRef} {...cardProps} sx={mergedSx}>
      {children}
    </MUICard>
  );
}
