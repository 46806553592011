import { isString } from 'lodash';

// ASSET_VALUE
export const enumLikeRegex = /^([A-Z]|_)+$/;

// AssetValue
export const pascalCaseLikeRegex = /[A-Z][a-z]+|[0-9]+/g;

const nonWhitespaceRegex = /^\S*$/;

export function isEnumLike(value?: unknown): value is string {
  return isString(value) && enumLikeRegex.test(value);
}

export function isPascalCaseLike(value?: unknown): value is string {
  return (
    isString(value) &&
    pascalCaseLikeRegex.test(value) &&
    nonWhitespaceRegex.test(value)
  );
}
