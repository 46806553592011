import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { GiftProposalProjection } from '@/modules/gifting/proposal/giftProposal.types';
import { getTaxMetricsForProjection } from '@/modules/gifting/proposal/giftProposal.utils';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_SIZING, FONT_WEIGHTS } from '@/styles/tokens/fonts';

import { useProjectionsForProposal } from '../../../../hooks/useProjectionsForProposal';
import { calculateTotalWealthForProjection } from '../../GiftDesignerModelScenariosForm.utils';
import { ValueWithArrow } from './ValueWithArrow';

interface GiftBenefitsCardProps {
  proposalId: string;
  projections?: GiftProposalProjection[];
  gridTemplateColumns?: string;
}

export const titleStyles: SxProps<Theme> = {
  fontSize: FONT_SIZING.xs.fontSize,
  fontWeight: FONT_WEIGHTS.bold,
  lineHeight: FONT_SIZING.xs.lineHeight,
  color: COLORS.PRIMITIVES.WHITE,
  letterSpacing: '0.12px',
};

const subtitleStyles: SxProps<Theme> = {
  color: COLORS.NAVY[100],
  fontSize: FONT_SIZING.xs.fontSize,
  fontWeight: FONT_WEIGHTS.regular,
  lineHeight: FONT_SIZING.xs.lineHeight,
  letterSpacing: '0.12px',
};

export function GiftBenefitsCard({
  proposalId,
  projections: externalProjections,
  gridTemplateColumns,
}: GiftBenefitsCardProps) {
  const theme = useTheme();
  const { projections } = useProjectionsForProposal(
    proposalId,
    externalProjections
  );

  if (!projections?.length || projections.length === 1) {
    return null;
  }

  const baselineProjection = projections[0]!;

  const baselineWealth = calculateTotalWealthForProjection({
    projection: baselineProjection,
  });

  return (
    <Box px={4} py={3} bgcolor={theme.palette.primary.main} borderRadius={1.5}>
      <Box
        display="grid"
        // First column is auto fit to the content width, the rest of the columns can expand up to 270px
        gridTemplateColumns={
          gridTemplateColumns ??
          `auto repeat(${projections.length}, minmax(auto, 270px))`
        }
        rowGap={1}
      >
        <Typography variant="subtitle2" sx={titleStyles}>
          Benefits of gifting
        </Typography>

        {projections.map((projection, idx) => {
          return (
            <Typography
              key={`scenario-${idx}`}
              variant="subtitle2"
              sx={{
                ...titleStyles,
                color: COLORS.NAVY[50],
                textAlign: 'right',
              }}
            >
              Scenario {idx + 1}
              {projection?.scenario?.isBaseline ? ' (Baseline)' : ''}
            </Typography>
          );
        })}

        <Typography variant="subtitle2" sx={subtitleStyles}>
          Taxable gift
        </Typography>

        {projections.map((p, idx) => {
          const differenceFromBaseline = p.taxableGift.minus(
            baselineProjection?.taxableGift ?? 0
          );

          return (
            <ValueWithArrow
              key={`scenario-taxable-gift-${idx}`}
              value={differenceFromBaseline}
              isCompareSource={p.scenario?.isBaseline}
            />
          );
        })}

        <Typography variant="subtitle2" sx={subtitleStyles}>
          Change in total wealth
        </Typography>

        {projections.map((p, idx) => {
          const totalWealth = calculateTotalWealthForProjection({
            projection: p,
          });

          const differenceFromBaseline = totalWealth.minus(baselineWealth);

          return (
            <ValueWithArrow
              key={`scenario-net-wealth-${idx}`}
              value={differenceFromBaseline}
              isCompareSource={p.scenario?.isBaseline}
            />
          );
        })}

        <Typography variant="subtitle2" sx={subtitleStyles}>
          Change in gift & estate tax
        </Typography>

        {projections.map((p, idx) => {
          const { combinedGiftAndEstateTax: projectionGiftAndEstateTax } =
            getTaxMetricsForProjection(p);
          const { combinedGiftAndEstateTax: baselineGiftAndEstateTax } =
            getTaxMetricsForProjection(baselineProjection);

          const differenceFromBaseline = projectionGiftAndEstateTax.minus(
            baselineGiftAndEstateTax
          );

          return (
            <ValueWithArrow
              key={`scenario-estate-tax-${idx}`}
              value={differenceFromBaseline}
              isCompareSource={idx === 0}
            />
          );
        })}
      </Box>
    </Box>
  );
}
