/* eslint-disable react/display-name */
import { Stack } from '@mui/material';
import {
  GridColumnHeaderParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';

import { HeaderCellTypography } from '../../../components/HeaderCellTypography';
import { cellPaddingX, cellPaddingY } from '../../components/cells';
import { HeaderRenderProp } from '../../types';
import { getHeaderRenderProp } from '../../utils/getRenderProp';

export interface ContextualHelpHeaderRendererProps<
  R extends GridValidRowModel,
  V,
  F,
> {
  header?: HeaderRenderProp<R, V, F, string>;
  contextualHelp?: HeaderRenderProp<R, V, F, JSX.Element>;
}

export const ContextualHelpHeaderRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends string = string,
  F = V,
>(
  factoryProps?: ContextualHelpHeaderRendererProps<R, V, F>
) => {
  return (props: GridColumnHeaderParams<R, V, F>) => {
    const header = getHeaderRenderProp({
      props,
      prop: factoryProps?.header,
      defaultValue: props.colDef.headerName,
    });

    const contextualHelp = getHeaderRenderProp({
      props,
      prop: factoryProps?.contextualHelp,
      defaultValue: null,
    });

    return (
      <Stack
        spacing={1}
        textOverflow="ellipsis"
        overflow="hidden"
        sx={{
          padding: `${cellPaddingY} ${cellPaddingX}`,
        }}
        direction="row"
      >
        <HeaderCellTypography>{header}</HeaderCellTypography>
        {contextualHelp && (
          <ContextualHelpTooltip sx={{ '@media print': { display: 'none' } }}>
            {contextualHelp}
          </ContextualHelpTooltip>
        )}
      </Stack>
    );
  };
};
