import { StateCode } from '@/types/schema';

export const exemptionNotApplicableStates = [StateCode.Il, StateCode.Ny];

export function getStateTaxTooltipContent(state: StateCode) {
  if (exemptionNotApplicableStates.includes(state)) {
    return `Assumes the full taxable estate is taxed according to the progressive state tax brackets.`;
  }

  return `Assumes the taxable estate in excess of the exemption is taxed according to the progressive state tax brackets.`;
}
