import { makeVar } from '@apollo/client';
import { startOfHour } from 'date-fns';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useReportError } from '@/hooks/useReportError';

import { useIrsConstantsQuery } from './graphql/IRSConstants.generated';

export const localFederalEstateTaxPercentFormattedVar =
  makeVar<string>(EMPTY_CONTENT_HYPHEN);

const DEFAULT_IRS_CONSTANTS = {
  rate7520: null,
  lifetimeExemptionAmount: null,
  gstExemptionAmount: null,
  annualGiftTaxExclusionAmount: null,
  federalEstateTaxPercent: null,
  federalLongTermCapitalGrainsTaxPercent: null,
  federalOrdinaryIncomeTaxPercentMaxBracket: null,
  availableStateEstateTaxes: null,
  localFederalEstateTaxPercentFormatted:
    localFederalEstateTaxPercentFormattedVar(),
} as const;

interface Props {
  at?: Date;
}

export function useIRSConstants(props?: Props) {
  // We want to use the start of the hour so that we can cache the results for
  // the entire hour.
  const atStartOfHour = startOfHour(props?.at ?? new Date());

  const { data, error } = useIrsConstantsQuery({
    variables: {
      at: atStartOfHour,
    },
    onCompleted: (data) => {
      if (data.irsConstants.federalEstateTaxPercent) {
        localFederalEstateTaxPercentFormattedVar(
          `${data.irsConstants.federalEstateTaxPercent.toString()}`
        );
      }
    },
  });

  useReportError('could not fetch irs constants', error);

  return data?.irsConstants ?? DEFAULT_IRS_CONSTANTS;
}

export function useFederalEstateTaxFormatted() {
  return useIRSConstants().localFederalEstateTaxPercentFormatted;
}
