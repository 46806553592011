import { Stack, Typography } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import {
  DisplayTable,
  DisplayTableColumn,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { useEventListener } from '@/hooks/useEventListener';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_SIZING, FONT_WEIGHTS } from '@/styles/tokens/fonts';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';
import { truncateToEllipis } from '@/utils/formatting/strings';

import { GiftProposalProjection } from '../../../giftProposal.types';
import { getTaxMetricsForProjection } from '../../../giftProposal.utils';
import { useProjectionsForProposal } from '../../../hooks/useProjectionsForProposal';
import { GiftDesignerModelScenariosFormShape } from '../GiftDesignerModelScenariosForm.types';
import { calculateTotalWealthForProjection } from '../GiftDesignerModelScenariosForm.utils';

interface GiftScenarioTableProps {
  proposalId: string;
  projections?: GiftProposalProjection[];
  setColumnWidths?: Dispatch<SetStateAction<number[]>>;
}

export function GiftScenarioTable({
  proposalId,
  projections: externalProjections,
  setColumnWidths,
}: GiftScenarioTableProps) {
  // Storing the ref in state allows us to immediately trigger the useEffect once it is set
  const [tableRef, setTableRef] = useState<HTMLTableElement | null>(null);

  const handleResize = useCallback(() => {
    if (tableRef) {
      const columns = tableRef.querySelectorAll('th');
      const columnWidths = Array.from(columns).map(
        (column) => column.offsetWidth
      );

      setColumnWidths?.(columnWidths);
    }
  }, [setColumnWidths, tableRef]);

  const debouncedHandleResize = useDebouncedFn(handleResize, 50, {
    leading: true,
    trailing: true,
  });

  useEventListener('resize', debouncedHandleResize);

  const { projections } = useProjectionsForProposal(
    proposalId,
    externalProjections
  );
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();
  const shouldShowAfterEstateTax = useWatch({
    control,
    name: 'showAfterEstateTax',
  });

  // Hide the row if all values are zero
  const shouldShowOutOfEstateFamily =
    projections?.some(
      ({ outOfEstateFamilyValue }) => !outOfEstateFamilyValue.isZero()
    ) ?? false;

  // Hide the row if all values are zero
  const shouldShowOutOfEstateCharitable =
    projections?.some(
      ({ outOfEstateCharitableValue }) => !outOfEstateCharitableValue.isZero()
    ) ?? false;

  const columns = useMemo<DisplayTableColumn[]>(() => {
    const scenarioColumns =
      projections?.map<DisplayTableColumn>((_p, idx) => ({
        headerName: `Scenario ${idx + 1}`,
        align: 'right',
      })) ?? [];

    if (scenarioColumns.length) {
      return [
        {
          headerName: '',
        },
        ...scenarioColumns,
      ];
    }

    return scenarioColumns;
  }, [projections]);

  useEffect(() => {
    // Initial resize and resize on column change
    handleResize();
  }, [handleResize, columns]);

  if (!columns.length) {
    return null;
  }

  return (
    <DisplayTable
      ref={(ref) => {
        setTableRef(ref);
      }}
      columns={columns}
    >
      <StyledTableRow>
        <StyledTableCell align="left">&nbsp;</StyledTableCell>
        {projections?.map((p, idx) => (
          <StyledTableCell align="right" key={`projection-${idx}`}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold', wordWrap: 'break-word' }}
            >
              {p.scenario?.isBaseline
                ? 'Baseline'
                : truncateToEllipis(p.scenario?.displayName, 24)}
            </Typography>
          </StyledTableCell>
        ))}
      </StyledTableRow>
      {!shouldShowAfterEstateTax && (
        <StyledTableRow>
          <StyledTableCell align="left">
            <Typography variant="body1">In estate (pre-tax)</Typography>
          </StyledTableCell>
          {projections?.map((p, idx) => (
            <StyledTableCell align="right" key={`projection-${idx}`}>
              <Typography
                variant="body1"
                data-testid={`GiftScenarioTable-inEstate-preTax-${idx}`}
              >
                {formatCurrencyNoDecimals(p.inEstateValueBeforeTax)}
              </Typography>
            </StyledTableCell>
          ))}
        </StyledTableRow>
      )}
      {shouldShowAfterEstateTax && (
        <StyledTableRow>
          <StyledTableCell align="left">
            <Typography variant="body1">In estate (after-tax)</Typography>
          </StyledTableCell>
          {projections?.map((p, idx) => {
            return (
              <StyledTableCell align="right" key={`projection-${idx}`}>
                <Typography
                  variant="body1"
                  data-testid={`GiftScenarioTable-inEstate-afterTax-${idx}`}
                >
                  {formatCurrencyNoDecimals(p.inEstateValueAfterTax)}
                </Typography>
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      )}
      {shouldShowAfterEstateTax && (
        <StyledTableRow>
          <StyledTableCell align="left">
            <Typography variant="body1">Gift & estate tax</Typography>
          </StyledTableCell>
          {projections?.map((p, idx) => {
            const { combinedGiftAndEstateTax } = getTaxMetricsForProjection(p);

            return (
              <StyledTableCell align="right" key={`projection-${idx}`}>
                <Typography
                  variant="body1"
                  sx={{
                    color: COLORS.MATH.NEGATIVE,
                  }}
                  data-testid={`GiftScenarioTable-giftAndEstateTax-${idx}`}
                >
                  {formatCurrencyNoDecimalsAccounting(combinedGiftAndEstateTax)}
                </Typography>
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      )}
      {!shouldShowAfterEstateTax && (
        <StyledTableRow>
          <StyledTableCell align="left">
            <Typography variant="body1">Gift tax</Typography>
          </StyledTableCell>
          {projections?.map((p, idx) => {
            return (
              <StyledTableCell align="right" key={`projection-${idx}`}>
                <Typography
                  variant="body1"
                  sx={{
                    color: COLORS.MATH.NEGATIVE,
                  }}
                  data-testid={`GiftScenarioTable-giftTax-${idx}`}
                >
                  {formatCurrency(p.giftTax, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    currencySign: 'accounting',
                  })}
                </Typography>
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      )}
      {shouldShowOutOfEstateFamily && (
        <StyledTableRow>
          <StyledTableCell align="left">
            <Typography variant="body1">Out of estate (family)</Typography>
          </StyledTableCell>
          {projections?.map((p, idx) => {
            return (
              <StyledTableCell align="right" key={`projection-${idx}`}>
                <Typography variant="body1">
                  {formatCurrencyNoDecimals(p.outOfEstateFamilyValue)}
                </Typography>
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      )}
      {shouldShowOutOfEstateCharitable && (
        <StyledTableRow>
          <StyledTableCell align="left">
            <Typography variant="body1">Out of estate (charity)</Typography>
          </StyledTableCell>
          {projections?.map((p, idx) => {
            return (
              <StyledTableCell align="right" key={`projection-${idx}`}>
                <Typography
                  variant="body1"
                  data-testid={`GiftScenarioTable-outOfEstateFamily-${idx}`}
                >
                  {formatCurrencyNoDecimals(p.outOfEstateCharitableValue)}
                </Typography>
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      )}

      <StyledTableRow
        sx={{
          borderTop: `solid ${COLORS.NAVY[600]} 2px`,
        }}
      >
        <StyledTableCell align="left">
          <Stack>
            <Typography
              variant="body1"
              sx={{
                fontWeight: FONT_WEIGHTS.bold,
                fontSize: FONT_SIZING.md.fontSize,
                lineHeight: FONT_SIZING.md.lineHeight,
              }}
            >
              Total wealth
            </Typography>
            <Typography variant="subtitle2">
              Combined in & out of estate
            </Typography>
          </Stack>
        </StyledTableCell>
        {projections?.map((p, idx) => {
          return (
            <StyledTableCell align="right" key={`projection-${idx}`}>
              <Typography
                variant="h5"
                data-testid={`GiftScenarioTable-totalWeatlh-${idx}`}
              >
                {formatCurrencyNoDecimals(
                  calculateTotalWealthForProjection({
                    projection: p,
                    shouldShowAfterEstateTax,
                  })
                )}
              </Typography>
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    </DisplayTable>
  );
}
