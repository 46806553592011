import { cx } from '@emotion/css';
import { Box, Stack, Typography } from '@mui/material';
import React, { useId } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';

import { InputLabel } from './InputLabel';
import {
  BaseInputProps,
  FormControlLabelPosition,
  GetFormControlPropsFromBaseInputProps,
  HelpTextVariant,
} from './inputTypes';

interface FormControlProps<
  // we omit 'value' here becuase some input types have multiple values,
  // and because this component doesn't need to know about the value
  SpecificBaseInputProps extends Omit<BaseInputProps, 'value'>,
> {
  id?: string;
  label?: React.ReactNode;
  /**
   * the presence of contextualHelp indicates that there's help text related to this input, which
   * will cause the help icon to show up next to the input label
   */
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  required?: boolean;
  /**
   * hideRequirementIndicator is used for checkboxes and toggles, where there's no need to show an
   * asterisk or "optional"  tag because the default value of the input is already valid
   */
  hideRequirementIndicator?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is really hard to type correctly.
  component: React.ComponentType<any>;
  componentKind?: 'select' | 'multiSelect';
  labelPosition?: FormControlLabelPosition;
  inputProps: GetFormControlPropsFromBaseInputProps<SpecificBaseInputProps>;
  /**
   * if the underlying input can't be referenced with htmlFor because it's not a valid input
   * element (like a buttongroup), you can use this property to correctly associate the label
   * with the input using aria-labelledby
   */
  associateLabelWithAria?: boolean;
  hideLabel?: boolean;
  labelIconEnd?: React.ReactNode;
}

function getHelpTextColorForVariant(helpTextVariant: HelpTextVariant) {
  if (helpTextVariant === 'warning') {
    return COLORS.FUNCTIONAL.WARNING[600];
  }

  if (helpTextVariant === 'error') {
    return COLORS.FUNCTIONAL.ERROR.DEFAULT;
  }

  return undefined;
}

const useStyles = makeStyles<{
  helpTextVariant: HelpTextVariant;
}>()((_theme, { helpTextVariant }) => ({
  helpText: {
    color: getHelpTextColorForVariant(helpTextVariant),
  },
}));

export function FormControl<SpecificBaseInputProps extends BaseInputProps>({
  id,
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant = 'default',
  required,
  hideRequirementIndicator,
  labelPosition = 'top',
  component: InputComponent,
  componentKind,
  associateLabelWithAria,
  inputProps,
  hideLabel = false,
  labelIconEnd,
}: FormControlProps<SpecificBaseInputProps>) {
  const stableInputId = useId();
  const inputId = `formControl_${id || stableInputId}`;
  const describedById = `formControlDescribedBy_${useId()}`;
  const labelId = `formControlLabel_${useId()}`;

  const { classes } = useStyles({
    helpTextVariant,
  });

  // we only want there to be an `aria-describedby` prop or `aria-labelledby` prop
  // if there's actually a descriptive element
  const describedByProp =
    errorMessage || helpText ? { 'aria-describedby': describedById } : {};
  const labelledByProp = associateLabelWithAria
    ? { 'aria-labelledby': labelId }
    : {};

  const inputComponent = (
    <InputComponent
      {...inputProps}
      sx={{
        mt: hideLabel && labelPosition === 'top' ? '0 !important' : undefined, // Note that if we are hiding the label, we can override the 4px spacing between the label and the input
        ...(inputProps.sx || {}),
      }}
      {...describedByProp}
      {...labelledByProp}
      labelId={
        componentKind === 'select' || componentKind === 'multiSelect'
          ? labelId
          : undefined
      }
      id={inputId}
      error={!!errorMessage}
    />
  );

  const labelProp = associateLabelWithAria ? {} : { htmlFor: inputId };
  const inputLabel = label && (
    <InputLabel
      id={labelId}
      required={required}
      hideRequirementIndicator={hideRequirementIndicator}
      {...labelProp}
      contextualHelp={contextualHelp}
      hidden={hideLabel}
      labelIconEnd={labelIconEnd}
    >
      {label}
    </InputLabel>
  );

  const labelAndInputTop = labelPosition === 'top' && (
    <>
      {inputLabel}
      {inputComponent}
    </>
  );

  const labelAndInputRight = labelPosition === 'right' && (
    <Box display="flex">
      {inputComponent}
      {inputLabel && (
        <Box
          display="inline-block"
          ml={1.5}
          flexGrow={1}
          sx={{
            alignSelf: 'center',
          }}
        >
          {inputLabel}
          <Typography
            id={describedById}
            variant="subtitle2"
            className={cx(classes.helpText)}
          >
            {helpText}
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Stack spacing={0.5}>
      {labelAndInputTop}
      {labelAndInputRight}
      {errorMessage && (
        <Typography
          id={describedById}
          variant="subtitle2"
          color={COLORS.FUNCTIONAL.ERROR.DEFAULT}
        >
          {errorMessage}
        </Typography>
      )}
      {/* We only want to show helpText if there's not error text. We can't support showing both. */}
      {!errorMessage && helpText && !labelAndInputRight && (
        <Typography
          id={describedById}
          variant="subtitle2"
          className={cx(classes.helpText)}
        >
          {helpText}
        </Typography>
      )}
    </Stack>
  );
}
