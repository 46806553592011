import { BaseSyntheticEvent } from 'react';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { useFormContext } from '@/components/react-hook-form';

import { GiftDesignerModelScenariosFormShape } from '../GiftDesignerModelScenariosForm.types';

interface MakeProjectionButtonProps extends Partial<ButtonProps> {
  onSubmitSaveModelScenarios: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>;
}

export function MakeProjectionButton({
  onSubmitSaveModelScenarios,
  ...buttonProps
}: MakeProjectionButtonProps) {
  const { formState } = useFormContext<GiftDesignerModelScenariosFormShape>();

  return (
    <Button
      variant="primary"
      size="sm"
      loading={formState.isSubmitting}
      onClick={onSubmitSaveModelScenarios}
      {...buttonProps}
    >
      Update illustration
    </Button>
  );
}
