import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';

import { BaselineIncomeAndExpensesFormShape } from '../GiftDesignerBasicInformationForm.types';
import { BaselineIncomeAndExpensesModal } from './BaselineIncomeAndExpensesModal/BaselineIncomeAndExpensesModal';

interface AddNewIncomeOrExpenseButtonProps {
  onSubmitSuccess: (formData: BaselineIncomeAndExpensesFormShape) => void;
}

export function AddNewIncomeOrExpenseButton({
  onSubmitSuccess,
}: AddNewIncomeOrExpenseButtonProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  return (
    <>
      <BaselineIncomeAndExpensesModal
        incomeData={null}
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
        }}
        onSubmitSuccess={(formData) => {
          onSubmitSuccess(formData);
          closeModal();
        }}
      />

      <Button
        startIcon={PlusIcon}
        variant="secondary"
        size="md"
        onClick={() => {
          openModal();
        }}
      >
        Add new cash flow
      </Button>
    </>
  );
}
