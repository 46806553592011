import { Box, CircularProgress } from '@mui/material';
import { ComponentProps } from 'react';

interface LoaderProps {
  boxProps?: Partial<ComponentProps<typeof Box>>;
  circularProgressProps?: Partial<ComponentProps<typeof CircularProgress>>;
}

export function Loader({
  boxProps = {},
  circularProgressProps = {},
}: LoaderProps) {
  return (
    <Box {...boxProps}>
      <CircularProgress size={100} {...circularProgressProps} />
    </Box>
  );
}
