import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { GridAlignment, GridValidRowModel } from '@mui/x-data-grid-pro';
import React from 'react';

import { ROW_BORDER_STYLE } from '@/components/tables/constants';
import { COLORS } from '@/styles/tokens/colors';

import { Column } from '../types';

export const cellPaddingY = '20px';
export const cellPaddingX = '16px';

const SHARED_CELL_STYLE: React.CSSProperties = {
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'inherit',
  textDecoration: 'inherit',
  borderTop: ROW_BORDER_STYLE,
};

const alignToJustifyContent: Record<
  GridAlignment,
  React.CSSProperties['justifyContent']
> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const alignToTextAlign: Record<
  GridAlignment,
  React.CSSProperties['textAlign']
> = {
  left: 'inherit',
  right: 'right',
  center: 'center',
};

export type CommonCellProps = Pick<Column<GridValidRowModel>, 'align'>;

const getDynamicCSSProps = (
  align: CommonCellProps['align']
): React.CSSProperties => {
  return {
    justifyContent: align
      ? alignToJustifyContent[align]
      : alignToJustifyContent.left,
    textAlign: align ? alignToTextAlign[align] : alignToTextAlign.left,
  };
};

export interface CellContainerProps extends CommonCellProps {
  sx?: BoxProps['sx'];
}

/**
 * This is the standard, white background cell container.
 */
export const CellContainer = styled(Box)<CellContainerProps>(({
  align,
  sx,
}) => {
  return {
    ...SHARED_CELL_STYLE,
    ...getDynamicCSSProps(align),
    padding: `${cellPaddingY} ${cellPaddingX}`,
    sx,
  };
});

export type PrimaryCellTypographyProps = TypographyProps;
export const PrimaryCellTypography = ({
  children,
  ...otherProps
}: TypographyProps) => (
  <Typography
    noWrap
    variant="subtitle1"
    component="span"
    color={COLORS.GRAY[900]}
    {...otherProps}
  >
    {children}
  </Typography>
);

export type SecondaryCellTypographyProps = TypographyProps;
export const SecondaryCellTypography = ({
  children,
  ...otherProps
}: TypographyProps) => (
  <Typography
    noWrap
    variant="subtitle2"
    component="span"
    color={COLORS.GRAY[500]}
    {...otherProps}
  >
    {children}
  </Typography>
);
