import { Context, useContext } from 'react';

export function useGuardedContext<C>(
  context: Context<C>,
  providerName: string
) {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error(
      `Context unavailable, did you forget to nest under <${providerName} />?`
    );
  }
  return ctx;
}
