import { PaletteColor, Theme, useTheme } from '@mui/material';
import { CSSProperties } from 'react';

import { COLORS } from '@/styles/tokens/colors';

export const CHART_SEMANTIC_COLOR_VALUE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  NEGATIVE: 'NEGATIVE',
} as const;
export type ChartSemanticColorValue =
  (typeof CHART_SEMANTIC_COLOR_VALUE)[keyof typeof CHART_SEMANTIC_COLOR_VALUE];

export function useColorChoices(): Record<
  ChartSemanticColorValue,
  PaletteColor
> {
  const theme = useTheme();
  return {
    PRIMARY: theme.palette.dataVisualizationPrimary,
    SECONDARY: theme.palette.dataVisualizationSecondary,
    TERTIARY: theme.palette.dataVisualizationTertiary,
    NEGATIVE: theme.palette.dataVisualizationNegative,
  };
}
export type ChartColorDefinitions = Record<
  ChartSemanticColorValue,
  {
    text: string;
    radialGradient: [string, string];
    backgroundColor: string;
    contrastText: string;
  }
>;

export const GREY_CANDY_CANE: CSSProperties['background'] = `repeating-linear-gradient(
  -45deg,
  ${COLORS.GRAY[200]} 0px,
  ${COLORS.GRAY[200]} 4px,
  ${COLORS.GRAY[300]} 4px,
  ${COLORS.GRAY[300]} 8px
);`;

export const THEMED_CANDY_CANE = (theme: Theme) => `repeating-linear-gradient(
  -45deg,
  ${theme.palette.dataVisualizationSecondary.lighter},
  ${theme.palette.dataVisualizationSecondary.lighter}, 4px,
  ${theme.palette.dataVisualizationPrimary.lighter} 4px,
  ${theme.palette.dataVisualizationPrimary.lighter} 8px
);`;

export function useChartColorDefinitions(): ChartColorDefinitions {
  const theme = useTheme();
  const {
    dataVisualizationPrimary,
    dataVisualizationSecondary,
    dataVisualizationTertiary,
    dataVisualizationNegative,
  } = theme.palette;
  return {
    [CHART_SEMANTIC_COLOR_VALUE.PRIMARY]: {
      text: dataVisualizationPrimary.dark,
      radialGradient: [
        dataVisualizationPrimary.light,
        dataVisualizationPrimary.main,
      ],
      contrastText: dataVisualizationPrimary.contrastText,
      backgroundColor: dataVisualizationPrimary.lighter,
    },
    [CHART_SEMANTIC_COLOR_VALUE.SECONDARY]: {
      text: dataVisualizationSecondary.dark,
      radialGradient: [
        dataVisualizationSecondary.light,
        dataVisualizationSecondary.main,
      ],
      contrastText:
        theme.typography.h6.color ?? dataVisualizationSecondary.contrastText,
      backgroundColor: dataVisualizationSecondary.lighter,
    },
    [CHART_SEMANTIC_COLOR_VALUE.TERTIARY]: {
      text: dataVisualizationTertiary.dark,
      radialGradient: [
        dataVisualizationTertiary.light,
        dataVisualizationTertiary.main,
      ],
      contrastText: dataVisualizationSecondary.contrastText,
      backgroundColor: dataVisualizationTertiary.lighter,
    },
    [CHART_SEMANTIC_COLOR_VALUE.NEGATIVE]: {
      text: dataVisualizationNegative.dark,
      radialGradient: [
        dataVisualizationNegative.light,
        dataVisualizationNegative.main,
      ],
      contrastText: dataVisualizationNegative.contrastText,
      backgroundColor: dataVisualizationNegative.main,
    },
  };
}
