import { Box, styled, SxProps, Theme, useTheme } from '@mui/material';
import React, { ComponentType, useEffect, useMemo } from 'react';

import { IconProps } from '@/components/icons/ChevronDownIcon';
import { Loader } from '@/components/progress/Loader';
import { Slot } from '@/components/utils/slots';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { diagnostics } from '@/utils/diagnostics';
import { formPost } from '@/utils/formUtils';

import { ButtonRoot } from './ButtonRoot';
import { ChevronWithOutline } from './HeaderWithPopover';
import { getCSSPropertiesByVariant, iconSizeByButtonSize } from './styles';
import { CommonButtonProps } from './types';

export type PassableIconProps = Omit<IconProps, 'height' | 'width'>;

export type ButtonProps = React.PropsWithChildren<
  {
    sx?: SxProps<Theme>;
    contentBoxSx?: SxProps<Theme>;
    startIcon?: ComponentType<IconProps>;
    endIcon?: ComponentType<IconProps>;
    iconProps?: PassableIconProps;
    contextualHelp?: JSX.Element;
    component?: React.ElementType;
    method?: 'post';
    slots?: {
      EndIcon?: Slot<typeof ChevronWithOutline | typeof Loader>;
    };
  } & CommonButtonProps
>;

const ButtonStartIcon = styled('span', {
  name: 'ActionButton',
  slot: 'StartIcon',
})(() => ({
  display: 'inherit',
  marginRight: 8,
  marginLeft: -4, // to give the appearance of being "centered"
}));

const ButtonEndIcon = styled('span', {
  name: 'ActionButton',
  slot: 'EndIcon',
})(() => ({
  display: 'inherit',
  marginLeft: 8,
  marginRight: -4,
}));

function ButtonWithoutRef(
  {
    children,
    startIcon: StartIconProp,
    endIcon: EndIconProp,
    contextualHelp: contextualHelpProp,
    loading,
    disabled,
    contentBoxSx,
    method,
    slots,
    ...props
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const iconSizePx = iconSizeByButtonSize[props.size];
  const iconProps = useMemo(() => ({ size: iconSizePx }), [iconSizePx]);
  const theme = useTheme();

  const startIcon = StartIconProp && (
    <ButtonStartIcon>
      <StartIconProp {...iconProps} />
    </ButtonStartIcon>
  );

  const endIcon = useMemo(() => {
    // Use the EndIcon slot if it's provided
    if (slots?.EndIcon) {
      return (
        <ButtonEndIcon>
          <slots.EndIcon.component {...slots.EndIcon.props} />
        </ButtonEndIcon>
      );
    }

    // Otherwise, use the EndIcon prop
    return (
      EndIconProp && (
        <ButtonEndIcon>
          <EndIconProp {...iconProps} />
        </ButtonEndIcon>
      )
    );
  }, [EndIconProp, iconProps, slots]);

  const computedDisabled = disabled || !!loading;

  const variantStyle = getCSSPropertiesByVariant(
    props.variant,
    computedDisabled,
    theme
  );

  useEffect(() => {
    if (
      !props.external &&
      typeof props.href === 'string' &&
      props.href.includes('api/')
    ) {
      diagnostics.warn(
        'Links to the API should use the external prop to bypass client routing.'
      );
    }
  }, [props.external, props.href]);

  const postProps = useMemo(() => {
    if (method === 'post') {
      return {
        onClick: () => {
          formPost(props.href as string, {});
        },
        href: undefined, // Remove the href to prevent the button from being a link
      };
    }

    return {};
  }, [method, props.href]);

  const buttonContent = (
    <ButtonRoot {...props} {...postProps} ref={ref} disabled={computedDisabled}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          /**
           * We want to bypass any propogation events from this div up to the button root,
           * otherwise forms can receive the undefined values when captured by this button.
           */
          pointerEvents: 'none',
          /**
           * We set visibility here rather than doing conditional renders on loading,
           * because we don't want the background of the button shrinking / expanding as the content is
           * replace with the absolutely positioned loading spinner.
           * The visibility prop preserve the browser's computed layout from the button content between loading states.
           */
          visibility: loading ? 'hidden' : undefined,
          ...contentBoxSx,
        }}
      >
        {startIcon}
        {children}
        <Box flexGrow={1} />
        {endIcon}
      </Box>
      {loading && (
        <Loader
          boxProps={{
            sx: {
              display: 'flex',
              position: 'absolute',
            },
          }}
          circularProgressProps={{
            size: 28,
            sx: {
              color: variantStyle.color,
            },
          }}
        />
      )}
    </ButtonRoot>
  );

  if (contextualHelpProp) {
    return (
      <ContextualHelpTooltip wrappedComponent={buttonContent}>
        {contextualHelpProp}
      </ContextualHelpTooltip>
    );
  }

  return buttonContent;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ButtonWithoutRef
);
