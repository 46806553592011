// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubBrandFragment = { __typename?: 'SubBrand', id: string, displayName: string };

export type Branding_Branded_SubBrand_Fragment = { __typename?: 'SubBrand', id: string, displayName: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null, lightWideLogoURL: string };

export type Branding_Branded_TenantBranding_Fragment = { __typename?: 'TenantBranding', id: string, displayName: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null, lightWideLogoURL: string };

export type Branding_BrandedFragment = Branding_Branded_SubBrand_Fragment | Branding_Branded_TenantBranding_Fragment;

export type UpdateTenantBrandingMutationVariables = Types.Exact<{
  input: Types.UpdateTenantBrandingInput;
  updateTenantBrandingId: Types.Scalars['ID']['input'];
}>;


export type UpdateTenantBrandingMutation = { __typename?: 'Mutation', updateTenantBranding: { __typename?: 'TenantBranding', id: string } };

export const SubBrandFragmentDoc = gql`
    fragment SubBrand on SubBrand {
  id
  displayName
}
    `;
export const Branding_BrandedFragmentDoc = gql`
    fragment Branding_Branded on Branded {
  id
  displayName
  primaryColor
  secondaryColor
  dataVisualizationPrimaryColor
  dataVisualizationSecondaryColor
  dataVisualizationTertiaryColor
  dataVisualizationNegativeColor
  buttonsLinkColor
  lightWideLogoURL: logoURL
  darkSquareLogoURL
  darkWideLogoURL
}
    `;
export const UpdateTenantBrandingDocument = gql`
    mutation UpdateTenantBranding($input: UpdateTenantBrandingInput!, $updateTenantBrandingId: ID!) {
  updateTenantBranding(input: $input, id: $updateTenantBrandingId) {
    id
  }
}
    `;
export type UpdateTenantBrandingMutationFn = Apollo.MutationFunction<UpdateTenantBrandingMutation, UpdateTenantBrandingMutationVariables>;

/**
 * __useUpdateTenantBrandingMutation__
 *
 * To run a mutation, you first call `useUpdateTenantBrandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantBrandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantBrandingMutation, { data, loading, error }] = useUpdateTenantBrandingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      updateTenantBrandingId: // value for 'updateTenantBrandingId'
 *   },
 * });
 */
export function useUpdateTenantBrandingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantBrandingMutation, UpdateTenantBrandingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantBrandingMutation, UpdateTenantBrandingMutationVariables>(UpdateTenantBrandingDocument, options);
      }
export type UpdateTenantBrandingMutationHookResult = ReturnType<typeof useUpdateTenantBrandingMutation>;
export type UpdateTenantBrandingMutationResult = Apollo.MutationResult<UpdateTenantBrandingMutation>;
export type UpdateTenantBrandingMutationOptions = Apollo.BaseMutationOptions<UpdateTenantBrandingMutation, UpdateTenantBrandingMutationVariables>;