import Decimal from 'decimal.js';
import { UseFieldArrayAppend } from 'react-hook-form';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';

import { useGiftDesignerModelScenariosContext } from '../contexts/GiftDesignerModelScenarios.context';
import { DEFAULT_BASIC_INFORMATION_FORM_VALUES } from '../GiftDesignerBasicInformationForm.utils';
import { GiftDesignerModelScenariosFormShape } from '../GiftDesignerModelScenariosForm.types';
import { giftingProposalCashFlowFragmentToScenarioCashFlow } from '../GiftDesignerModelScenariosForm.utils';
import { MAX_GIFT_SCENARIOS_COUNT } from './GiftScenarios';

interface AddGiftScenarioProps extends Partial<ButtonProps> {
  appendGiftScenario: UseFieldArrayAppend<
    GiftDesignerModelScenariosFormShape,
    'scenarios'
  >;
  giftScenarioCount: number;
}

export function AddGiftScenarioButton({
  appendGiftScenario,
  giftScenarioCount,
  ...buttonProps
}: AddGiftScenarioProps) {
  const { proposal } = useGiftDesignerModelScenariosContext();
  const baselineCashFlows = proposal?.baseCashFlows ?? [];
  const lengthOfAnalysis = new Decimal(
    proposal?.lengthOfAnalysis ??
      DEFAULT_BASIC_INFORMATION_FORM_VALUES.lengthOfAnalysis
  );

  // Make sure to add the baseline cash flows to the new scenario
  const scenarioCashFlows = baselineCashFlows.map((cf) =>
    giftingProposalCashFlowFragmentToScenarioCashFlow(
      cf,
      true,
      lengthOfAnalysis
    )
  );

  return (
    <Button
      variant="primary"
      size="sm"
      disabled={
        giftScenarioCount >= MAX_GIFT_SCENARIOS_COUNT || buttonProps.disabled
      }
      startIcon={PlusIcon}
      onClick={() => {
        appendGiftScenario({
          _id: '', // This will be replaced by RHF
          name: '',
          scenarioCashFlows,
        });
      }}
      {...buttonProps}
    >
      Add scenario
    </Button>
  );
}
