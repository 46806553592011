import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useContext, useEffect, useMemo } from 'react';

import { ModalContext } from '@/components/modals/ModalContext';
import { Popper } from '@/components/poppers/Popper/Popper';
import { makeUnprintableStyle } from '@/utils/makeUnprintableStyle';

import { HelpIconButton } from './HelpIconButton';

// playing around, 50ms felt like enough time to allow the user to get their mouse over the
// tooltip content to keep it open, even moving slowly, while still making it feel like
// the tooltip closed quickly.
const TOOLTIP_CLOSE_DELAY_MS = 50;

type Props = React.PropsWithChildren<{
  sx?: SxProps<Theme>;
  wrappedComponent?: JSX.Element;
  // this is a prop that allows a developer to force the tooltip to stay open for debugging
  // and development
  _devOnlyOpen?: boolean;
  center?: boolean;
}>;

export function ContextualHelpTooltip({
  sx,
  children,
  wrappedComponent,
  _devOnlyOpen,
  center,
}: Props) {
  const anchorEl = React.useRef(null);
  const timerRef = React.useRef<number | null>(null);
  const [isMouseInTargetArea, setIsMouseInTargetArea] = React.useState(false);
  const [isContextualHelpOpen, setIsContextualHelpOpen] = React.useState(false);

  const { modalState } = useContext(ModalContext);
  const anyOpen = useMemo(() => {
    return Object.values(modalState).some((modal) => modal === true);
  }, [modalState]);

  useEffect(() => {
    // if any dialog is open, we want to close the tooltip
    if (anyOpen) {
      setIsContextualHelpOpen(false);
    }
  }, [anyOpen, setIsContextualHelpOpen]);

  // because there are sometimes links and things in contexutal help tooltips, we want to
  // add a delay before hiding the tooltip to allow the user to get to the link and click it
  React.useEffect(() => {
    if (isMouseInTargetArea) {
      setIsContextualHelpOpen(true);
    } else {
      timerRef.current = window.setTimeout(() => {
        if (isMouseInTargetArea) return;
        setIsContextualHelpOpen(false);
        timerRef.current = null;
      }, TOOLTIP_CLOSE_DELAY_MS);
    }
    return () => {
      timerRef.current !== null && clearTimeout(timerRef.current);
    };
  }, [isMouseInTargetArea]);

  // If we provide a width to the tooltip, we want to use that width instead of the width
  // of the wrapped component. This is useful when setting widths as a percentage of the
  // container element.
  const shouldAssumeWidthOfWrappedComponent = !(sx && 'width' in sx);

  return (
    <Box
      sx={makeUnprintableStyle({
        display: 'flex',
        alignItems: 'center',
        ...sx,
      })}
    >
      {wrappedComponent ? (
        <div
          onMouseEnter={() => setIsMouseInTargetArea(true)}
          onMouseLeave={() => setIsMouseInTargetArea(false)}
          ref={anchorEl}
          style={{
            width: shouldAssumeWidthOfWrappedComponent ? 'auto' : '100%',
          }}
        >
          {wrappedComponent}
        </div>
      ) : (
        <HelpIconButton
          ref={anchorEl}
          buttonProps={{
            onMouseEnter: () => setIsMouseInTargetArea(true),
            onMouseLeave: () => setIsMouseInTargetArea(false),
          }}
        />
      )}
      {children && (
        <Popper
          anchorRef={anchorEl}
          onMouseEnter={() => setIsMouseInTargetArea(true)}
          onMouseLeave={() => setIsMouseInTargetArea(false)}
          open={isContextualHelpOpen || _devOnlyOpen}
          center={center}
        >
          {children}
        </Popper>
      )}
    </Box>
  );
}
