import { useMemo } from 'react';
import { Path, useWatch } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useFormContext } from '@/components/react-hook-form';
import { GiftingProposalPortfolioPortfolioType } from '@/types/schema';

import { OutOfEstatePortfolioFormShape } from '../../GiftDesignerBasicInformationForm.types';
import { portfolioTypeOptions } from './OutOfEstatePortfolioFormModal.utils';

type OutOfEstatePortfolioFormField = Path<OutOfEstatePortfolioFormShape>;

function Name() {
  const { control } = useFormContext<OutOfEstatePortfolioFormShape>();

  return (
    <FormAwareTextInput<OutOfEstatePortfolioFormShape>
      control={control}
      fieldName={'name' as const satisfies OutOfEstatePortfolioFormField}
      label="Name"
      required
    />
  );
}

function Amount() {
  const { control } = useFormContext<OutOfEstatePortfolioFormShape>();

  return (
    <FormAwareCurrencyInput<OutOfEstatePortfolioFormShape>
      control={control}
      fieldName={'amount' as const satisfies OutOfEstatePortfolioFormField}
      label="Amount"
      isDecimalJSInput
      required
    />
  );
}

function PortfolioType() {
  const { control } = useFormContext<OutOfEstatePortfolioFormShape>();

  const selectedPortfolioType = useWatch({
    control,
    name: 'type',
  });

  const helpText = useMemo(() => {
    const porfolioTypeName = portfolioTypeOptions.find(
      (option) => option.value === selectedPortfolioType
    )?.display;

    switch (selectedPortfolioType) {
      case GiftingProposalPortfolioPortfolioType.Charitable:
        return `${porfolioTypeName}: The portfolio will grow by the full assumed pre-tax return`;
      case GiftingProposalPortfolioPortfolioType.GrantorTrust:
        return `${porfolioTypeName}: The portfolio will grow by the full assumed pre-tax return, and any tax generated from investment income will be deducted from the client's estate each year`;
      case GiftingProposalPortfolioPortfolioType.NonGrantorIndividual:
        return `${porfolioTypeName}: The portfolio will grow by the assumed after-tax return, as the portfolio will use investment returns to pay any tax generated from investment income`;
      case GiftingProposalPortfolioPortfolioType.NonGrantorTrust:
        return `${porfolioTypeName}: The portfolio will grow by the assumed after-tax return, as the portfolio will use investment returns to pay any tax generated from investment income`;
      default:
        return '';
    }
  }, [selectedPortfolioType]);

  return (
    <FormAwareSelectInput<OutOfEstatePortfolioFormShape>
      control={control}
      fieldName={'type' as const satisfies OutOfEstatePortfolioFormField}
      label="Portfolio type"
      options={portfolioTypeOptions}
      required
      helpText={helpText}
      testId="OutOfEstatePortfolioFormModal-PortfolioType"
    />
  );
}

export const OutOfEstatePortfolioFormFields = {
  Name,
  Amount,
  PortfolioType,
};
