import Decimal from 'decimal.js';
import { useMemo } from 'react';

import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

interface GiftTaxMiniTableProps {
  taxableGiftsMade: Decimal;
  lifetimeExemptionAppliedTowardGiftTax: Decimal;
  giftTax: Decimal;
}

export function GiftTaxMiniTable({
  taxableGiftsMade,
  lifetimeExemptionAppliedTowardGiftTax,
  giftTax,
}: GiftTaxMiniTableProps) {
  const rate = useFederalEstateTaxFormatted();

  // Compute the gift tax subtotal by subtracting the lifetime exemption applied toward gift tax from the taxable gifts made
  const giftTaxSubtotal = taxableGiftsMade.minus(
    lifetimeExemptionAppliedTowardGiftTax
  );

  const giftTaxRows: MiniTableRow[] = useMemo(() => {
    return [
      {
        label: 'Gift tax',
        labelProps: { variant: 'h6' },
        value: '',
      },
      {
        label: 'Taxable gifts made',
        value: formatCurrencyNoDecimals(taxableGiftsMade),
      },
      {
        label: 'Lifetime exemption used',
        value: formatCurrencyNoDecimalsAccounting(
          lifetimeExemptionAppliedTowardGiftTax.abs().negated()
        ),
      },
      {
        label: 'Amount subject to tax',
        value: formatCurrencyNoDecimals(giftTaxSubtotal),
      },
      {
        label: 'Gift tax due',
        value: formatCurrencyNoDecimalsAccounting(giftTax.abs().negated()),
        labelTooltip: `Assumes taxable gifts in excess of the remaining exemption are taxed at a ${rate}% federal estate tax rate.`,
        valueProps: { color: COLORS.MATH.NEGATIVE },
      },
    ];
  }, [
    giftTax,
    giftTaxSubtotal,
    lifetimeExemptionAppliedTowardGiftTax,
    rate,
    taxableGiftsMade,
  ]);

  return <MiniTable variant="tally" rows={giftTaxRows} />;
}
