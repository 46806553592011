import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';
/**
 * @description Helper hook to clean up / cancel any handlers as dependency change, component is unmounted, etc
 */
export const useDebouncedFn: typeof debounce = (fn, wait, options) => {
  const debouncedFn = useMemo(() => {
    return debounce(fn, wait, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, wait, JSON.stringify(options)]);

  const prevDebouncedFn = usePrevious(debouncedFn);

  /**
   * @description Cancel the previously debounced function when a new one is created, to prevent unintended execution of stale fns.
   */
  useEffect(() => {
    prevDebouncedFn?.cancel();
  }, [prevDebouncedFn, debouncedFn]);

  /**
   * @description Cancel the debounce on unmount
   */
  useEffect(() => {
    return () => {
      prevDebouncedFn?.cancel();
      debouncedFn.cancel();
    };
  }, [debouncedFn, prevDebouncedFn]);

  return debouncedFn;
};
