import { makeVar } from '@apollo/client';

import { User } from '@/types/schema';

export const mostRecentlyViewedWaterfallVar =
  makeVar<User['localMostRecentlyViewedWaterfall']>(null);

export const mostRecentlyViewedEntityMapVar =
  makeVar<User['localMostRecentlyViewedEntityMap']>(null);

export const mostRecentlyViewedHouseholdsVar = makeVar<
  { id: string; displayName: string }[]
>([]);
