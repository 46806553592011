import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormStateReturn,
} from 'react-hook-form';

import { UserPlus01Icon } from '@/components/icons/UserPlus01Icon';
import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { useSelectInputContext } from '@/modules/entities/contexts/selectInput/selectInput.context';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import { HelpTextVariant, SelectInputOption } from '../baseInputs/inputTypes';
import { SelectInput } from '../baseInputs/SelectInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';
import { useResponsiveSelectInput } from './hooks/useResponsiveSelectInput';

interface AddNewOptionDefinition {
  onClick: () => void;
  icon?: React.ReactNode;
  text?: string;
}

export type InputOption =
  | SelectInputOption
  | { type: 'component'; component: JSX.Element };

export interface FormAwareSelectInputProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  hideLabel?: boolean;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  options: InputOption[];
  addNewOption?: AddNewOptionDefinition;
  required?: boolean;
  control: Control<FormShape>;
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  contextualHelp?: JSX.Element;
  startAdornment?: React.ReactNode;
  testId?: string;
  id?: string;
  disableAutomaticNewOptionSelection?: boolean;
  labelIconEnd?: React.ReactNode;
  emptyValueDisplay?: string;
  showEmptyValue?: boolean;
}

export type ResponsiveSelectInputProps<FormShape extends FieldValues> = {
  field: ControllerRenderProps<FormShape, Path<FormShape>>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FormShape>;
} & FormAwareSelectInputProps<FormShape>;

function ResponsiveSelectInput<FormShape extends FieldValues>({
  field,
  fieldState,
  formState,
  ...selectInputProps
}: ResponsiveSelectInputProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const {
    removeSelectFromStack,
    pushActiveSelectInput,
    peekActiveSelectInput,
    popActiveSelectInput,
  } = useSelectInputContext();

  // Control the open state of the select input
  const [isOpen, setisOpen] = useState(false);

  const {
    label,
    helpText,
    helpTextVariant,
    options,
    addNewOption,
    hideLabel,
    required,
    disabled,
    contextualHelp,
    startAdornment,
    testId,
    id,
    fieldName,
    labelIconEnd,
    emptyValueDisplay,
    showEmptyValue,
  } = selectInputProps;

  useEffect(() => {
    return () => {
      // When the component unmounts, we want to remove it from the activeSelectStack.
      removeSelectFromStack(fieldName as string);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    const activeSelectInput = peekActiveSelectInput();

    if (activeSelectInput === fieldName) {
      // If we are closing the active select input, we want to pop it off the stack.
      popActiveSelectInput();
    }

    setisOpen(false);
  };

  const handleOpen = () => {
    pushActiveSelectInput(fieldName as string);
    setisOpen(true);
  };

  useResponsiveSelectInput<FormShape>({
    field,
    fieldState,
    formState,
    handleClose,
    isOpen,
    ...selectInputProps,
  });

  const finalOptions = useMemo(() => {
    if (!addNewOption) return options;
    return [
      {
        type: 'component' as const,
        component: (
          <MenuItem onClick={() => addNewOption.onClick()}>
            <ListItemIcon>
              {addNewOption.icon || <UserPlus01Icon size={16} />}
            </ListItemIcon>
            <ListItemText>{addNewOption.text || 'Add new'}</ListItemText>
          </MenuItem>
        ),
      },
      ...options,
    ];
  }, [addNewOption, options]);

  return (
    <SelectInput
      hideLabel={hideLabel}
      testId={testId}
      id={id}
      label={label}
      options={finalOptions}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      inputRef={field.ref}
      required={required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      disabled={disabledFromContext ?? disabled}
      contextualHelp={contextualHelp}
      errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
      startAdornment={startAdornment}
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      labelIconEnd={labelIconEnd}
      emptyValueDisplay={emptyValueDisplay}
      showEmptyValue={showEmptyValue}
    />
  );
}

export function FormAwareSelectInput<FormShape extends FieldValues>(
  props: FormAwareSelectInputProps<FormShape>
) {
  const { fieldName, label, required, control, validation } = props;

  const validations = getValidations(label, !!required, validation);

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <ResponsiveSelectInput
            field={field}
            fieldState={fieldState}
            formState={formState}
            {...props}
          />
        );
      }}
    />
  );
}
