import { Divider, Stack } from '@mui/material';
import { BaseSyntheticEvent } from 'react';
import { FieldArrayWithId, Path, UseFieldArrayReturn } from 'react-hook-form';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card';
import { TabContent } from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';

import { useGiftDesignerModelScenariosContext } from '../../contexts/GiftDesignerModelScenarios.context';
import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { GiftScenarioCardTitle } from './GiftScenarioCardTitle';
import {
  GiftScenarioTab,
  GiftScenarioTabContent,
} from './GiftScenarioTabContent';

type GiftScenarioProps = UseFieldArrayReturn<
  GiftDesignerModelScenariosFormShape,
  'scenarios',
  '_id'
> & {
  scenario: FieldArrayWithId<
    GiftDesignerModelScenariosFormShape,
    'scenarios',
    '_id'
  >;
  idx: number;
  triggerSave: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>;
  proposalId: string;
};

export function GiftScenario({
  scenario,
  remove,
  move,
  insert,
  idx,
  triggerSave,
  proposalId,
}: GiftScenarioProps) {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const isNoGiftingScenario =
    'isNoGiftingScenario' in scenario && scenario.isNoGiftingScenario;

  const { scenarioTabState, setScenarioTabState } =
    useGiftDesignerModelScenariosContext();

  const currentTab = scenarioTabState[idx] ?? GiftScenarioTab.GIFT;

  const options: ButtonGroupInputOption<GiftScenarioTab>[] = [
    {
      display: 'Gift details',
      value: GiftScenarioTab.GIFT,
    },
    {
      display: 'Cash flow',
      value: GiftScenarioTab.INCOME_OR_EXPENSE,
    },
  ];

  return (
    <Card
      variant="outlined"
      sx={{
        p: 3,
      }}
    >
      <Stack
        divider={
          <Divider
            sx={{
              borderColor: COLORS.GRAY[200],
            }}
          />
        }
      >
        <Stack>
          <GiftScenarioCardTitle
            scenario={scenario}
            remove={remove}
            move={move}
            insert={insert}
            idx={idx}
          />
          {isNoGiftingScenario && (
            <Callout severity="info-high">
              Luminary models a &quot;Baseline scenario&quot; to help you
              compare scenarios.
            </Callout>
          )}
        </Stack>
        {!isNoGiftingScenario && (
          <Stack spacing={2} pt={2}>
            <FormAwareTextInput<GiftDesignerModelScenariosFormShape>
              control={control}
              fieldName={
                `scenarios.${idx}.name` as const satisfies Path<GiftDesignerModelScenariosFormShape>
              }
              label="Name"
              required
            />
            <TabsProvider currentTab={currentTab}>
              <ButtonGroup<GiftScenarioTab>
                label=""
                testId="GiftScenarioTabButtonGroup"
                value={currentTab}
                options={options}
                onChange={(_, value) => {
                  setScenarioTabState({
                    ...scenarioTabState,
                    [idx]: value,
                  });
                }}
              />

              <TabContent<GiftScenarioTab> tabKey={GiftScenarioTab.GIFT}>
                <GiftScenarioTabContent
                  tabKey={GiftScenarioTab.GIFT}
                  scenarioIdx={idx}
                  triggerSave={triggerSave}
                  proposalId={proposalId}
                />
              </TabContent>
              <TabContent<GiftScenarioTab>
                tabKey={GiftScenarioTab.INCOME_OR_EXPENSE}
              >
                <GiftScenarioTabContent
                  tabKey={GiftScenarioTab.INCOME_OR_EXPENSE}
                  scenarioIdx={idx}
                  triggerSave={triggerSave}
                  proposalId={proposalId}
                />
              </TabContent>
            </TabsProvider>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
