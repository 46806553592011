import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { GiftingProposalCashFlowCashFlowType } from '@/types/schema';

import { BaselineIncomeAndExpensesFormShape } from '../../GiftDesignerBasicInformationForm.types';
import { DEFAULT_GROWTH_PERCENTAGE } from '../../GiftDesignerModelScenariosForm.utils';
import { AnnuallyRecurringValue } from './BaselineIncomeAndExpensesModal.fields';

interface UseBaselineIncomeAndExpensesFormDefaultValuesProps {
  incomeData: BaselineIncomeAndExpensesFormShape | null;
  lengthOfAnalysis: Decimal | null;
}

export function useBaselineIncomeAndExpensesFormDefaultValues({
  incomeData,
  lengthOfAnalysis,
}: UseBaselineIncomeAndExpensesFormDefaultValuesProps) {
  const defaultValues = useMemo(() => {
    if (!incomeData) {
      return {
        amount: new Decimal(0),
        annuallyRecurring: AnnuallyRecurringValue.true,
        cashFlowType: GiftingProposalCashFlowCashFlowType.Income,
        displayName: '',
        endYear: (lengthOfAnalysis ?? new Decimal(0)).plus(getYear(new Date())),
        growthPercentage: DEFAULT_GROWTH_PERCENTAGE,
        order: 0,
        startYear: new Decimal(getYear(new Date())),
        lengthOfAnalysis: lengthOfAnalysis ?? new Decimal(0),
      } as const satisfies BaselineIncomeAndExpensesFormShape;
    }

    return incomeData;
  }, [incomeData, lengthOfAnalysis]);

  return { defaultValues };
}
