import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { PopperContent } from '@/components/poppers/PopperContent';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';

interface GiftAndEstateTaxSectionTooltipProps {
  federalEstateTaxValue: Decimal;
  stateEstateTaxValue: Decimal;
  giftTaxValue: Decimal;
  actions?: React.ReactNode;
  isOnlyGiftTax?: boolean;
}

export function GiftAndEstateTaxSectionTooltip({
  federalEstateTaxValue = new Decimal(0),
  stateEstateTaxValue = new Decimal(0),
  giftTaxValue = new Decimal(0),
  actions,
  isOnlyGiftTax = false,
}: GiftAndEstateTaxSectionTooltipProps) {
  const estateTax = useMemo(() => {
    return formatCurrencyNoDecimalsAccounting(
      federalEstateTaxValue.plus(stateEstateTaxValue).negated()
    );
  }, [federalEstateTaxValue, stateEstateTaxValue]);

  const giftTax = formatCurrencyNoDecimalsAccounting(giftTaxValue.negated());

  const rowLabels = useMemo(() => {
    const estateTaxTuple = ['Estate tax', estateTax] as const;
    const giftTaxTuple = ['Gift tax', giftTax] as const;

    if (isOnlyGiftTax) {
      return [giftTaxTuple] as const;
    }

    return [estateTaxTuple, giftTaxTuple] as const;
  }, [estateTax, giftTax, isOnlyGiftTax]);

  const body = useMemo(() => {
    return (
      <Stack>
        <MiniTable
          rows={rowLabels.map((tax) => ({
            label: tax[0],
            value: tax[1],
            valueProps: { sx: { color: COLORS.MATH.NEGATIVE } },
          }))}
          variant="default"
        />
      </Stack>
    );
  }, [rowLabels]);

  return (
    <PopperContent
      body={body}
      actions={actions}
      actionsSx={{
        marginBottom: 0,
        marginRight: 0,
      }}
    />
  );
}
