import { compact } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { FieldValues } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { useSelectInputContext } from '@/modules/entities/contexts/selectInput/selectInput.context';

import { SelectInputOption } from '../../baseInputs/inputTypes';
import { ResponsiveSelectInputProps } from '../FormAwareSelectInput';

/**
 * @description Hook to respond to new options and automatically select them
 */
export function useResponsiveSelectInput<FormShape extends FieldValues>({
  isOpen,
  handleClose,
  ...props
}: ResponsiveSelectInputProps<FormShape> & {
  isOpen: boolean;
  handleClose: () => void;
}) {
  const { activeSelectInput } = useSelectInputContext();
  const {
    fieldName,
    options,
    disableAutomaticNewOptionSelection = false,
  } = props;

  const { setValue } = useFormContext<FormShape>();

  const isActiveSelectInput = activeSelectInput === fieldName;

  // Remove component options from options list
  const selectableOptions: SelectInputOption<unknown>[] = useMemo(() => {
    return compact(
      options.map((o) => {
        if (o.type === 'component') {
          return null;
        } else {
          return o;
        }
      })
    );
  }, [options]);

  const oldSelectableOptionsRef = useRef<typeof selectableOptions>([]);

  useEffect(
    // Wait for the value options to be loaded before initializing old options
    function initializeOldOptions() {
      if (selectableOptions.length && !oldSelectableOptionsRef.current.length) {
        oldSelectableOptionsRef.current = selectableOptions;
      }
    },
    [isOpen, selectableOptions]
  );

  useEffect(
    // Respond to changes in options and assume that if an option is added, it should be selected
    function selectAddedOption() {
      if (
        !selectableOptions.length ||
        !oldSelectableOptionsRef.current.length
      ) {
        return;
      }

      const oldOptionValues = new Set(
        oldSelectableOptionsRef.current.map((o) => o.value)
      );

      const newSelectableOption = selectableOptions.find((o) => {
        return !oldOptionValues.has(o.value);
      });

      if (
        newSelectableOption &&
        isActiveSelectInput &&
        !disableAutomaticNewOptionSelection
      ) {
        // If new option is found, select it
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- linter refactor
        setValue(fieldName, newSelectableOption.value as any);
        // Close the select input
        handleClose();
      }

      oldSelectableOptionsRef.current = selectableOptions;
    },
    [
      disableAutomaticNewOptionSelection,
      fieldName,
      handleClose,
      isActiveSelectInput,
      selectableOptions,
      setValue,
    ]
  );
}
