import { Control, Controller, FieldValues } from 'react-hook-form';

import { getFieldErrorValue } from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import { Checkbox } from '../baseInputs/Checkbox';
import { HelpTextVariant } from '../baseInputs/inputTypes';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

interface FormAwareCheckboxProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  required?: boolean;
  control: Control<FormShape>;
  disabled?: boolean;
  label?: React.ReactNode;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  contextualHelp?: JSX.Element;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function FormAwareCheckbox<FormShape extends FieldValues>({
  fieldName,
  required,
  control,
  label,
  helpText,
  helpTextVariant,
  contextualHelp,
  disabled,
  onChange,
  id,
}: FormAwareCheckboxProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field, fieldState, formState }) => {
        return (
          <Checkbox
            onChange={(...args) => {
              field.onChange(...args);
              onChange?.(...args);
            }}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            contextualHelp={contextualHelp}
            label={label}
            value={field.value}
            name={field.name}
            inputRef={field.ref}
            required={required}
            disabled={disabledFromContext ?? disabled}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            id={id}
          />
        );
      }}
    />
  );
}
