import TextField, { TextFieldProps } from '@mui/material/TextField';
import * as React from 'react';
import NumberFormat, { InputAttributes } from 'react-number-format';

import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BasePercentInputProps,
  FormControlPercentInputProps,
  HelpTextVariant,
} from '../inputTypes';

interface PercentInputProps extends Omit<FormControlPercentInputProps, 'type'> {
  label: string;
  id?: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
  hideLabel?: boolean;
}

interface NumberFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  label: string;
  id?: string;
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  allowNegative?: boolean;
}

const NumberFormatInput = React.forwardRef<
  NumberFormat<InputAttributes>,
  NumberFormatProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      type="tel"
    />
  );
});

function BasePercentInput({
  // see here: https://github.com/mui/material-ui/issues/12946
  // stripping off onKeyUp and onKeyDown is required here because TextField doesn't actually implement those props.
  onKeyUp: _onKeyUp,
  onKeyDown: _onKeyDown,
  decimalScale,
  fixedDecimalScale,
  allowNegative,
  ...inputProps
}: BasePercentInputProps) {
  const { disabled } = inputProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  const mergedSx = Object.assign({ background: 'white' }, disabledStyle);

  return (
    <TextField
      InputProps={{
        inputComponent: NumberFormatInput as unknown as NonNullable<
          NonNullable<TextFieldProps['InputProps']>['inputComponent']
        >,
        endAdornment: '%',
        sx: mergedSx,
        inputProps: {
          'data-testid': `percent-input-${inputProps.name ?? ''}`,
        },
      }}
      variant="outlined"
      inputProps={{
        decimalScale,
        fixedDecimalScale,
        allowNegative,
      }}
      {...inputProps}
    />
  );
}

export function PercentInput({
  label,
  contextualHelp,
  errorMessage,
  helpText,
  hideLabel,
  helpTextVariant,
  ...inputProps
}: PercentInputProps) {
  return (
    <FormControl<BasePercentInputProps>
      component={BasePercentInput}
      inputProps={inputProps}
      errorMessage={errorMessage}
      contextualHelp={contextualHelp}
      required={inputProps.required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      id={inputProps.id}
      label={label}
      hideLabel={hideLabel}
    />
  );
}
