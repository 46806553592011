import { Theme } from '@mui/material';
import { includes } from 'lodash';

import { COLORS } from '@/styles/tokens/colors';
import { FONT_SIZING } from '@/styles/tokens/fonts';
import { UnreachableError } from '@/utils/errors';

import { ButtonSize, ButtonVariant } from './types';

interface RegularButtonColorDefinition {
  backgroundColor: string;
  backgroundColorOnHover: string;
  textColor: string;
  borderColor: string;
  textColorOnHover?: string;
}

interface DisabledButtonColorDefinition {
  backgroundColor?: string;
  backgroundOnHover?: string;
  textColor?: string;
  borderColor: string;
}

interface ButtonColorMap {
  regular: RegularButtonColorDefinition;
  disabled: DisabledButtonColorDefinition;
}
type ColorsByVariant = Record<ButtonVariant, ButtonColorMap>;
export function getColorsForVariant(variant: ButtonVariant, theme: Theme) {
  const buttonColor = theme.palette.buttons;
  const secondary: ButtonColorMap = {
    regular: {
      backgroundColor: theme.palette.common.white,
      backgroundColorOnHover: COLORS.GRAY[300],
      textColor: buttonColor.main,
      borderColor: COLORS.GRAY[300],
    },
    disabled: {
      borderColor: COLORS.GRAY[100],
      backgroundColor: theme.palette.common.white,
      textColor: COLORS.GRAY[300],
    },
  };
  const colorsByVariant: ColorsByVariant = {
    primary: {
      regular: {
        backgroundColor: buttonColor.main,
        borderColor: buttonColor.main,
        textColor: buttonColor.contrastText,
        backgroundColorOnHover: buttonColor.dark,
      },
      disabled: {
        backgroundColor: buttonColor.light,
        borderColor: buttonColor.light,
      },
    },
    secondary,
    'secondary-filled': { ...secondary },
    destructive: {
      regular: {
        backgroundColor: theme.palette.common.white,
        backgroundColorOnHover: COLORS.FUNCTIONAL.ERROR[50],
        textColor: COLORS.FUNCTIONAL.ERROR.DEFAULT,
        borderColor: COLORS.FUNCTIONAL.ERROR.DEFAULT,
      },
      disabled: {
        borderColor: COLORS.FUNCTIONAL.ERROR[300],
        backgroundColor: theme.palette.common.white,
        textColor: COLORS.FUNCTIONAL.ERROR[300],
      },
    },
    'destructive-transparent': {
      regular: {
        backgroundColor: 'transparent',
        backgroundColorOnHover: COLORS.FUNCTIONAL.ERROR[50],
        textColor: COLORS.FUNCTIONAL.ERROR.DEFAULT,
        textColorOnHover: COLORS.FUNCTIONAL.ERROR[700],
        borderColor: 'transparent',
      },
      disabled: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        textColor: COLORS.FUNCTIONAL.ERROR[300],
      },
    },
    'destructive-prominent': {
      regular: {
        backgroundColor: COLORS.FUNCTIONAL.ERROR.DEFAULT,
        backgroundColorOnHover: COLORS.FUNCTIONAL.ERROR[700],
        textColor: theme.palette.common.white,
        borderColor: COLORS.FUNCTIONAL.ERROR.DEFAULT,
      },
      disabled: {
        borderColor: COLORS.FUNCTIONAL.ERROR[300],
        backgroundColor: COLORS.FUNCTIONAL.ERROR[500],
        textColor: theme.palette.common.white,
      },
    },
    transparent: {
      regular: {
        backgroundColor: 'transparent',
        backgroundColorOnHover: COLORS.GRAY[50],
        textColor: COLORS.GRAY[500],
        textColorOnHover: COLORS.GRAY[600],
        borderColor: 'transparent',
      },
      disabled: {
        textColor: COLORS.GRAY[300],
        borderColor: 'transparent',
      },
    },
  };

  return colorsByVariant[variant];
}

export const getCSSPropertiesByVariant = (
  variant: ButtonVariant,
  disabled: boolean,
  theme: Theme
) => {
  const colors = getColorsForVariant(variant, theme);
  const secondary = {
    color: colors.regular.textColor,
    border: `solid ${colors.regular.borderColor} 1px`,
    ':hover': {
      backgroundColor: colors.regular.backgroundColorOnHover,
    },
    ...(disabled
      ? {
          border: `solid ${colors.disabled.borderColor} 1px`,
          backgroundColor: colors.disabled.backgroundColor,
          color: colors.disabled.textColor,
        }
      : {}),
  };

  switch (variant) {
    case 'primary': {
      return {
        backgroundColor: colors.regular.backgroundColor,
        border: `solid ${colors.regular.borderColor} 1px`,
        color: colors.regular.textColor,
        ':hover': {
          backgroundColor: colors.regular.backgroundColorOnHover,
          borderColor: colors.regular.backgroundColorOnHover,
        },
        ...(disabled
          ? {
              backgroundColor: colors.disabled.backgroundColor,
              borderColor: colors.disabled.borderColor,
            }
          : {}),
      };
    }
    case 'secondary':
      return secondary;
    case 'secondary-filled':
      return { ...secondary, backgroundColor: colors.regular.backgroundColor };
    case 'destructive': {
      return {
        backgroundColor: colors.regular.backgroundColor,
        color: colors.regular.textColor,
        border: `solid ${colors.regular.borderColor} 1px`,
        ':hover': {
          backgroundColor: colors.regular.backgroundColorOnHover,
        },
        ...(disabled
          ? {
              border: `solid ${colors.disabled.borderColor} 1px`,
              backgroundColor: colors.disabled.backgroundColor,
              color: colors.disabled.textColor,
            }
          : {}),
      };
    }
    case 'destructive-prominent': {
      return {
        backgroundColor: colors.regular.backgroundColor,
        color: colors.regular.textColor,
        border: `solid ${colors.regular.borderColor} 1px`,
        ':hover': {
          backgroundColor: colors.regular.backgroundColorOnHover,
          borderColor: colors.regular.backgroundColorOnHover,
        },
        ...(disabled
          ? {
              border: `solid ${colors.disabled.borderColor} 1px`,
              backgroundColor: colors.disabled.backgroundColor,
              color: colors.disabled.textColor,
            }
          : {}),
      };
    }
    case 'destructive-transparent': {
      return {
        backgroundColor: colors.regular.backgroundColor,
        color: colors.regular.textColor,
        border: `solid ${colors.regular.borderColor} 1px`,
        ':hover': {
          backgroundColor: colors.regular.backgroundColorOnHover,
          borderColor: colors.regular.backgroundColorOnHover,
          color: colors.regular.textColorOnHover,
        },
        ...(disabled
          ? {
              border: `solid ${colors.disabled.borderColor} 1px`,
              backgroundColor: colors.disabled.backgroundColor,
              color: colors.disabled.textColor,
            }
          : {}),
      };
    }
    case 'transparent': {
      return {
        backgroundColor: colors.regular.backgroundColor,
        color: colors.regular.textColor,
        border: `solid ${colors.regular.borderColor} 1px`,
        ':hover': {
          backgroundColor: colors.regular.backgroundColorOnHover,
          borderColor: colors.regular.backgroundColorOnHover,
          color: colors.regular.textColorOnHover,
        },
        ...(disabled
          ? {
              color: colors.disabled.textColor,
            }
          : {}),
      };
    }
    default:
      throw new UnreachableError({
        case: variant,
        message: `Unrecognized button variant ${variant}`,
      });
  }
};

interface PaddingDefinition {
  paddingX: number;
  paddingY: number;
}

// an odd number, but we want the height of
// this button to be ~40px and match the height
// of a generic text input
const SMALL_VERTICAL_PADDING_PX = 9;

const paddingBySize: Record<ButtonSize, PaddingDefinition> = {
  // these ButtonGroup-specific sizes seems weird because we need smaller horizontal padding to
  // allow large numbers of buttons to reduce neatly on smaller-width screens.
  _bg: {
    paddingX: 8,
    paddingY: SMALL_VERTICAL_PADDING_PX,
  },

  xs: {
    paddingX: 8,
    paddingY: 6,
  },
  sm: {
    paddingX: 12,
    paddingY: SMALL_VERTICAL_PADDING_PX,
  },
  md: {
    paddingX: 16,
    paddingY: 12,
  },
  lg: {
    paddingX: 24,
    paddingY: 12,
  },
};

function getCSSPropsForButtonPadding(
  size: ButtonSize,
  isSquare: boolean
): React.CSSProperties {
  const paddingDefinition = paddingBySize[size];
  if (isSquare) {
    return {
      padding: paddingDefinition.paddingY,
    };
  }

  return {
    padding: `${paddingDefinition.paddingY}px ${paddingDefinition.paddingX}px`,
  };
}

const fontStyleOverridesBySize: Record<ButtonSize, React.CSSProperties> = {
  _bg: {},
  xs: {},
  sm: {},
  md: {
    fontSize: FONT_SIZING.sm.fontSize,
  },
  lg: {
    fontSize: FONT_SIZING.md.fontSize,
  },
};

export const iconSizeByButtonSize: Record<ButtonSize, number> = {
  xs: 16,
  _bg: 20,
  sm: 20,
  md: 20,
  lg: 20,
};

export function getButtonStyles({
  theme,
  variant,
  size,
  fullWidth,
  disabled,
  whiteSpace,
  square = false,
}: {
  theme: Theme;
  variant: ButtonVariant;
  size: ButtonSize;
  fullWidth?: boolean;
  square?: boolean;
  disabled?: boolean;
  whiteSpace: React.CSSProperties['whiteSpace'];
}) {
  const variantStyle = getCSSPropertiesByVariant(variant, !!disabled, theme);
  const fullWidthStyle = fullWidth ? { width: '100%' } : {};
  const fontStyleOverrides = fontStyleOverridesBySize[size];
  const padding = getCSSPropsForButtonPadding(size, square);
  const whiteSpaceStyle = whiteSpace ? { whiteSpace } : {};
  const typographyStyle =
    size === 'xs' ? theme.typography.button2 : theme.typography.button;
  const baseStyling = {
    borderRadius: theme.shape.borderRadius,
    // note that there are size-specific typography overrides in `styleBySize`
    // so typography must be applied before it
    ...typographyStyle,
    ...variantStyle,
    ...fontStyleOverrides,
    ...padding,
    ...fullWidthStyle,
    ...whiteSpaceStyle,
    ':focus-visible': {
      // for most of the buttons, we can just use the a normal 2px outline.
      // for button group buttons, though, we currently have overflow set to hidden
      // as an implementation detail, so we actually want the outline to be *inset*
      ...(includes(['_bg'], size)
        ? {
            outline: 'solid 2px',
            outlineOffset: -3,
          }
        : {
            outline: 'solid black 2px',
            outlineOffset: 2,
          }),
    },
  };

  return baseStyling;
}
