import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface UnloadPromptContext {
  shouldBlockNavCtx: boolean;
  setShouldBlockNavCtx: Dispatch<SetStateAction<boolean>>;
}

export const UnloadPromptContext = createContext<UnloadPromptContext>({
  shouldBlockNavCtx: false,
  setShouldBlockNavCtx: () => null,
});

export const useUnloadPromptContext = () => {
  return useGuardedContext(UnloadPromptContext, 'UnloadPromptProvider');
};
