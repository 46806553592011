import posthog from 'posthog-js';

import { getEnvironment } from './environmentUtils';

export function getPosthogKey() {
  if (getEnvironment() === 'prod') {
    return 'phc_PTrx0j5vB6xy4Phx0QZdgdpf06lr4EL5mkiI1m9XFoq';
  }
  return 'phc_hrN1OL9g4qSO8DS1PR5JRlk2DA1U0dk3RJH6Yl7YJYq';
}

export function getPosthogSessionURL() {
  return posthog.get_session_replay_url();
}
