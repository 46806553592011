import { IncomingMessage } from 'http';

import { assertNonNil } from './assertUtils';
import { getEnvironment } from './environmentUtils';

export function getTenant() {
  return assertNonNil(
    window.location.hostname.split('.')[0],
    `Could not parse tenant id for ${window.location.hostname}`
  );
}

export function getServerSideTenant(headers: IncomingMessage['headers']) {
  return headers.host?.split('.')[0] ?? null;
}

export function getFullyQualifiedTenantURL(subdomain: string) {
  const env = getEnvironment();
  const tld = env === 'prod' ? 'withluminary.com' : 'withluminary.dev';
  return `https://${subdomain}.${tld}`;
}
