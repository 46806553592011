import { Stack, styled, Typography, useTheme } from '@mui/material';

import { getTriggerStyling } from './styles';

export type LinkButtonProps = React.PropsWithChildren<{
  display: string;
  underline?: boolean;
  endIcon?: React.ReactNode;
}> &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const StyledButton = styled('button')<{ color: string; underline?: boolean }>(({
  theme,
  color,
  underline = true,
}) => {
  const triggerStyling = getTriggerStyling(color, theme);

  return {
    ...triggerStyling,
    borderBottom: underline ? triggerStyling.borderBottom : 'none',
  };
});

/**
 * This is a button that looks like a link.
 */

export function LinkButton({
  display,
  underline = true,
  endIcon,
  ...nativeButtonProps
}: LinkButtonProps) {
  const theme = useTheme();
  const color = theme.palette.links.main;
  return (
    <StyledButton
      color={color}
      underline={underline}
      type="button"
      {...nativeButtonProps}
    >
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography color="inherit" variant="h6" fontSize="sm" component="span">
          {display}
        </Typography>
        {endIcon}
      </Stack>
    </StyledButton>
  );
}
