import { useCallback } from 'react';
import { SetOptional } from 'type-fest';

import { Modal, ModalProps } from '@/components/modals/Modal/Modal';
import { AsteriskRequiredLabel } from '@/modules/forms/formAccessories';
import { CONFIRMATION_MESSAGE } from '@/navigation/UnloadPrompt.provider';

export type FormModalProps = SetOptional<ModalProps, 'rightHeaderContent'> & {
  shouldBlockClose?: boolean;
};

export function FormModal({
  rightHeaderContent = <AsteriskRequiredLabel />,
  onClose,
  children,
  shouldBlockClose,
  isOpen,
  ...rest
}: FormModalProps) {
  const safeModalClose = useCallback(() => {
    // If the form's context says we should block, we want to block the user from
    // closing the modal if the modal is already open
    if (shouldBlockClose && isOpen) {
      const isConfirmed = window.confirm?.(CONFIRMATION_MESSAGE);

      if (!isConfirmed) {
        return;
      }
    }

    onClose?.();
  }, [isOpen, onClose, shouldBlockClose]);

  return (
    <Modal
      rightHeaderContent={rightHeaderContent}
      onClose={safeModalClose}
      isOpen={isOpen}
      {...rest}
    >
      {children}
    </Modal>
  );
}
