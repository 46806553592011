import { Box, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { Callout } from '@/components/notifications/Callout/Callout';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';

import {
  BaselineIncomeAndExpensesFormShape,
  BaselineIncomeAndExpensesFormShapeMerged,
  GiftDesignerBasicInformationFormShape,
} from '../../GiftDesignerBasicInformationForm.types';
import {
  AnnuallyRecurringValue,
  BaselineIncomeAndExpensesFormFields,
} from './BaselineIncomeAndExpensesModal.fields';
import { useBaselineIncomeAndExpensesFormDefaultValues } from './BaselineIncomeAndExpensesModal.utils';

interface OutOfEstatePortfolioFormModalProps {
  incomeData: BaselineIncomeAndExpensesFormShape | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess: (formData: BaselineIncomeAndExpensesFormShape) => void;
  onDelete?: () => void;
}

function BaselineIncomeAndExpensesModalInner({
  incomeData,
  isOpen,
  onClose,
  onSubmitSuccess,
  onDelete = () => null,
}: OutOfEstatePortfolioFormModalProps) {
  const isEdit = !!incomeData;

  const { formRef, handleSave } = useFormSaveHandler();

  const { reset, handleSubmit, control, formState } =
    useFormContext<BaselineIncomeAndExpensesFormShape>();

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const heading = useMemo(() => {
    if (isEdit) {
      return 'Edit cash flow';
    }

    return 'Add cash flow';
  }, [isEdit]);

  const actions = useMemo(() => {
    return (
      <FormModalActions.Provider<BaselineIncomeAndExpensesFormShape>
        formState={formState}
      >
        {isEdit && <FormModalActions.DeleteButton onConfirmDelete={onDelete} />}
        <FormModalActions.CancelButton onClick={handleClose} />
        <FormModalActions.SaveButton onClick={handleSave}>
          {isEdit ? 'Save edits' : 'Add cash flow'}
        </FormModalActions.SaveButton>
      </FormModalActions.Provider>
    );
  }, [formState, handleClose, handleSave, isEdit, onDelete]);

  const onSubmit = handleSubmit(
    (formData: BaselineIncomeAndExpensesFormShapeMerged) => {
      const commonFormData = {
        cashFlowId: formData.cashFlowId,
        amount: formData.amount,
        cashFlowType: formData.cashFlowType,
        displayName: formData.displayName,
        order: formData.order,
        startYear: formData.startYear,
        lengthOfAnalysis: formData.lengthOfAnalysis,
      };

      // We need to make sure to format the form data correctly
      if (formData.annuallyRecurring === AnnuallyRecurringValue.false) {
        return onSubmitSuccess({
          ...commonFormData,
          annuallyRecurring: AnnuallyRecurringValue.false,
          endYear: null,
          growthPercentage: null,
        });
      } else {
        return onSubmitSuccess({
          ...commonFormData,
          annuallyRecurring: AnnuallyRecurringValue.true,
          endYear: formData.endYear!,
          growthPercentage: formData.growthPercentage!,
        });
      }
    }
  );

  useSubmitSuccessHandler(() => {
    handleClose();
  });

  const annuallyRecurringValue = useWatch({
    control,
    name: 'annuallyRecurring',
  });

  return (
    <FormModal
      isOpen={isOpen}
      heading={heading}
      onClose={handleClose}
      actions={actions}
    >
      <Stack
        component="form"
        ref={formRef}
        onSubmit={onSubmit}
        noValidate
        pb={1}
      >
        <FormLayoutRow>
          <FormLayoutItem>
            <BaselineIncomeAndExpensesFormFields.Name />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <BaselineIncomeAndExpensesFormFields.CashFlowType />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem width={5}>
            <BaselineIncomeAndExpensesFormFields.Amount />
          </FormLayoutItem>
          <FormLayoutItem width={7}>
            <Stack height="100%" justifyContent="flex-end">
              <BaselineIncomeAndExpensesFormFields.AnnuallyRecurring />
              <Box height={8} />
            </Stack>
          </FormLayoutItem>
        </FormLayoutRow>
        {annuallyRecurringValue === AnnuallyRecurringValue.true ? (
          <FormLayoutRow>
            <FormLayoutItem width={4}>
              <BaselineIncomeAndExpensesFormFields.StartYear
                isAnnuallyRecurring={true}
              />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <BaselineIncomeAndExpensesFormFields.EndYear />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <BaselineIncomeAndExpensesFormFields.GrowthRate />
            </FormLayoutItem>
          </FormLayoutRow>
        ) : (
          <FormLayoutRow>
            <FormLayoutItem>
              <BaselineIncomeAndExpensesFormFields.StartYear
                isAnnuallyRecurring={false}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}
        <Callout severity="info-high" textVariant="subtitle2">
          You are {isEdit ? 'editing' : 'adding'} a &quot;Baseline cash
          flow&quot;, these edits will be reflected across all scenarios in this
          analysis.
        </Callout>
      </Stack>
    </FormModal>
  );
}

export function BaselineIncomeAndExpensesModal(
  props: OutOfEstatePortfolioFormModalProps
) {
  const { incomeData } = props;

  const { control } = useFormContext<GiftDesignerBasicInformationFormShape>();

  const lengthOfAnalysis = useWatch({
    control,
    name: 'lengthOfAnalysis',
  });

  const { defaultValues } = useBaselineIncomeAndExpensesFormDefaultValues({
    incomeData,
    lengthOfAnalysis,
  });

  const formMethods = useForm<BaselineIncomeAndExpensesFormShape>({
    defaultValues,
    values: defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <BaselineIncomeAndExpensesModalInner {...props} />
    </FormProvider>
  );
}
