import { PopperContent } from '@/components/poppers/PopperContent';

export function GiftExemptionSunsets() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body={`Exemptions increase annually with inflation and are set to "sunset", or be reduced to roughly half of the current exemption amounts, at the end of 2025.`}
    />
  );
}
