import { useCallback, useEffect } from 'react';

import * as diagnostics from '@/utils/diagnostics';

/**
 * @description Reports an error using the diagnostics.error method.
 * @param {string | undefined} description - The description of the error.
 * @param {ApolloError | undefined} error - The error object.
 * @param {Record<string, any> | undefined} context - Additional context information.
 * @returns {Object} An object with the reportError function.
 *
 * @example
 * Usage 1: Automatically report an error when it occurs.
 *  The hook will trigger the error reporting logic whenever error and description are provided.
 *  context can also be included if necessary.
 * ```
 * import { useReportError } from '@/hooks/useReportError';
 * useReportError('An error occurred', error, { additionalData: 'some value' });
 * ```
 * @example
 *  Usage 2: Manually report an error using the reportError function returned by the hook.
 *  This is useful when you need to handle errors in a specific scenario.
 * ```
 * import { useReportError } from '@/hooks/useReportError';
 * const { reportError } = useReportError();
 * const handleClick = () => {
 *   reportError('Error during button click');
 * };
 * ```
 */
type DiagnosticsErrorArgs = Parameters<typeof diagnostics.error>;

export const useReportError = (
  description?: DiagnosticsErrorArgs['0'],
  error?: DiagnosticsErrorArgs['1'],
  context?: DiagnosticsErrorArgs['2']
) => {
  useEffect(() => {
    if (!error) return;
    if (!description) return;
    diagnostics.error(description, error, context);
  }, [error, context, description]);

  const reportError = useCallback(
    (
      description: DiagnosticsErrorArgs['0'],
      error?: DiagnosticsErrorArgs['1'],
      context?: DiagnosticsErrorArgs['2']
    ) => diagnostics.error(description, error, context),
    []
  );

  return { reportError };
};
