import { useState } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getNodes } from '@/utils/graphqlUtils';

import { useGetScenarioGiftTypeOptionsQuery } from './graphql/GetScenarioGiftTypeOptions.generated';

const errorMessage =
  "We weren't able to initialize this form. Please refresh the page and try again.";

export function useScenarioGiftTypeOptions(proposalId: string) {
  const { createErrorFeedback, showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [typeOptions, setTypeOptions] = useState<SelectInputOption<string>[]>(
    []
  );

  const queryMethods = useGetScenarioGiftTypeOptionsQuery({
    variables: {
      where: {
        id: proposalId,
      },
    },
    onCompleted(data) {
      const proposals = getNodes(data.proposals);

      const grantors = proposals[0]?.household.possiblePrimaryClients;

      if (!grantors) {
        const errorDescription = 'Expected clients for the proposal.';
        const err = new Error(errorDescription);
        reportError(errorDescription, err, {
          proposalId,
        });
        showFeedback(errorMessage);
        throw err;
      }

      setTypeOptions(
        grantors.map((grantor) => ({
          display: grantor.displayName,
          value: grantor.id,
        })) ?? []
      );
    },
    onError: createErrorFeedback(
      "We weren't able to initialize this form. Please refresh the page and try again."
    ),
  });

  return { typeOptions, ...queryMethods };
}
