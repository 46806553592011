import { createContext, useContext } from 'react';

export interface FormFieldsDisabledContextType {
  // this is kind of an odd type signature, but it's defined this way because we only want this context
  // to be able to explicitly mark all inputs as disabled; we don't want this component to explicitly *enable*
  // all inputs because we want to delegate the control of that to the form itself, and allowing this
  // component to do that would be a major footgun.
  disabled: true | undefined;
}

export const FormFieldsDisabledContext = createContext<
  FormFieldsDisabledContextType | undefined
>(undefined);

export const useFormFieldsDisabled = (): FormFieldsDisabledContextType => {
  const context = useContext(FormFieldsDisabledContext);

  // normally we would use useGuardedContext here, but we're going to use it inside a lot of form-aware
  // input components and don't want to force ourselves to wrap every form in this provider.
  // so we're going to default to undefined and delegate to local form fields' disabled states
  if (!context) {
    return {
      disabled: undefined,
    };
  }

  return context;
};
