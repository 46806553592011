import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import {
  getDecimalJSSupportingOnChange,
  getFieldErrorValue,
  getPossiblyDecimalFieldValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import { CurrencyInput } from '../baseInputs/CurrencyInput/CurrencyInput';
import { HelpTextVariant } from '../baseInputs/inputTypes';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';
import { useValidateOnChange } from './hooks/useValidateOnChange';

export interface FormAwareCurrencyInputProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  hideLabel?: boolean;
  control: Control<FormShape>;
  onFocus?: () => void;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  required?: boolean;
  disabled?: boolean;
  isDecimalJSInput?: boolean;
  decimalScale?: number;
  validation?: RegisterOptions<FormShape>['validate'];
  contextualHelp?: JSX.Element;
  validateOnChange?: boolean;
  allowNegative?: boolean;
}

export function FormAwareCurrencyInput<FormShape extends FieldValues>({
  fieldName,
  label,
  helpText,
  helpTextVariant,
  required,
  control,
  disabled,
  decimalScale = 2,
  onFocus,
  validation,
  isDecimalJSInput,
  contextualHelp,
  validateOnChange,
  allowNegative = false,
  hideLabel = false,
}: FormAwareCurrencyInputProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, validation);
  useValidateOnChange<FormShape>(
    validateOnChange ?? false,
    fieldName,
    control,
    { valueType: 'decimal' }
  );

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <CurrencyInput
            label={label}
            hideLabel={hideLabel}
            inputRef={field.ref}
            required={required}
            name={field.name}
            decimalScale={decimalScale}
            onChange={getDecimalJSSupportingOnChange(field.onChange, {
              isDecimalJS: !!isDecimalJSInput,
            })}
            onBlur={field.onBlur}
            onFocus={onFocus}
            disabled={disabledFromContext ?? disabled}
            value={getPossiblyDecimalFieldValue(field.value, {
              isDecimalJS: !!isDecimalJSInput,
              fieldName: field.name,
              decimalScale,
            })}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            errorMessage={getFieldErrorValue(
              fieldState,
              formState.isSubmitted,
              validateOnChange ?? false
            )}
            contextualHelp={contextualHelp}
            allowNegative={allowNegative}
          />
        );
      }}
    />
  );
}
