import { upperFirst } from 'lodash';

import { pascalCaseLikeRegex } from '../regex';

/**
 * Given a list of strings and a final separator (or "join term"), this function joins them using oxford comma logic.
 * ['ian'] => 'ian'
 * ['ian', 'brett'] => 'ian and brett'
 * ['ian', 'brett', 'edu'] => 'ian, brett, and edu'
 * ['ian', 'brett', 'edu', 'alex'] => 'ian, brett, edu, and alex'
 */
export function englishJoinStringList(
  list: string[],
  joinTerm = 'and'
): string {
  if (list.length === 0) return '';
  if (list.length === 1) return list.join('');
  if (list.length === 2) return list.join(` ${joinTerm} `);
  const lastItem = list[list.length - 1];
  const otherItems = list.slice(0, list.length - 1);
  // don't use a trailing oxford comma for ampersand-joined lists
  const oxfordComma = joinTerm === '&' ? '' : ',';
  return `${otherItems.join(', ')}${oxfordComma} ${joinTerm} ${lastItem}`;
}

export function formatPascalCase(value?: string | null) {
  const parsed = value?.match(pascalCaseLikeRegex);
  if (!parsed?.[0]) return value;
  return [parsed[0], ...parsed.slice(1).map((p) => p.toLowerCase())].join(' ');
}

export function formatEnumCase(value?: string | null) {
  return value ? upperFirst(value.toLowerCase().split('_').join(' ')) : '';
}

export function formatTaxId(value?: string | null) {
  if (!value) return '';
  // turns "123456789" into "12-3456789"
  return value.replace(/(\d{2})(\d{7})/, '$1-$2');
}

export function getIndefiniteArticle(precedingWord: string) {
  return ['a', 'e', 'i', 'o', 'u'].includes(precedingWord[0] || '')
    ? 'an'
    : 'a';
}

export function pluralize(
  count: number,
  singular: string,
  plural?: string
): string {
  return count === 1 ? singular : plural || `${singular}s`;
}

/** Truncate a string to a max length; if longer, end with an ellipsis.  If possible, use CSS to do this instead. */
export function truncateToEllipis(
  string: string | undefined,
  maxLength: number
): string {
  if (!string) return '';
  if (string.length <= maxLength) return string;

  return `${string.slice(0, maxLength - 1).trim()}…`;
}

/**
 *
 * @returns the input with the following changes:
 * - all whitespace is replaced with _
 * - all characters are lowercased
 * - all non-alphanumeric characters are removed
 * - all leading and trailing underscores are removed
 */
export function getIdSafeString(value: string) {
  return value
    .replace(/\s/g, '_')
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, '')
    .replace(/^_+|_+$/g, '');
}
