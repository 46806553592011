export function getAppVersion() {
  if (isDevelopmentMode()) return 'dev';
  return process.env.NEXT_PUBLIC_BUILD_VERSION || 'UNKNOWN';
}

export type DeploymentEnvironment = 'development' | 'staging' | 'prod';
export function getEnvironment(): DeploymentEnvironment {
  // this is passed into the build process in the github action definition
  return (
    (process.env.NEXT_PUBLIC_DEPLOYMENT_STAGE as DeploymentEnvironment) ||
    'development'
  );
}

export function isDevelopmentMode() {
  return getEnvironment() === 'development';
}

// use this instead of using e.g. the URL to determine any kind of tenant URL to
// avoid potential security issues
export function getKnownValidDomain(): string {
  const validDomain = process.env.NEXT_PUBLIC_SERVER_DOMAIN;
  if (!validDomain) {
    throw new Error('NEXT_PUBLIC_SERVER_DOMAIN var not set');
  }

  return validDomain;
}
